<div class="content-wrapper">
  <div class="main-title">
    Изменить пароль
  </div>

  <app-account-sidebar class="account-sidebar"></app-account-sidebar>

  <div class="main-container" *ngIf="!preloaderVisibility">

    <form class="form-group"
          [formGroup]="form"
          (ngSubmit)="onSubmit()">

      <mat-form-field>
        <mat-label>Текущий пароль <i class="danger">*</i></mat-label>
        <input
          [type]="showPassword01 ? 'text' : 'password'"
          matInput
          id="password-old"
          formControlName="passwordOld">

        <span class="show-password" (click)="toggleShowPassword01()">
           <i class="if if-eye"></i>
           <i class="if if-no-eye"></i>
        </span>

        <mat-error *ngIf="form.get('passwordOld').hasError('required')">
          Поле не может быть пустым
        </mat-error>
      </mat-form-field>

      <div formGroupName="passwordGroup">

        <mat-form-field>
          <mat-label>Новый пароль <i class="danger">*</i></mat-label>
          <input
            [type]="showPassword02 ? 'text' : 'password'"
            matInput
            id="password"
            formControlName="password"
            (input)="onPasswordInput()">
          <span class="show-password" (click)="toggleShowPassword02()">
             <i class="if if-eye"></i>
             <i class="if if-no-eye"></i>
          </span>
          <mat-error *ngIf="form.get('passwordGroup.password').hasError('minlength') && !form.get('passwordGroup.password').hasError('required')">
            Длина пароля должна быть минимум 5 символов
          </mat-error>
          <mat-error *ngIf="form.get('passwordGroup.password').hasError('required')">
            Поле не может быть пустым
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Подтвердите пароль <i class="danger">*</i></mat-label>
          <input
            [type]="showPassword03 ? 'text' : 'password'"
            matInput
            id="password-confirm"
            formControlName="passwordConfirm"
            (input)="onPasswordInput()">
          <span class="show-password" (click)="toggleShowPassword03()">
             <i class="if if-eye"></i>
             <i class="if if-no-eye"></i>
          </span>
          <mat-error *ngIf="form.get('passwordGroup').hasError('mismatch') && !form.get('passwordGroup.passwordConfirm').hasError('required')">
            Пароли не совпадают
          </mat-error>
          <mat-error *ngIf="form.get('passwordGroup.passwordConfirm').hasError('required')">
            Поле не может быть пустым
          </mat-error>
        </mat-form-field>

      </div>

      <div class="last-buttons">
        <button type="button"
                class="btn btn-default"
                routerLink="/account">
          Отмена
        </button>
        <button type="submit"
                class="btn btn-primary"
                [disabled]="form.invalid">
          Сохранить
        </button>
      </div>

    </form>

  </div>

  <div class="load-content-spinner" *ngIf="preloaderVisibility">
    <img src="assets/images/load-content-spinner.svg" alt="">
  </div>
</div>
