<div class="content-wrapper">
  <div class="main-title">Расписание приемов</div>

  <nav class="custom-tabs-nav">
    <ul>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'schedule', 'appointment-add']" routerLinkActive="active">
          Запись пациентов
        </button>
      </li>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'schedule', 'appointment-edit']" routerLinkActive="active">
          Управлиение записями
        </button>
      </li>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'schedule', 'edit']" routerLinkActive="active">
          Управление графиком
        </button>
      </li>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'schedule', 'add']" routerLinkActive="active">
          Создание графика
        </button>
      </li>
    </ul>
  </nav>

  <div class="router-wrapper">
    <router-outlet></router-outlet>
  </div>

</div>
