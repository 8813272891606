<mat-dialog-content class="mat-typography">
  <div class="dialog-title">{{date | date:'dd MMMM yyyy'}}</div>

  <div class="time-grid-list edit">
    <button class="time-grid-item"
            *ngFor="let item of cells; let i = index"
            [class.is-appointment]="!item.IsEnable && !item.IsBlocked"
            [class.is-blocked]="item.IsBlocked"
            (click)="showCreateAppointment(item, i)">
      {{item.TimeString}}
    </button>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center" class="dialog-buttons">
  <button type="button"
          class="btn btn-default btn-small"
          (click)="closeDialog()">
    Отмена
  </button>
</mat-dialog-actions>
