/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./submit-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./submit-dialog.component";
var styles_SubmitDialogComponent = [i0.styles];
var RenderType_SubmitDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SubmitDialogComponent, data: {} });
export { RenderType_SubmitDialogComponent as RenderType_SubmitDialogComponent };
export function View_SubmitDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 5, "mat-dialog-actions", [["align", "center"], ["class", "dialog-buttons mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u0414\u0430 "])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-default"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041D\u0435\u0442 "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.data.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_SubmitDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-submit-dialog", [], null, null, null, View_SubmitDialogComponent_0, RenderType_SubmitDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.SubmitDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var SubmitDialogComponentNgFactory = i1.ɵccf("app-submit-dialog", i3.SubmitDialogComponent, View_SubmitDialogComponent_Host_0, {}, {}, []);
export { SubmitDialogComponentNgFactory as SubmitDialogComponentNgFactory };
