import { ElementRef, EventEmitter, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounce, } from 'rxjs/operators';
import { DaDataConfigDefault } from '../../da-data-config';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DadataService, DaDataType } from '../../services/dadata.service';
import { BaseService } from '../../services/base.service';
var NGX_DADATA_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DadataMatComponent; }),
    multi: true
};
var DadataMatComponent = /** @class */ (function () {
    function DadataMatComponent(dataService, baseService) {
        this.dataService = dataService;
        this.baseService = baseService;
        this._value = '';
        this.currentFocus = -1;
        this.data = [];
        this.config = DaDataConfigDefault;
        this.disabled = null;
        this.type = DaDataType.address;
        this.limit = DaDataConfigDefault.limit;
        this.placeholder = '';
        this.fromBound = '';
        this.toBound = '';
        this.restrict = false;
        this.locations = [];
        this.parts = [''];
        this.cityFiasCheck = false;
        this.required = false;
        this.control = new FormControl();
        this.selected = new EventEmitter();
        this.suggestionEvent = new EventEmitter();
        this.inputString$ = new Subject();
        this.onTouched = function () {
        };
        this.propagateChange = function () {
        };
    }
    Object.defineProperty(DadataMatComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (v) {
            if (v !== this._value) {
                this._value = v;
                this.propagateChange(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    DadataMatComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.setApiKey(this.apiKey ? this.apiKey : this.config.apiKey);
        this.inputString$.pipe(debounce(function () { return timer(_this.config.delay ? _this.config.delay : 500); })).subscribe(function (x) {
            _this.additional = {
                parts: _this.parts,
                fromBound: _this.fromBound,
                toBound: _this.toBound,
                locations: _this.locations,
                restrict: _this.restrict
            };
            _this.dataService.getData(x, _this.type, _this.limit, _this.additional).subscribe(function (y) {
                _this.data = y.suggestions;
            });
        });
    };
    DadataMatComponent.prototype.setCityFias = function (selectVal) {
        // this.selected.emit(selectVal.option.value);
        var _this = this;
        if (this.cityFiasCheck) {
            this.baseService.postSuggestion(selectVal.option.value)
                .subscribe(function (response) {
                if (response.suggestions.length) {
                    _this.selected.emit(response.suggestions[0].data.city_fias_id);
                }
            });
        }
    };
    DadataMatComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            this.suggestionEvent.emit(changes.value);
        }
    };
    DadataMatComponent.prototype.onInputBlur = function () {
        !this.disabled ? this.onTouched() : null;
    };
    DadataMatComponent.prototype.getData = function (value) {
        this.inputString$.next(value);
        this.currentFocus = -1;
    };
    DadataMatComponent.prototype.onOutsideClick = function () {
        this.data = [];
    };
    DadataMatComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this._value = value;
        }
    };
    /**
     * Set the function to be called
     * when the control receives a change event.
     *
     * @param fn a function
     */
    DadataMatComponent.prototype.registerOnChange = function (fn) {
        // this.onSuggestionSelected = fn;
        this.propagateChange = fn;
    };
    /**
     * Set the function to be called
     * when the control receives a touch event.
     *
     * @param fn a function
     */
    DadataMatComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Implements disabled state for this element
     *
     * @param isDisabled
     */
    DadataMatComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    return DadataMatComponent;
}());
export { DadataMatComponent };
