import { Component, OnInit } from '@angular/core';
import { ClinicService } from '../../shared/services/clinic.service';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs';
import { FindClinicResponseInterface } from '../../shared/interfaces/clinic/find-clinic-response.interface';
import { ClinicInterface } from '../../shared/interfaces/clinic/clinic.interface';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-clinic-list',
  templateUrl: './clinic-list.component.html',
  styleUrls: ['./clinic-list.component.scss']
})
export class ClinicListComponent implements OnInit {

  preloaderVisibility: boolean = true;
  timeout: boolean = true;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorDataMessage;

  clinicsList: ClinicInterface[];

  findClinicSubscribe: Subscription;

  constructor(
    private clinicService: ClinicService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.findClinicSubscribe = this.clinicService.findClinic()
      .subscribe((response: FindClinicResponseInterface) => {
        console.log('FindClinicResult', response);
        this.timeout = false;

        if (response.FindClinicResult.Success) {
          this.preloaderVisibility = false;
          this.clinicService.setClinicsListData(response.FindClinicResult.AccountsData);
          this.clinicsList = response.FindClinicResult.AccountsData;
        } else {
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
        }
      }, error => {
        this.timeout = false;
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
      });

    setTimeout(() => {
      if (this.timeout) {
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.findClinicSubscribe.unsubscribe();
      }
    }, 30000);
  }

  convertAvatar(avatar) {

    if (avatar && avatar.length) {
      const name = avatar.Name;
      const content = avatar.ConcatArray;

      if (name.length) {
        let filename: any = name.split('.');
        filename = filename[filename.length - 1];

        return 'data:image/' + filename + ';base64,' + content;
      } else {
        return '/assets/images/clinic-placeholder.png';
      }
    } else {
      return '/assets/images/clinic-placeholder.png';
    }

  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      this.preloaderVisibility = false;
    });
  }

}
