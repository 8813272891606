<div class="auth-background-image">
  <img src="assets/images/auth-image-04.jpg" alt="">
</div>

<div class="auth-centered" *ngIf="!preloaderVisibility">
  <form
    class="auth-form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()">

    <div class="auth-form-title">Восстановление пароля</div>

    <label>
      <input
        [type]="showPassword ? 'text' : 'password'"
        placeholder="Новый пароль"
        id="password"
        class="input-password"
        formControlName="password">
      <i class="if if-light-envelope"></i>
      <span class="show-password" (click)="toggleShowPassword()">
         <i class="if if-eye"></i>
         <i class="if if-no-eye"></i>
      </span>

      <span class="auth-error-message"
            *ngIf="form.get('password').invalid && form.get('password').touched">

        <span *ngIf="form.get('password')['errors']['required']">
          Поле не может быть пустым
        </span>
        <span *ngIf="form.get('password')['errors']['minlength']">
          Длина пароля должна быть минимум 5 символов
        </span>
      </span>
    </label>

    <button
      type="submit"
      class="auth-button"
      [disabled]="form.invalid">
      Сохранить
    </button>
  </form>
  <div class="auth-links">
    <a routerLink="/auth/sign-in">Войти</a>
    <a routerLink="/auth/sign-up">Регистрация</a>
  </div>
</div>

<div class="load-spinner" *ngIf="preloaderVisibility">
  <img src="assets/images/load-spinner.svg" alt="">
</div>
