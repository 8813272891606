<div class="main-container">
  <div *ngIf="!preloader">
    <div class="row specialist-in-clinic-list" *ngIf="specialistsList.length">
      <div class="col-xl-3 col-lg-4 col-md-6" *ngFor="let specialist of specialistsList; let i = index;">
        <div class="card-specialist-in-clinic">
          <div class="image">
            <img *ngIf="!specialist.link" src="/assets/images/user-man-placeholder.png" alt="" >
            <img *ngIf="specialist.link" src="{{specialist.link}}" alt="">
          </div>
          <div class="caption">
            <div class="name">{{specialist.givenname}} {{specialist.surname}}</div>
            <div class="post"
                 *ngIf="specialist.specializationid.length">
              {{specialist.specializationid[0] | catalogName:doctorSpecializationCatalog}}
              <div class="more-popup"
                   *ngIf="specialist.specializationid.length > 1">
                <div class="dots-link">и Еще {{specialist.specializationid.length - 1}}</div>
                <div class="hidden-more-popup">
                  <div *ngFor="let specialization of specialist.specializationid | slice:1; let i = index;">
                    {{specialization | catalogName:doctorSpecializationCatalog}}
                  </div>
                </div>
              </div>
            </div>
            <div class="status" *ngIf="specialist.statusid == specialistStatuses.wait">Ожидает подтверждения</div>
            <div class="status status-dismiss" *ngIf="specialist.statusid == specialistStatuses.decline">Заявка отклонена</div>
            <button type="button" class="btn btn-primary btn-small" (click)="checkBeforeCancel(specialist.userid, i)" *ngIf="specialist.statusid == specialistStatuses.wait">Отменить заявку</button>
            <button type="button" class="btn btn-primary btn-small" (click)="checkBeforeHide(specialist.userid, i)" *ngIf="specialist.statusid == specialistStatuses.decline">Скрыть заявку</button>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="!specialistsList.length">Увас нету отправленых заявок</p>
  </div>
  <app-preloader *ngIf="preloader"></app-preloader>
</div>
