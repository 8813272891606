/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./specialists-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../shared/components/preloader/preloader.component.ngfactory";
import * as i4 from "../../../shared/components/preloader/preloader.component";
import * as i5 from "../../../shared/pipes/catalog-name.pipe";
import * as i6 from "./specialists-list.component";
import * as i7 from "../../../shared/services/clinic.service";
import * as i8 from "@angular/material/dialog";
import * as i9 from "@angular/router";
import * as i10 from "../../../shared/services/dialog.service";
import * as i11 from "../../../shared/services/user.service";
var styles_SpecialistsListComponent = [i0.styles];
var RenderType_SpecialistsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpecialistsListComponent, data: {} });
export { RenderType_SpecialistsListComponent as RenderType_SpecialistsListComponent };
function View_SpecialistsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/user-man-placeholder.png"]], null, null, null, null, null))], null, null); }
function View_SpecialistsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.Link, ""); _ck(_v, 0, 0, currVal_0); }); }
function View_SpecialistsListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _v.context.$implicit.Specialization, _co.doctorSpecializationCatalog)); _ck(_v, 1, 0, currVal_0); }); }
function View_SpecialistsListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "more-popup"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "dots-link"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\u0438 \u0415\u0449\u0435 ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "hidden-more-popup"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SpecialistsListComponent_7)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.SlicePipe, [])], function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.parent.parent.context.$implicit.Specialization, 1)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = (_v.parent.parent.context.$implicit.Specialization.length - 1); _ck(_v, 2, 0, currVal_0); }); }
function View_SpecialistsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "post"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.Specialization.length > 1); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.context.$implicit.Specialization[0].Specialization, _co.doctorSpecializationCatalog)); _ck(_v, 1, 0, currVal_0); }); }
function View_SpecialistsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "col-xl-3 col-lg-4 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "card-specialist-in-clinic"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_3)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_5)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-primary btn-small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.checkBeforeRemove(_v.context.$implicit.SpecialistId, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0423\u0432\u043E\u043B\u0438\u0442\u044C"]))], function (_ck, _v) { var currVal_0 = !_v.context.$implicit.Link; _ck(_v, 4, 0, currVal_0); var currVal_1 = _v.context.$implicit.Link; _ck(_v, 6, 0, currVal_1); var currVal_3 = _v.context.$implicit.Specialization.length; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.Name; _ck(_v, 9, 0, currVal_2); }); }
function View_SpecialistsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "row specialist-in-clinic-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-xl-3 col-lg-4 col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "card-add"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addSpecialist() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "if if-add-round icon-gradient"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0441\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u0430"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.specialistsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SpecialistsListComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-preloader", [], null, null, null, i3.View_PreloaderComponent_0, i3.RenderType_PreloaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.PreloaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_SpecialistsListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i5.CatalogNamePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "main-container"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SpecialistsListComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.preloader; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.preloader; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SpecialistsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-specialists-list", [], null, null, null, View_SpecialistsListComponent_0, RenderType_SpecialistsListComponent)), i1.ɵdid(1, 114688, null, 0, i6.SpecialistsListComponent, [i7.ClinicService, i8.MatDialog, i9.Router, i10.DialogService, i11.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpecialistsListComponentNgFactory = i1.ɵccf("app-specialists-list", i6.SpecialistsListComponent, View_SpecialistsListComponent_Host_0, {}, {}, []);
export { SpecialistsListComponentNgFactory as SpecialistsListComponentNgFactory };
