import { RouterModule, Routes } from '@angular/router';
import { ClinicComponent } from '../clinic.component';
import { AuthGuard } from '../../shared/services/auth.guard';
import { NgModule } from '@angular/core';
import { SpecialistsListComponent } from './specialists-list/specialists-list.component';
import { SpecialistsComponent } from './specialists.component';
import { SpecialistsInvitedComponent } from './specialists-invited/specialists-invited.component';

const routes: Routes = [
  {
    path: 'clinic/:id', component: ClinicComponent, canActivate: [AuthGuard], children: [
      {
        path: 'specialists', component: SpecialistsComponent, children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: SpecialistsListComponent},
          {path: 'invited', component: SpecialistsInvitedComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SpecialistsRoutingModule {
}
