/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.component";
var styles_AuthComponent = [];
var RenderType_AuthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AuthComponent, data: {} });
export { RenderType_AuthComponent as RenderType_AuthComponent };
export function View_AuthComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "auth-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "auth-logo"], ["routerLink", "/"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/logo-white.svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(5, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); }, null); }
export function View_AuthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auth", [], null, null, null, View_AuthComponent_0, RenderType_AuthComponent)), i0.ɵdid(1, 114688, null, 0, i2.AuthComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AuthComponentNgFactory = i0.ɵccf("app-auth", i2.AuthComponent, View_AuthComponent_Host_0, {}, {}, []);
export { AuthComponentNgFactory as AuthComponentNgFactory };
