<div class="row text-left" *ngIf="!hideAll">
  <div class="col-lg-6 main-info-block">
    <div class="title">Имя</div>
    <div class="text">{{appointmentQuery.Name.split(' ')[0]}}</div>
  </div>
  <div class="col-lg-6 main-info-block">
    <div class="title">Фамилия</div>
    <div class="text">{{appointmentQuery.Name.split(' ')[1]}}</div>
  </div>
  <div class="col-lg-6 main-info-block">
    <div class="title">Питомец</div>
    <div class="text">{{appointmentQuery.PetName}}</div>
  </div>
  <div class="col-lg-6 main-info-block">
    <div class="title">Услуга</div>
    <div class="text">{{appointmentQuery.ServiceId | catalogName:services}}</div>
  </div>
  <div class="col-lg-12 main-info-block" *ngIf="needAddress">
    <div class="title">Адрес</div>
    <div class="text">{{appointmentQuery.Address}}</div>
  </div>
  <div class="col-lg-12 main-info-block">
    <div class="title">Жалоба</div>
    <div class="text">{{appointmentQuery.Compliant}}</div>
  </div>
</div>
