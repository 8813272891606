import { Component, ElementRef, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { SelectInterface } from '../../interfaces/select.interface';
import { api } from '../../../../environments/api';
import { CustomValidators } from '../../validators/custom.validators';

const NGX_DADATA_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ForceAutocompleteComponent),
  multi: true
};

@Component({
  selector: 'app-force-autocomplete',
  templateUrl: './force-autocomplete.component.html',
  styleUrls: ['./force-autocomplete.component.scss'],
  providers: [NGX_DADATA_VALUE_ACCESSOR]
})
export class ForceAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  private _value: any = '';
  filteredOptions: SelectInterface[] = [];

  @Input() required: boolean = false;
  @Input() label: string = '';
  @Input() disabled: boolean = null;
  @Input() selectOptions: SelectInterface[] = [];
  @Input() control: FormControl = new FormControl(null, [this.customValidators.autofillForceRequire]);
  @Output() optionSelected = new EventEmitter;

  @ViewChild('inputValue') inputValue: ElementRef;

  constructor(
    private customValidators: CustomValidators
  ) { }

  onTouched = () => {
  };
  propagateChange: any = () => {
  };

  ngOnInit() {
    this.filteredOptions = [...this.selectOptions];
    console.log(this.disabled);
    if(this.disabled) {
      this.control.disable();
    } else  {
      this.control.enable();
    }
  }

  onOptionSelected(e) {
    this.optionSelected.emit(e.option);
  }

  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      this.propagateChange(v);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if(this.disabled) {
        this.control.disable();
      } else  {
        this.control.enable();
      }
    }
  }

  onInputBlur() {
    !this.disabled ? this.onTouched() : null;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this._value = value;
    }
  }

  private _filter(value: string): Object[] {
    const filterValue = value.toLowerCase();
    return this.selectOptions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeAutoFill(event) {
    this.filteredOptions = this._filter(event.target.value) as SelectInterface[];
  }

  displayWith(obj?: any): string | undefined {
    return obj ? obj.name : undefined;
  }

  /**
   * Set the function to be called
   * when the control receives a change event.
   *
   * @param fn a function
   */
  registerOnChange(fn: any): void {
    // this.onSuggestionSelected = fn;
    this.propagateChange = fn;
  }

  /**
   * Set the function to be called
   * when the control receives a touch event.
   *
   * @param fn a function
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implements disabled state for this element
   *
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
