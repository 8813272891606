import { Component, OnInit } from '@angular/core';
import { ClinicService } from '../../shared/services/clinic.service';

@Component({
  selector: 'app-specialists',
  templateUrl: './specialists.component.html',
  styleUrls: ['./specialists.component.scss']
})
export class SpecialistsComponent implements OnInit {

  constructor(
    public clinicService: ClinicService
  ) {
  }

  ngOnInit() {
  }

}
