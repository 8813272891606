/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-add-timegrid-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/material/dialog";
import * as i4 from "./appointment-add-timegrid-dialog.component";
var styles_AppointmentAddTimegridDialogComponent = [i0.styles];
var RenderType_AppointmentAddTimegridDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentAddTimegridDialogComponent, data: {} });
export { RenderType_AppointmentAddTimegridDialogComponent as RenderType_AppointmentAddTimegridDialogComponent };
function View_AppointmentAddTimegridDialogComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "time-grid-item"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showCreateAppointment(_v.context.$implicit, _v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = !_v.context.$implicit.IsEnable; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.TimeString; _ck(_v, 1, 0, currVal_1); }); }
export function View_AppointmentAddTimegridDialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 7, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i3.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵppd(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "time-grid-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentAddTimegridDialogComponent_1)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 3, "mat-dialog-actions", [["align", "center"], ["class", "dialog-buttons mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i3.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "button", [["class", "btn btn-default btn-small"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u0442\u043C\u0435\u043D\u0430 "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.cells; _ck(_v, 8, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i1.ɵnov(_v, 0), _co.date, "dd MMMM yyyy")); _ck(_v, 4, 0, currVal_0); }); }
export function View_AppointmentAddTimegridDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-add-timegrid-dialog", [], null, null, null, View_AppointmentAddTimegridDialogComponent_0, RenderType_AppointmentAddTimegridDialogComponent)), i1.ɵdid(1, 114688, null, 0, i4.AppointmentAddTimegridDialogComponent, [i3.MAT_DIALOG_DATA, i3.MatDialog, i3.MatDialogRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentAddTimegridDialogComponentNgFactory = i1.ɵccf("app-appointment-add-timegrid-dialog", i4.AppointmentAddTimegridDialogComponent, View_AppointmentAddTimegridDialogComponent_Host_0, {}, {}, []);
export { AppointmentAddTimegridDialogComponentNgFactory as AppointmentAddTimegridDialogComponentNgFactory };
