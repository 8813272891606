/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sidebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./sidebar.component";
import * as i4 from "../../services/user.service";
var styles_SidebarComponent = [i0.styles];
var RenderType_SidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SidebarComponent, data: {} });
export { RenderType_SidebarComponent as RenderType_SidebarComponent };
export function View_SidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "close-mobile"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMobileSidebar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "if if-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "nav", [["class", "sidebar-navigation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 8, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "button", [["routerLink", "/account/clinic-list"], ["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeMobileSidebarCheck() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵdid(7, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵeld(10, 0, null, null, 0, "i", [["class", "if if-clinic"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041C\u043E\u0438 \u043A\u043B\u0438\u043D\u0438\u043A\u0438"])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 3, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeMobileSidebarCheck() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "if if-support"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "span", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041C\u043E\u0438 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F"])), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "sidebar-socials"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "div", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u041C\u044B \u0432 \u0441\u043E\u0446. \u0441\u0435\u0442\u044F\u0445"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "socials-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 1, "a", [["href", "https://vk.com/appmarko"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 0, "i", [["class", "if if-vkontakte"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "/account/clinic-list"; _ck(_v, 6, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_SidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sidebar", [], null, null, null, View_SidebarComponent_0, RenderType_SidebarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SidebarComponent, [i4.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarComponentNgFactory = i1.ɵccf("app-sidebar", i3.SidebarComponent, View_SidebarComponent_Host_0, {}, {}, []);
export { SidebarComponentNgFactory as SidebarComponentNgFactory };
