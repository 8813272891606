<mat-form-field>
  <mat-label>{{placeholder}} <i class="danger" *ngIf="required">*</i></mat-label>
  <input type="text"
         aria-label="Number"
         matInput
         #inputValue
         autocomplete="false"
         (input)="getData(inputValue.value)"
         (blur)="onInputBlur()"
         [formControl]="control"
         [matAutocomplete]="autoFirstName">

  <mat-autocomplete #autoFirstName="matAutocomplete" (optionSelected)="setCityFias($event)">
    <mat-option *ngFor="let item of data;let i = index" [value]="item.value">
      {{item.value}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="control.getError('required')">Поле не может быть пустым</mat-error>
</mat-form-field>
