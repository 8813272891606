/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./specialists.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./specialists.component";
import * as i4 from "../../shared/services/clinic.service";
var styles_SpecialistsComponent = [i0.styles];
var RenderType_SpecialistsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SpecialistsComponent, data: {} });
export { RenderType_SpecialistsComponent as RenderType_SpecialistsComponent };
export function View_SpecialistsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0420\u0430\u0441\u043F\u0438\u0441\u0430\u043D\u0438\u044F \u043F\u0440\u0438\u0435\u043C\u043E\u0432"])), (_l()(), i1.ɵeld(3, 0, null, null, 17, "nav", [["class", "custom-tabs-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 16, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 4), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0441\u0442\u044B \u043A\u043B\u0438\u043D\u0438\u043A\u0438 "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 4), i1.ɵdid(17, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u044F\u0432\u043A\u0438 \u043D\u0430 \u0434\u043E\u0431\u0430\u0432\u043B\u0435\u043D\u0438\u0435 "])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "router-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(23, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, "/clinic", _co.clinicService.activeClinic, "specialists", "list"); _ck(_v, 7, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 9, 0, currVal_1); var currVal_2 = _ck(_v, 16, 0, "/clinic", _co.clinicService.activeClinic, "specialists", "invited"); _ck(_v, 15, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 17, 0, currVal_3); _ck(_v, 23, 0); }, null); }
export function View_SpecialistsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-specialists", [], null, null, null, View_SpecialistsComponent_0, RenderType_SpecialistsComponent)), i1.ɵdid(1, 114688, null, 0, i3.SpecialistsComponent, [i4.ClinicService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SpecialistsComponentNgFactory = i1.ɵccf("app-specialists", i3.SpecialistsComponent, View_SpecialistsComponent_Host_0, {}, {}, []);
export { SpecialistsComponentNgFactory as SpecialistsComponentNgFactory };
