/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clinic-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./clinic-list.component";
import * as i5 from "../../shared/services/clinic.service";
import * as i6 from "@angular/material/dialog";
var styles_ClinicListComponent = [i0.styles];
var RenderType_ClinicListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClinicListComponent, data: {} });
export { RenderType_ClinicListComponent as RenderType_ClinicListComponent };
function View_ClinicListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.Files[(_v.parent.context.$implicit.Files.length - 1)].Link; _ck(_v, 0, 0, currVal_0); }); }
function View_ClinicListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/clinic-placeholder.png"]], null, null, null, null, null))], null, null); }
function View_ClinicListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "col-xl-4 col-md-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵeld(3, 0, null, null, 8, "div", [["class", "card-clinic"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicListComponent_3)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicListComponent_4)), i1.ɵdid(8, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "clinic-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/clinic", _v.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.Files.length; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_v.context.$implicit.Files.length; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = _v.context.$implicit.AccountName; _ck(_v, 11, 0, currVal_3); }); }
function View_ClinicListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "row card-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicListComponent_2)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 7, "div", [["class", "col-xl-4 col-md-6"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 2), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "card-add"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["class", "if if-add-round icon-gradient"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "div", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043A\u043B\u0438\u043D\u0438\u043A\u0443"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.clinicsList; _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, "/account", "clinic-add"); _ck(_v, 4, 0, currVal_1); }, null); }
function View_ClinicListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "load-content-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/load-content-spinner.svg"]], null, null, null, null, null))], null, null); }
export function View_ClinicListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u0430\u0448\u0438 \u043A\u043B\u0438\u043D\u0438\u043A\u0438"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicListComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicListComponent_5)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.preloaderVisibility; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.preloaderVisibility; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_ClinicListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clinic-list", [], null, null, null, View_ClinicListComponent_0, RenderType_ClinicListComponent)), i1.ɵdid(1, 114688, null, 0, i4.ClinicListComponent, [i5.ClinicService, i6.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClinicListComponentNgFactory = i1.ɵccf("app-clinic-list", i4.ClinicListComponent, View_ClinicListComponent_Host_0, {}, {}, []);
export { ClinicListComponentNgFactory as ClinicListComponentNgFactory };
