/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./schedule.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./schedule.component";
import * as i4 from "../../shared/services/clinic.service";
var styles_ScheduleComponent = [i0.styles];
var RenderType_ScheduleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduleComponent, data: {} });
export { RenderType_ScheduleComponent as RenderType_ScheduleComponent };
export function View_ScheduleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 39, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "main-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0420\u0430\u0441\u043F\u0438\u0441\u0430\u043D\u0438\u0435 \u043F\u0440\u0438\u0435\u043C\u043E\u0432"])), (_l()(), i1.ɵeld(3, 0, null, null, 33, "nav", [["class", "custom-tabs-nav"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 32, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, [[1, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(8, 4), i1.ɵdid(9, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0417\u0430\u043F\u0438\u0441\u044C \u043F\u0430\u0446\u0438\u0435\u043D\u0442\u043E\u0432 "])), (_l()(), i1.ɵeld(13, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 15).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(15, 16384, [[3, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(16, 4), i1.ɵdid(17, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 3, { links: 1 }), i1.ɵqud(603979776, 4, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0423\u043F\u0440\u0430\u0432\u043B\u0438\u0435\u043D\u0438\u0435 \u0437\u0430\u043F\u0438\u0441\u044F\u043C\u0438 "])), (_l()(), i1.ɵeld(21, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, [[5, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(24, 4), i1.ɵdid(25, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 5, { links: 1 }), i1.ɵqud(603979776, 6, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0423\u043F\u0440\u0430\u0432\u043B\u0435\u043D\u0438\u0435 \u0433\u0440\u0430\u0444\u0438\u043A\u043E\u043C "])), (_l()(), i1.ɵeld(29, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 6, "button", [["routerLinkActive", "active"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 31).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(31, 16384, [[7, 4]], 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(32, 4), i1.ɵdid(33, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, i1.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i1.ɵqud(603979776, 7, { links: 1 }), i1.ɵqud(603979776, 8, { linksWithHrefs: 1 }), (_l()(), i1.ɵted(-1, null, [" \u0421\u043E\u0437\u0434\u0430\u043D\u0438\u0435 \u0433\u0440\u0430\u0444\u0438\u043A\u0430 "])), (_l()(), i1.ɵeld(37, 0, null, null, 2, "div", [["class", "router-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(38, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(39, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 8, 0, "/clinic", _co.clinicService.activeClinic, "schedule", "appointment-add"); _ck(_v, 7, 0, currVal_0); var currVal_1 = "active"; _ck(_v, 9, 0, currVal_1); var currVal_2 = _ck(_v, 16, 0, "/clinic", _co.clinicService.activeClinic, "schedule", "appointment-edit"); _ck(_v, 15, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 17, 0, currVal_3); var currVal_4 = _ck(_v, 24, 0, "/clinic", _co.clinicService.activeClinic, "schedule", "edit"); _ck(_v, 23, 0, currVal_4); var currVal_5 = "active"; _ck(_v, 25, 0, currVal_5); var currVal_6 = _ck(_v, 32, 0, "/clinic", _co.clinicService.activeClinic, "schedule", "add"); _ck(_v, 31, 0, currVal_6); var currVal_7 = "active"; _ck(_v, 33, 0, currVal_7); _ck(_v, 39, 0); }, null); }
export function View_ScheduleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-schedule", [], null, null, null, View_ScheduleComponent_0, RenderType_ScheduleComponent)), i1.ɵdid(1, 114688, null, 0, i3.ScheduleComponent, [i4.ClinicService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduleComponentNgFactory = i1.ɵccf("app-schedule", i3.ScheduleComponent, View_ScheduleComponent_Host_0, {}, {}, []);
export { ScheduleComponentNgFactory as ScheduleComponentNgFactory };
