<mat-dialog-content class="mat-dialog-appointment">

  <div class="dialog-title">Запись на прием</div>

  <mat-form-field *ngIf="!preloader && !success">
    <mat-label>Тип записи клиента <i class="danger">*</i></mat-label>
    <mat-select [(value)]="userType"
                (selectionChange)="changeUserType($event)">
      <mat-option value="move" *ngIf="moveAppointment">Перенос приема</mat-option>
      <mat-option value="new">Зарегистрировать нового клиента</mat-option>
      <mat-option value="current">Записать существующего клиента</mat-option>
    </mat-select>
  </mat-form-field>

  <app-create-appointment-move
    #moveComponent
    *ngIf="userType == 'move' && !success"
    [services]="selectServices"
    [appointmentQuery]="queryAppointment"
    [appointment]="appointment"
    (preloader)="preloader = $event"
    (setSuccess)="setSuccess()">
  </app-create-appointment-move>

  <app-create-appointment-new-user
    *ngIf="userType == 'new' && !success"
    #newUserComponent
    [appointment]="appointment"
    [selectServices]="selectServices"
    (preloader)="preloader = $event"
    (setSuccess)="setSuccess()">
  </app-create-appointment-new-user>

  <app-create-appointment-search-user
    *ngIf="userType == 'current' && !success"
    #searchUserComponent
    [appointment]="appointment"
    [selectServices]="selectServices"
    (preloader)="preloader = $event"
    (setSuccess)="setSuccess()"
  ></app-create-appointment-search-user>

  <div class="create-appointment-price-row" *ngIf="!preloader && !success">
    Конечная стоимость будет расчитана в клинике
  </div>

  <div class="record-info-time" *ngIf="!preloader">
    <div class="date">{{appointment.Date | date: 'dd MMMM yyyy'}}</div>
    <div class="time">{{appointment.TimeString}}</div>
  </div>
  <div class="record-info-list" *ngIf="!preloader">
    <div class="specialist-small-info">
      <div class="image">
        <img src="/assets/images/user-man-placeholder.png" alt="">
      </div>
      <div class="content">
        <div class="name">{{appointment.SpecialistName}}</div>
        <div class="post">{{appointment.SpecializationName}}</div>
      </div>
    </div>
    <div class="clinic-small-info">
      <div class="image">
        <img src="/assets/images/clinic-placeholder.png" alt="">
      </div>
      <div class="content">
        <div class="name">{{appointment.AccountName}}</div>
        <div class="post">{{clinicService.getClinicFullData().AccountData.City}}, {{clinicService.getClinicFullData().AccountData.Address}}</div>
      </div>
    </div>
  </div>

  <app-preloader
    [static]="true"
    *ngIf="preloader">
  </app-preloader>

</mat-dialog-content>
<mat-dialog-actions class="dialog-buttons" align="center" *ngIf="!preloader">
  <button type="button"
          (click)="closeDialog()"
          class="btn btn-default btn-small">
    <span *ngIf="!success">Отмена</span>
    <span *ngIf="success">Закрыть</span>
  </button>
  <button type="button"
          class="btn btn-primary btn-small"
          *ngIf="userType == 'move' && !success"
          (click)="moveAppointmentComponent.submit()"
          [disabled]="newUserComponent && newUserComponent.form.invalid">
    Записать
  </button>
  <button type="button"
          class="btn btn-primary btn-small"
          *ngIf="userType == 'new' && !success"
          (click)="newUserComponent.submit()"
          [disabled]="newUserComponent && newUserComponent.form.invalid">
    Записать
  </button>
  <button type="button"
          class="btn btn-primary btn-small"
          *ngIf="userType == 'current' && !success"
          (click)="searchUserComponent.submit()"
          [disabled]="searchUserComponent && searchUserComponent.form.invalid">
    Записать
  </button>
</mat-dialog-actions>
