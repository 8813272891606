import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { api } from '../../../../environments/api';
import { ClinicInvitedSpecialistList } from '../../../shared/interfaces/clinic/invited-specialist/clinic-invited-specialist-list';
import { ClinicService } from '../../../shared/services/clinic.service';
import { switchRelationTypes } from '../../../shared/catalogs/main.catalog';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { SwitchRelationTypeStaticInterface } from '../../../shared/interfaces/user/switch-relation-type/switch-relation-type-static.interface';
import { PreSwitchRelationTypeInterface } from '../../../shared/interfaces/user/pre-switch-relation-type.interface';
import { UserService } from '../../../shared/services/user.service';
import { SwitchRelationTypeResponseInterface } from '../../../shared/interfaces/user/switch-relation-type-response.interface';
import { DialogService } from '../../../shared/services/dialog.service';
import { doctorSpecialization } from '../../../shared/catalogs/doctor.catalog';

@Component({
  selector: 'app-specialists-invited',
  templateUrl: './specialists-invited.component.html',
  styleUrls: ['./specialists-invited.component.scss']
})
export class SpecialistsInvitedComponent implements OnInit {

  preloader: boolean = true;

  getInvitedSpecialistSubscription: Subscription;
  cancelInviteSubscription: Subscription;
  hideInviteSubscription: Subscription;

  specialistsList: ClinicInvitedSpecialistList[] = [];
  specialistStatuses: SwitchRelationTypeStaticInterface = api.switchRelationTypeList;
  doctorSpecializationCatalog: SelectInterface[] = doctorSpecialization;

  constructor(
    private clinicService: ClinicService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.getInvitedSpecialists();
  }

  getInvitedSpecialists() {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getInvitedSpecialistSubscription.unsubscribe();
    }, api.requestTimeout);

    this.getInvitedSpecialistSubscription = this.clinicService.getInvitedSpecialist()
      .subscribe((response: ClinicInvitedSpecialistList[]) => {
        !api.production ? console.log('getInvitedSpecialist', response) : null;
        clearTimeout(timeout);
        this.preloader = false;

        response.forEach((item) => {

          if (!this.specialistsList.find(find => find.userid == item.userid)) {
            const duplicates: ClinicInvitedSpecialistList[] = response.filter(filter => filter.userid == item.userid);

            let specializations = [];

            duplicates.forEach(newItem => {
              specializations.push(newItem.specializationid);
            });

            this.specialistsList.push({
              userid: item.userid,
              statusid: item.statusid,
              specializationid: specializations,
              surname: item.surname,
              givenname: item.givenname,
              link: item.link
            });

          }
        });

        !api.production ? console.log('ClinicInvitedSpecialistListResponse', response) : null;
        !api.production ? console.log('ClinicInvitedSpecialistList', this.specialistsList) : null;

      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });
  }

  /*
  * Подтверждение на отмену заявки
  * */

  checkBeforeCancel(id, index) {
    this.dialogService.openSubmitDialog(
      'Подтвердите действие!',
      'Вы действительно хотите отменить приглашение специалиста в клинику?'
    ).subscribe(response => {
      response ? this.cancelInvite(id, index) : null;
    });
  }

  /*
  * Отмена заявки
  * */

  cancelInvite(id, index) {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.cancelInviteSubscription.unsubscribe();
    }, api.requestTimeout);

    this.cancelInviteSubscription = this.switchType(id, api.switchRelationTypeList.cancel)
      .subscribe((response: SwitchRelationTypeResponseInterface) => {
        !api.production ? console.log('SwitchRelationTypeResponseInterface', response) : null;
        clearTimeout(timeout);

        if (!response.SwitchRelationTypeResult.Success) {
          this.dialogService.showStandardError();
        } else {
          this.dialogService.openStatusDialog(
            'Заявка отменена!',
            'Вы успешно отменили заявку на присоеденение специалиста к клинике.'
          );
          this.specialistsList.splice(index, 1);
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });
  }

  /*
  * Подтверждение на скрытие отклоненой заявки
  * */

  checkBeforeHide(id, index) {
    this.dialogService.openSubmitDialog(
      'Подтвердите действие!',
      'Вы действительно хотите скрыть отклоненное приглашение специалиста в клинику?'
    ).subscribe(response => {
      response ? this.hideInvite(id, index) : null;
    });
  }

  hideInvite(id, index) {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.hideInviteSubscription.unsubscribe();
    }, api.requestTimeout);

    this.hideInviteSubscription = this.switchType(id, api.switchRelationTypeList.hide)
      .subscribe((response: SwitchRelationTypeResponseInterface) => {
        !api.production ? console.log('SwitchRelationTypeResponseInterface', response) : null;
        clearTimeout(timeout);

        if (!response.SwitchRelationTypeResult.Success) {
          this.dialogService.showStandardError();
        } else {
          this.dialogService.openStatusDialog(
            'Заявка скрыта!',
            'Вы успешно скрыли отклоненную заявку на присоеденение специалиста к клинике.'
          );
          this.specialistsList.splice(index, 1);
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });
  }

  /*
  * Запрос на отправку приглашения
  * */

  switchType(userId, type) {
    const request: PreSwitchRelationTypeInterface = {
      AccountId: this.clinicService.getClinicFullData().AccountData.AccountId,
      UserId: userId,
      LinkTypeId: type
    };

    return this.userService.switchRelationType(request);
  }

}
