import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { AppointmentsGridInterface } from '../../../shared/interfaces/appointments/appointments-grid.interface';
import * as moment from 'moment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { specializations, typeAppointment } from '../../../shared/catalogs/clinic.catalogs';
import { ClinicListSpecialistsInterface } from '../../../shared/interfaces/clinic/clinic-list-specialists.interface';
import { ClinicService } from '../../../shared/services/clinic.service';
import { MatDialog } from '@angular/material';
import { DialogService } from '../../../shared/services/dialog.service';
import { api } from '../../../../environments/api';
import { GetClinicSpecialistsResponseInterface } from '../../../shared/interfaces/clinic/get-clinic-specialists-response.interface';
import { dateCompilation, isEmpty } from '../../../shared/functions/functions';
import { GetAppointmentsFiltersInterface } from '../../../shared/interfaces/clinic/schedules/get-appointments-filters.interface';
import { extendMoment } from 'moment-range';
import { GetAppointmentsResponseInterface } from '../../../shared/interfaces/clinic/schedules/get-appointments-response.interface';
import { createAdminAppointmentsGrid } from '../../../shared/functions/appointments';
import { AppointmentAdminGridInterface } from '../../../shared/interfaces/appointments/appointment-admin-grid.interface';
import { CustomValidators } from '../../../shared/validators/custom.validators';

@Component({
    selector: 'app-appointment-edit',
    templateUrl: './appointment-edit.component.html',
    styleUrls: ['./appointment-edit.component.scss']
})
export class AppointmentEditComponent implements OnInit {

    preloader: boolean = false;
    preloaderGlobal: boolean = true;
    showGrid: boolean = false;
    showDateChanger: boolean = false;
    noAppointments: boolean = false;
    init: boolean = false;

    getAppointmentsSubscribe: Subscription;
    getClinicSpecialistSubscribe: Subscription;

    appointmentsInsideClinic: AppointmentAdminGridInterface = {};
    appointmentsOutsideClinic: AppointmentsGridInterface = {};
    appointmentsGrid: AppointmentAdminGridInterface = {};

    startDate = moment().startOf('day');
    dueDate = moment().add(6, 'days').endOf('day');

    form: FormGroup;
    selectTypeAppointment: SelectInterface[] = typeAppointment;

    dateRange = [];

    specialistsList: ClinicListSpecialistsInterface[] = [];
    selectSpecialist: SelectInterface[] = [];

    selectSpecialization: SelectInterface[] = [...specializations];
    filterSpecializations: SelectInterface[] = [];

    constructor(
        public clinicService: ClinicService,
        private dialog: MatDialog,
        private dialogService: DialogService,
        private customValidators: CustomValidators
    ) {
        moment().locale('ru');
    }

    ngOnInit() {
        this.selectSpecialization.unshift({name: 'Все специализации', value: ''});
        this.filterSpecializations = [...this.selectSpecialization];
        this.formInit();
        this.getClinicSpecialist();
    }

    getDates(dates) {
        this.startDate = dates[0];
        this.dueDate = dates[1];

        this.init ? this.formChange() : null;

        !api.production ? console.log('startDate', this.startDate.format('DD.MM.YYYY')) : null;
        !api.production ? console.log('dueDate', this.dueDate.format('DD.MM.YYYY')) : null;
    }

    getClinicSpecialist() {
        const timeout = setTimeout(() => {
            this.preloaderGlobal = false;
            this.dialogService.showStandardError();
            this.getClinicSpecialistSubscribe.unsubscribe();
        }, api.requestTimeout);

        const request = this.clinicService.getClinicFullData().AccountData.AccountId;

        this.getClinicSpecialistSubscribe = this.clinicService.getClinicSpecialists(request)
            .subscribe((response: GetClinicSpecialistsResponseInterface) => {
                !api.production ? console.log('GetSpecialistResult', response) : null;

                clearTimeout(timeout);
                this.preloaderGlobal = false;

                if (response.GetSpecialistResult.Success) {
                    this.specialistsList = response.GetSpecialistResult.Specialists;
                    this.initSelect();
                } else {
                    this.dialogService.showStandardError();
                }
            }, () => {
                this.dialogService.showStandardError();
                clearTimeout(timeout);
            });
    }

    /*
   * Инициализация select
   * */

    initSelect() {
        for (let item of this.specialistsList) {
            this.selectSpecialist.push(
                {
                    value: item.SpecialistId,
                    name: item.Name
                }
            );
        }
        this.selectSpecialist.unshift({name: 'Все врачи', value: ''});
    }

    formInit() {
        this.form = new FormGroup({
            'specialization': new FormControl(
                null,
                [this.customValidators.autofillForceRequire]
            ),
            'typeAppointment': new FormControl(
                this.selectTypeAppointment[0].value,
                [Validators.required]
            ),
            'specialist': new FormControl(
                null,
                [this.customValidators.autofillForceRequire]
            )
        });
    }

    checkEmpty() {
        const empty: boolean = isEmpty(this.appointmentsGrid);

        this.showGrid = !empty;
        this.noAppointments = empty;
    }

    changeTypeAppointment() {
        const values = this.form.value;

        if (values.typeAppointment === this.selectTypeAppointment[0].value) {
            this.appointmentsGrid = this.appointmentsInsideClinic;
        } else {
            this.appointmentsGrid = this.appointmentsOutsideClinic;
        }

        this.checkEmpty();
    }

    formChange() {
        const values = this.form.value;

        if (!this.init) {
            this.init = true;
        }

        const timeout = setTimeout(() => {
            this.preloader = false;
            this.showGrid = false;
            this.showDateChanger = false;
            this.noAppointments = false;
            this.dialogService.showStandardError();
            this.getAppointmentsSubscribe.unsubscribe();
        }, api.requestTimeout);

        let request: GetAppointmentsFiltersInterface = {
            StartDate: dateCompilation(this.startDate),
            DueDate: dateCompilation(this.dueDate),
            Mode: true
        };

        values.specialization && values.specialization.value ? request.SpecializationId = values.specialization.value : null;
        values.specialist && values.specialist.value ? request.SpecialistId = values.specialist.value : null;

        this.noAppointments = false;
        this.preloader = true;

        const eMoment = extendMoment(moment);
        this.dateRange = Array.from(eMoment.range(this.startDate, this.dueDate).by('days'));

        this.getAppointmentsSubscribe = this.clinicService.getAppointments(request)
            .subscribe((response: GetAppointmentsResponseInterface) => {

                !api.production ? console.log('GetAppointmentsResponse', response) : null;

                this.preloader = false;
                clearTimeout(timeout);

                if (response.GetSpecialistScheulderWithAppointmentResult.Success) {
                    let activities = response.GetSpecialistScheulderWithAppointmentResult.AmdinScheduleActivities;

                    !api.production ? console.log('Activities', activities) : null;

                    this.appointmentsInsideClinic = {};
                    this.appointmentsOutsideClinic = {};
                    this.showDateChanger = true;

                    if (!activities) {
                        this.showGrid = false;
                        this.noAppointments = true;
                    } else {
                        this.showGrid = true;
                        this.noAppointments = false;

                        activities.forEach((item) => {
                            if (item.ScheduleReceptionTypes[0].TypeId === this.selectTypeAppointment[1].value) {
                                createAdminAppointmentsGrid(this.appointmentsOutsideClinic, item, this.dateRange);
                            } else {
                                createAdminAppointmentsGrid(this.appointmentsInsideClinic, item, this.dateRange);
                            }
                        });

                        if (values.typeAppointment === this.selectTypeAppointment[0].value) {
                            this.appointmentsGrid = this.appointmentsInsideClinic;
                        } else {
                            this.appointmentsGrid = this.appointmentsOutsideClinic;
                        }

                        this.checkEmpty();

                        !api.production ? console.log('inside', this.appointmentsInsideClinic) : null;
                        !api.production ? console.log('outside', this.appointmentsOutsideClinic) : null;
                    }
                } else {
                    this.dialogService.showStandardError();
                    this.showGrid = false;
                    this.showDateChanger = false;
                    this.noAppointments = false;
                }

            }, () => {
                clearTimeout(timeout);
                this.dialogService.showStandardError();
                this.showGrid = false;
                this.showDateChanger = false;
                this.noAppointments = false;
            });
    }

}
