<ng-template *ngIf="isAppointment"></ng-template>

<mat-dialog-content class="mat-typography">
  <div class="dialog-title">Редактировать запись</div>
  <ng-template [ngIf]="isAppointment">
    <div class="edit-appointment-info-list" *ngIf="isAppointment">
      <div class="user-small-info">
        <div class="image">
          <img *ngIf="!userImage" src="/assets/images/user-man-placeholder.png"  alt="">
          <img *ngIf="userImage" [src]="userImage" alt="">
        </div>
        <div class="content">
          <div class="name">{{client.Name}}</div>
          <div class="contact">{{client.Phone.substr(1) | phone}}</div>
        </div>
      </div>
      <div class="pet-small-info">
        <div class="image">
          <img *ngIf="!petImage" src="/assets/images/pets-placeholder.png" alt="">
          <img *ngIf="petImage" [src]="petImage" alt="">
        </div>
        <div class="content">
          <div class="name">{{pet.Name}}</div>
        </div>
      </div>
      <div class="appointment-small-price-info">
        <div class="item" *ngIf="item.ClinicService.ClinicService">{{item.ClinicService.ClinicService | catalogName:selectClinicServices}} - <span>{{item.ClinicService.Cost}}</span> руб.</div>
        <div class="item" *ngIf="item.ClinicService.CustomText">{{item.ClinicService.CustomText}} - <span>{{item.ClinicService.Cost}}</span> руб.</div>
      </div>
    </div>
    <div class="edit-appointment-controls" *ngIf="isAppointment">
      <button
        type="button"
        class="btn btn-primary btn-small"
        (click)="cancelAppointment()"
      >Отменить прием</button>
      <button
        type="button"
        class="btn btn-primary btn-small"
        (click)="cancelAppointment(true)"
      >Перенести прием</button>
      <mat-checkbox
        [formControl]="actionBlock"
      >Заблокировать запись</mat-checkbox>
    </div>
    <hr>
    <div class="record-info-time">
      <div class="date">{{item.Date | date: 'dd MMMM yyyy'}}</div>
      <div class="time">{{item.TimeString}}</div>
    </div>
    <div class="record-info-list" *ngIf="isAppointment">
      <div class="specialist-small-info">
        <div class="content">
          <div class="name">Адрес приема</div>
          <div class="post">{{client.Address}}</div>
        </div>
      </div>
      <div class="specialist-small-info">
        <div class="image">
          <img src="/assets/images/user-man-placeholder.png" alt="">
        </div>
        <div class="content">
          <div class="name">{{item.SpecialistName}}</div>
          <div class="post">{{item.SpecializationName}}</div>
        </div>
      </div>
      <div class="clinic-small-info">
        <div class="image">
          <img src="/assets/images/clinic-placeholder.png" alt="">
        </div>
        <div class="content">
          <div class="name">{{item.AccountName}}</div>
          <div class="post">{{item.Address}}</div>
        </div>
      </div>
    </div>
  </ng-template>
  <p *ngIf="isBlocked">Запись заблокирована</p>
  <p *ngIf="!isAppointment && !isBlocked">Запись свободна</p>
</mat-dialog-content>
<mat-dialog-actions class="dialog-buttons" align="center">
  <button type="button"
          class="btn btn-default btn-small"
          (click)="closeDialog()">
    Отмена
  </button>
  <button
    *ngIf="!isAppointment && !isBlocked"
    class="btn btn-primary btn-small"
    type="button"
    (click)="blockAppointment(0)"
  >Заблокировать запись</button>
  <button
    *ngIf="isBlocked"
    class="btn btn-primary btn-small"
    type="button"
    (click)="removeBlockAppointment()"
  >Разблокировать запись</button>
</mat-dialog-actions>
