<div class="auth-background">
  <div routerLink="/" class="auth-logo">
    <img src="assets/images/logo-white.svg" alt="">
  </div>

  <div class="auth-background-image">
    <img src="assets/images/auth-image-06.jpg" alt="">
  </div>

  <div class="content-404">
    <div class="center">
      <div class="image-404">
        <img src="assets/images/404.svg" alt="">
      </div>
      <div class="text">Упс! Похоже чей то непослушный питомец съел эту страницу. <br> Попробуйте перейти на главную.</div>
      <button routerLink="/" class="btn btn-primary">На главную</button>
    </div>
  </div>
</div>
