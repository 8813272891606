<mat-dialog-content class="mat-typography">
  <div class="dialog-title">Изменить фото</div>
  <div class="change-photo-wrapper" *ngIf="!preloaderVisibility">
    <div class="preview-photo">
      <img [src]="currentAvatar" alt="" *ngIf="currentAvatar">
      <img src="assets/images/clinic-placeholder.png" alt="" *ngIf="!currentAvatar">
      <div class="new-photo" *ngIf="showPreview"><img [src]="data.image" alt=""></div>
    </div>
    <div class="upload-photo-wrapper">
      <div
        class="upload-photo"
        ngfDrop
        selectable="1"
        [accept]="'image/png, image/jpeg'"
        [(files)]="files"
        [class.error]="showError"
        [(lastInvalids)]="lastInvalidFiles"
        (lastInvalidsChange)="lastInvalidsChange()"
        (filesChange)="changeFiles()">

          <span *ngIf="!showPreview && !showError">
            Выберите файл или <br> перетащите его сюда
          </span>
          <span *ngIf="showError">
            {{errorText}}
          </span>
      </div>
      <div class="file-info"
           *ngIf="showPreview">
        <div class="file-name">
          {{files[0].name}}
        </div>
        <div class="remove-file close"
             (click)="clearFile()">
          <i class="if if-garbage"></i>
        </div>
      </div>
    </div>
    <div #cropperWrapper class="cropper-wrapper">
      <img-cropper #cropper
                   *ngIf="showPreview"
                   [image]="data"
                   [settings]="cropperSettings">
      </img-cropper>
    </div>
  </div>
  <div class="load-local-spinner" *ngIf="preloaderVisibility">
    <img src="assets/images/load-content-spinner.svg" alt="">
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" class="dialog-buttons">
<button type="button" mat-dialog-close class="btn btn-default btn-small">Отмена</button>
    <button type="submit" class="btn btn-primary btn-small" [disabled]="!isFile" (click)="changePhoto()">Сохранить</button>
</mat-dialog-actions>
