import { NgModule } from '@angular/core';
import { AgePipe } from './age.pipe';
import { GenderPipe } from './gender.pipe';
import { PhonePipe } from './phone.pipe';
import { CatalogNamePipe } from './catalog-name.pipe';
import { FilterPipe } from './filter.pipe';

@NgModule({
  declarations: [
    AgePipe,
    GenderPipe,
    PhonePipe,
    CatalogNamePipe,
    FilterPipe
  ],
  exports: [
    AgePipe,
    GenderPipe,
    PhonePipe,
    CatalogNamePipe,
    FilterPipe
  ]
})
export class PipesModule {}
