<div class="account-avatar">
  <div class="image" (click)="changePhoto()">
    <img [src]="avatar" alt="" *ngIf="avatar">
    <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!avatar && userData && userData.Gender == 'Male'">
    <img src="assets/images/user-wooman-placeholder.png" alt="" *ngIf="!avatar && userData && userData.Gender == 'Female'">
    <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!avatar && !userData">
    <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!avatar && userData">
  </div>
  <div class="d-flex justify-content-center">
    <button class="button-transparent btn-large" (click)="changePhoto()">
      <i class="if if-photo-camera"></i>
      Изменить
    </button>
  </div>
</div>
<div class="text-center">
  <button class="btn btn-primary icon-right show-mobile" (click)="toggleNavigation()">Меню профиля <i class="if if-burger-02"></i></button>
</div>
<nav class="account-sidebar-navigation"
     [class.show]="showMobileNavigation">
  <ul>
    <li>
      <a routerLink="/account/info" routerLinkActive="active">
        <i class="if if-user-woman"></i>
        <span class="text">Мой профиль</span>
      </a>
    </li>
    <li>
      <a routerLink="/account/edit" routerLinkActive="active">
        <i class="if if-edit"></i>
        <span class="text">Редактировать профиль</span>
      </a>
    </li>
    <li>
      <a routerLink="/account/change-password" routerLinkActive="active">
        <i class="if if-change-password"></i>
        <span class="text">Изменить пароль</span>
      </a>
    </li>
    <li>
      <a class="disabled-link">
        <i class="if if-settings"></i>
        <span class="text">Настройки</span>
      </a>
    </li>
    <li>
      <a class="disabled-link">
        <i class="if if-alarm"></i>
        <span class="text">Уведомления</span>
      </a>
    </li>
    <li>
      <a class="disabled-link">
        <i class="if if-security"></i>
        <span class="text">Безопасность</span>
      </a>
    </li>
  </ul>
</nav>
