import { ElementRef, EventEmitter, forwardRef, OnChanges, OnInit, Renderer2, SimpleChanges } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { debounce, } from 'rxjs/operators';
import { DaDataConfigDefault } from '../../da-data-config';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DaDataType, DadataService } from '../../services/dadata.service';
var NGX_DADATA_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DadataComponent; }),
    multi: true
};
export function createDaDataValidator(value) {
    return function (c) {
        var err = {
            rangeError: {
                given: c.value,
                expected: value,
            }
        };
        return (c.value !== value) ? err : null;
    };
}
var DadataComponent = /** @class */ (function () {
    function DadataComponent(dataService, _r) {
        this.dataService = dataService;
        this._r = _r;
        this._value = '';
        this.currentFocus = -1;
        this.data = [];
        this.config = DaDataConfigDefault;
        this.disabled = null;
        this.type = DaDataType.address;
        this.limit = DaDataConfigDefault.limit;
        this.placeholder = '';
        this.fromBound = '';
        this.toBound = '';
        this.restrict = false;
        this.locations = [];
        this.parts = [''];
        this.selected = new EventEmitter();
        this.suggestionEvent = new EventEmitter();
        this.inputString$ = new Subject();
        this.onTouched = function () {
        };
        this.propagateChange = function () {
        };
        this.validateFn = function () {
        };
    }
    Object.defineProperty(DadataComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (v) {
            if (v !== this._value) {
                this._value = v;
                this.propagateChange(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    DadataComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.setApiKey(this.apiKey ? this.apiKey : this.config.apiKey);
        this.inputString$.pipe(debounce(function () { return timer(_this.config.delay ? _this.config.delay : 500); })).subscribe(function (x) {
            _this.additional = {
                parts: _this.parts,
                fromBound: _this.fromBound,
                toBound: _this.toBound,
                locations: _this.locations,
                restrict: _this.restrict
            };
            _this.dataService.getData(x, _this.type, _this.limit, _this.additional).subscribe(function (y) {
                _this.data = y.suggestions;
            });
        });
    };
    DadataComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            this.suggestionEvent.emit(changes.value);
        }
    };
    DadataComponent.prototype.onInputBlur = function () {
        !this.disabled ? this.onTouched() : null;
    };
    DadataComponent.prototype.getData = function (value) {
        this.inputString$.next(value);
        this.currentFocus = -1;
    };
    DadataComponent.prototype.onClick = function (e, item) {
        this.inputValue.nativeElement.value = item.value;
        this.propagateChange(item.value);
        this.inputValue.nativeElement.focus();
        this.selectedSuggestion = item;
        this.data = [];
        this.currentFocus = -1;
        this.selected.emit(item);
    };
    DadataComponent.prototype.onOutsideClick = function () {
        this.data = [];
    };
    DadataComponent.prototype.onArrowDown = function () {
        this.removeFocus(this.currentFocus);
        if (this.currentFocus >= this.data.length - 1) {
            this.currentFocus = 0;
        }
        else {
            this.currentFocus++;
        }
        this.setFocus(this.currentFocus);
    };
    DadataComponent.prototype.onArrowUp = function () {
        this.removeFocus(this.currentFocus);
        if (this.currentFocus === 0) {
            this.currentFocus = this.data.length - 1;
        }
        else {
            this.currentFocus--;
        }
        this.setFocus(this.currentFocus);
    };
    DadataComponent.prototype.onEnter = function () {
        this.selectedSuggestion = this.data[this.currentFocus];
        this.inputValue.nativeElement.value = this.selectedSuggestion.value;
        this.data = [];
        this.currentFocus = -1;
        this.propagateChange(this.selectedSuggestion.value);
        // this.writeValue(this.selectedSuggestion.value);
        this.selected.emit(this.selectedSuggestion);
        // this.selectedData.emit(this.selectedSuggestion.data);
        // this.selectedString.emit(this.selectedSuggestion.value);
    };
    DadataComponent.prototype.setFocus = function (id) {
        var activeEl = document.getElementById(id + 'item');
        this._r.addClass(activeEl, 'active');
    };
    DadataComponent.prototype.removeFocus = function (id) {
        if (id !== -1) {
            var activeEl = document.getElementById(id + 'item');
            this._r.removeClass(activeEl, 'active');
        }
    };
    DadataComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this._value = value;
        }
        // this.onSuggestionSelected(value);
    };
    /**
     * Set the function to be called
     * when the control receives a change event.
     *
     * @param fn a function
     */
    DadataComponent.prototype.registerOnChange = function (fn) {
        // this.onSuggestionSelected = fn;
        this.propagateChange = fn;
    };
    /**
     * Set the function to be called
     * when the control receives a touch event.
     *
     * @param fn a function
     */
    DadataComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Implements disabled state for this element
     *
     * @param isDisabled
     */
    DadataComponent.prototype.setDisabledState = function (isDisabled) {
        alert('disabled!');
        this.disabled = isDisabled;
    };
    return DadataComponent;
}());
export { DadataComponent };
