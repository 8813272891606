import { Component, OnInit } from '@angular/core';
import { timepickerTheme } from '../../../shared/const/const';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { ClinicService } from '../../../shared/services/clinic.service';
import { GetClinicSpecialistsResponseInterface } from '../../../shared/interfaces/clinic/get-clinic-specialists-response.interface';
import { Subscription } from 'rxjs/Subscription';
import { api } from '../../../../environments/api';
import { ClinicListSpecialistsInterface } from '../../../shared/interfaces/clinic/clinic-list-specialists.interface';
import { Router } from '@angular/router';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { specializations, typeAppointment } from '../../../shared/catalogs/clinic.catalogs';
import { CurrentSpecialistScheduleResponseInterface } from '../../../shared/interfaces/clinic/schedules/current-specialist-schedule.response.interface';
import { ClinicSpecialistScheduleInterface } from '../../../shared/interfaces/clinic/schedules/clinic-specialist-schedule.interface';
import {
  dateCompilation,
  dateEncodeNoUtc
} from '../../../shared/functions/functions';
import { ReceptionTypesInterface } from '../../../shared/interfaces/clinic/schedules/reception-types.interface';
import { ScheduleTimetablesInterface } from '../../../shared/interfaces/clinic/schedules/schedule-timetables.interface';
import { DatePipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import * as moment from 'moment';
import { extendMoment } from 'moment-range';
import { DialogService } from '../../../shared/services/dialog.service';
import { CustomValidators } from '../../../shared/validators/custom.validators';
import { ClinicSpecialistScheduleResponseInterface } from '../../../shared/interfaces/clinic/schedules/clinic-specialist-schedule-response.interface';
import { CatalogNamePipe } from '../../../shared/pipes/catalog-name.pipe';

@Component({
  selector: 'app-schedule-add',
  templateUrl: './schedule-add.component.html',
  styleUrls: ['./schedule-add.component.scss']
})
export class ScheduleAddComponent implements OnInit {

  preloader: boolean = true;
  specialistSelected: boolean = false;
  scheduleSelected: boolean = false;

  form: FormGroup;
  minDateToday: Date = new Date();
  minDate: Date;
  maxDate: Date;
  minDateDueDate: Date = new Date();
  minTimes = [];
  maxTime = '11:59 pm';

  getClinicSpecialistSubscribe: Subscription;
  getClinicSpecialistSchedules: Subscription;
  addClinicSpecialistSchedule: Subscription;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  timePickerTheme = timepickerTheme;

  specialistId: string = '';

  schedules: ClinicSpecialistScheduleInterface[] = [];
  specialistsList: ClinicListSpecialistsInterface[] = [];

  selectSpecialist: SelectInterface[] = [];
  selectSchedule: SelectInterface[] = [];
  selectSpecialization: SelectInterface[];
  selectTypeId: SelectInterface[] = typeAppointment;

  constructor(
    public clinicService: ClinicService,
    private router: Router,
    private datePipe: DatePipe,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private customValidators: CustomValidators,
    private catalogNamePipe: CatalogNamePipe
  ) {
    iconRegistry.addSvgIcon(
      'clock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/clock.svg'));
  }

  ngOnInit() {
    this.getClinicSpecialist();
    this.initForm();
  }

  getClinicSpecialist() {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getClinicSpecialistSubscribe.unsubscribe();
    }, api.requestTimeout);

    const request = this.clinicService.getClinicFullData().AccountData.AccountId;

    this.getClinicSpecialistSubscribe = this.clinicService.getClinicSpecialists(request)
      .subscribe((response: GetClinicSpecialistsResponseInterface) => {
        !api.production ? console.log('GetSpecialistResult', response) : null;

        clearTimeout(timeout);
        this.preloader = false;

        if (response.GetSpecialistResult.Success) {
          this.specialistsList = response.GetSpecialistResult.Specialists;
          this.initSelect();
        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        this.dialogService.showStandardError();
        clearTimeout(timeout);
      });
  }

  /*
  * Инициализация select
  * */

  initSelect() {
    for (let item of this.specialistsList) {
      this.selectSpecialist.push(
        {
          value: item.SpecialistId,
          name: item.Name
        }
      );
    }
  }

  /*
  * Действие при смене специалиста
  * */

  changeSelectSpecialist(event) {
    this.clearAll();
    this.specialistId = event.value.value;

    this.generateSelectSpecialistSchedules(event.value.value);
  }

  generateSelectSpecialistSchedules(specialistId) {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getClinicSpecialistSchedules.unsubscribe();
    }, api.requestTimeout);

    this.selectSpecialization = [];
    const specialist = this.specialistsList.find(item => item.SpecialistId == specialistId);
    for (let item of specialist.Specialization) {
      this.selectSpecialization.push(
        {
          value: item.Specialization,
          name: this.catalogNamePipe.transform(item.Specialization, specializations)
        }
      );
    }
    !api.production ? console.log('fast-test', specialist) : null;
    !api.production ? console.log('fast-test', this.selectSpecialization) : null;

    this.getClinicSpecialistSchedules ? this.getClinicSpecialistSchedules.unsubscribe() : null;

    /*
    * Получаем список всех графиков выбранного специалиста
    * */

    this.getClinicSpecialistSchedules = this.clinicService.getSpecialistSchedules(specialistId)
      .subscribe((response: CurrentSpecialistScheduleResponseInterface) => {
        !api.production ? console.log('CurrentSpecialistScheduleResponseInterface', response) : null;
        clearTimeout(timeout);

        if (response.CurrentSpecialistScheduleResult.Success) {
          this.specialistSelected = true;

          this.schedules = response.CurrentSpecialistScheduleResult.SpecialistSchedules;

          if (this.schedules.length) {
            /*
            * Добавляем Id и название графиков в select для выбора готовых графиков
            * */
            for (let item of this.schedules) {

              let startDate = item.StartDate;
              let dueDate = item.DueDate;
              startDate = this.datePipe.transform(dateEncodeNoUtc(startDate), 'dd MMMM yyyy');
              dueDate = this.datePipe.transform(dateEncodeNoUtc(dueDate), 'dd MMMM yyyy');

              this.selectSchedule.push(
                {
                  'name': startDate + ' - ' + dueDate,
                  'value': item.Id
                }
              );
            }
          }

          this.selectSchedule.unshift({name: 'Новый график', value: 'add-new'});

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        this.dialogService.showStandardError();
        clearTimeout(timeout);
      });
  }

  /*
  * Действие при смене графика
  * */

  changeSelectSchedule(event) {

    this.clearForm();

    if (event.value.value == 'add-new') {
      this.scheduleSelected = true;
      this.minTimes = [];
    } else {

      const schedule = this.schedules.filter(x => x.Id === event.value.value)[0] as ClinicSpecialistScheduleInterface;
      this.minTimes = [];

      const startDate = dateEncodeNoUtc(schedule.StartDate);
      const dueDate = dateEncodeNoUtc(schedule.DueDate);

      !api.production ? console.log('startDate', startDate) : null;
      !api.production ? console.log('dueDate', dueDate) : null;

      const m = extendMoment(moment);

      const range = m.range(startDate, dueDate);

      let daysArrays = Array.from(range.by('days'));

      const newDueDate = moment().add({days: daysArrays.length - 1});

      this.form.patchValue(
        {
          'startDate': new Date(),
          'dueDate': newDueDate,
        }
      );

      this.minDate = new Date();
      this.maxDate = new Date(newDueDate.valueOf());

      this.form.controls.dueDate.enable();

      const receptionTypes: ReceptionTypesInterface[] = schedule.ReceptionTypes;

      if (receptionTypes.length) {

        const formReceptionTypes: FormArray = this.form.get('receptionTypes') as FormArray;

        let i = 0;

        for (let receptionType of receptionTypes) {

          /*
          * Перебираем и добавляем receptionTypes если они есть
          * */

          formReceptionTypes.push(
            new FormGroup(
              {
                'duration': new FormControl(parseInt(String(receptionType.Duration)), [Validators.required]),
                'price': new FormControl(receptionType.Price, [Validators.required]),
                'typeId': new FormControl(receptionType.TypeId, [Validators.required]),
                'specializationId': new FormControl(receptionType.SpecializationId, [Validators.required]),
                'scheduleTimetables': new FormArray([])
              }
            )
          );

          this.minTimes.push([]);

          const scheduleTimetables: ScheduleTimetablesInterface[] = receptionType.ScheduleTimetables;

          scheduleTimetables.sort((a, b) => <any>(dateEncodeNoUtc(a.Date)) - <any>(dateEncodeNoUtc(b.Date)));

          if (scheduleTimetables.length) {

            /*
            * Перебираем и добавляем scheduleTimetables если они есть
            * */

            const formScheduleTimetables: FormArray = formReceptionTypes.controls[i].get('scheduleTimetables') as FormArray;

            let j = 0;

            for (let scheduleTimetable of scheduleTimetables) {

              let startTimeConvert = scheduleTimetable.StartDate ? this.datePipe.transform(dateEncodeNoUtc(scheduleTimetable.StartDate), 'HH:mm') : null;
              let endTimeConvert = scheduleTimetable.DueDate ? this.datePipe.transform(dateEncodeNoUtc(scheduleTimetable.DueDate), 'HH:mm') : null;

              if (scheduleTimetable.StartDate) {
                let minTime = moment(dateEncodeNoUtc(scheduleTimetable.StartDate));
                minTime.add(receptionType.Duration, 'minute');
                this.minTimes[i].push(minTime.format('kk:mm'));
              } else {
                this.minTimes[i].puqsh(null);
              }

              let newDate = null;

              if (scheduleTimetable.Date) {
                const diffDate = moment(dateEncodeNoUtc(scheduleTimetable.Date)).diff(startDate, 'days');
                newDate = moment().add({days: diffDate});
              }

              formScheduleTimetables.push(
                new FormGroup(
                  {
                    'date': new FormControl(newDate, [Validators.required]),
                    'startTime': new FormControl(startTimeConvert, [Validators.required]),
                    'dueTime': new FormControl(endTimeConvert, [Validators.required, this.customValidators.minTimeSchedule])
                  }
                )
              );

              formScheduleTimetables.controls[j].get('date').markAsTouched();
              formScheduleTimetables.controls[j].get('startTime').markAsTouched();
              formScheduleTimetables.controls[j].get('dueTime').markAsTouched();

              j++;
            }
          }

          i++;
        }
      }

      !api.production ? console.log('form', this.form) : null;
      !api.production ? console.log('fast-test', this.minTimes) : null;

      this.scheduleSelected = true;
    }
  }

  /*
  * Очистка всех переменных
  * */

  clearAll() {
    this.specialistSelected = false;
    this.scheduleSelected = false;
    this.selectSchedule = [];
    this.schedules = [];
    this.minTimes = [];
    this.minDate = null;
    this.maxDate = null;
    this.clearForm();
  }

  /*
  * Очистка формы
  * */

  clearForm() {
    this.form.get('startDate').setValue(null);
    this.form.get('dueDate').setValue(null);

    const receptionTypes: FormArray = this.form.get('receptionTypes') as FormArray;

    while (receptionTypes.length !== 0) {
      receptionTypes.removeAt(0);
    }
  }

  /*
  * Создание формы
  * */

  initForm() {
    this.form = new FormGroup(
      {
        'startDate': new FormControl(null, [Validators.required]),
        'dueDate': new FormControl({value: null, disabled: true}, [Validators.required]),
        'receptionTypes': new FormArray([], [Validators.required])
      }
    );
  }

  /*
  * Создание массива ReceptionTypes для формы
  * */

  initReceptionTypes() {
    return new FormGroup(
      {
        'duration': new FormControl(null, [Validators.required]),
        'price': new FormControl(null, [Validators.required]),
        'typeId': new FormControl(null, [Validators.required]),
        'specializationId': new FormControl(null, [Validators.required]),
        'scheduleTimetables': new FormArray([], [Validators.required])
      }
    );
  }

  /*
  * Создание массива ScheduleTimetables для формы
  * */

  initScheduleTimetables() {
    return new FormGroup(
      {
        'date': new FormControl(null, [Validators.required]),
        'startTime': new FormControl(null, [Validators.required]),
        'dueTime': new FormControl(null, [Validators.required, this.customValidators.minTimeSchedule])
      }
    );
  }

  addReceptionType() {
    const controls: FormArray = this.form.get('receptionTypes') as FormArray;

    controls.push(
      this.initReceptionTypes()
    );

    const i = controls.controls.length - 1;

    const formScheduleTimetables: FormArray = controls.controls[i].get('scheduleTimetables') as FormArray;
    const startDate = this.form.controls.startDate.value;
    const dueDate = this.form.controls.dueDate.value;


    this.minTimes.push([]);

    if (startDate && dueDate) {

      const m = extendMoment(moment);

      const range = m.range(startDate, dueDate);

      let daysArrays = Array.from(range.by('days'));
      let i = this.minTimes.length - 1;

      for (let day of daysArrays) {
        this.minTimes[i].push(null);

        formScheduleTimetables.push(
          new FormGroup(
            {
              'date': new FormControl(day, [Validators.required]),
              'startTime': new FormControl(null, [Validators.required]),
              'dueTime': new FormControl(null, [Validators.required, this.customValidators.minTimeSchedule])
            }
          )
        );
      }
    }
  }

  addScheduleTimetables(i) {
    const parent: FormArray = this.form.get('receptionTypes') as FormArray;
    const controls: FormArray = parent.controls[i].get('scheduleTimetables') as FormArray;

    controls.push(
      this.initScheduleTimetables()
    );

    this.minTimes[i].push(null);
  }

  getReceptionTypes(form) {
    return form.controls.receptionTypes.controls;
  }

  getScheduleTimetables(form) {
    return form.controls.scheduleTimetables.controls;
  }

  removeReceptionType(i) {
    const controls: FormArray = this.form.get('receptionTypes') as FormArray;
    controls.removeAt(i);
    this.minTimes.splice(i, 1);
  }

  removeScheduleTimetables(i, j) {
    const controls: FormArray = this.form.get(['receptionTypes', i, 'scheduleTimetables']) as FormArray;
    controls.removeAt(j);
    this.minTimes[i].splice(j, 1);
  }

  changeStartDate(event) {
    this.minDateDueDate = event.value;
    this.minDate = event.value;

    this.form.get('dueDate').markAsTouched();
    this.form.get('dueDate').updateValueAndValidity();

    this.form.controls.startDate.valid ? this.form.controls.dueDate.enable() : this.form.controls.dueDate.disable();
  }

  changeDueDate(event) {
    this.maxDate = event.value;

    let receptionTypes: FormArray = this.form.controls.receptionTypes as FormArray;

    this.form.controls.dueDate.valid && !receptionTypes.length
      ? this.addReceptionType()
      : null;
  }

  changeMinTime(event, i, j, duration, scheduleTimetable) {
    let minTime = moment(new Date());
    const splitTime = event.split(':');
    minTime.hour(splitTime[0]).minute(splitTime[1]);
    this.minTimes[i][j] = minTime.add(duration, 'minutes').format('kk:mm');

    setTimeout(() => {
      scheduleTimetable.get('dueTime').updateValueAndValidity();
    }, 100);

  }

  onSubmit() {
    const values = this.form.value;

    !api.production ? console.log('form', this.form) : null;

    let receptionTypes: ReceptionTypesInterface[] = [];
    let i = 0;

    for (let receptionType of values.receptionTypes) {
      receptionTypes.push(
        {
          Name: 'reception',
          TypeId: receptionType.typeId,
          Duration: receptionType.duration,
          Price: parseInt(receptionType.price),
          SpecializationId: receptionType.specializationId
        }
      );

      if (receptionType.scheduleTimetables) {
        let scheduleTimetables: ScheduleTimetablesInterface[] = [];

        for (let scheduleTimetable of values.receptionTypes[i].scheduleTimetables) {

          const startTime = scheduleTimetable.startTime;
          const dueTime = scheduleTimetable.dueTime;
          let startDate = scheduleTimetable.date;
          let dueDate = moment(startDate);

          let startTimeSplit = startTime.split(':');
          let dueTimeSplit = dueTime.split(':');

          const newStartTime = startDate.hour(
            parseInt(startTimeSplit[0])
          ).minute(
            parseInt(startTimeSplit[1])
          ).second(0);

          const newDueTime = dueDate.hour(
            parseInt(dueTimeSplit[0])
          ).minute(
            parseInt(dueTimeSplit[1])
          ).second(0);

          newDueTime < newStartTime ? newDueTime.add(1, 'day') : null;

          scheduleTimetables.push(
            {
              Date: dateCompilation(scheduleTimetable.date),
              StartDate: dateCompilation(newStartTime),
              DueDate: dateCompilation(newDueTime)
            }
          );
        }

        receptionTypes[i].ScheduleTimetables = scheduleTimetables;
      }
    }

    const startDateMain = moment(values.startDate).startOf('day');
    const dueDateMain = moment(values.dueDate).endOf('day');

    let request: ClinicSpecialistScheduleInterface = {
      SpecialistId: this.specialistId,
      StartDate: dateCompilation(startDateMain),
      DueDate: dateCompilation(dueDateMain)
    };

    request.ReceptionTypes = receptionTypes;

    !api.production ? console.log('request', request) : null;

    !api.production ? console.log('fast-test', JSON.stringify(request)) : null;

    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.addClinicSpecialistSchedule.unsubscribe();
    }, api.requestTimeout);

    this.addClinicSpecialistSchedule = this.clinicService.addClinicSpecialistSchedule(request)
      .subscribe((response: ClinicSpecialistScheduleResponseInterface) => {
        !api.production ? console.log('CreateSpecialistScheduleResult', response) : null;
        clearTimeout(timeout);

        if (response.CreateSpecialistScheduleResult.Success) {
          this.clinicService.openStandardDialog(
            'Вы успешно создали график работы',
            'Вы создали график работы для специалиста. Не забудьте его опубликовать!'
          );

          this.selectSchedule = [];
          this.schedules = [];
          this.clearAll();
          this.generateSelectSpecialistSchedules(this.specialistId);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        this.dialogService.showStandardError();
        clearTimeout(timeout);
      });
  }


}
