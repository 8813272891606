import { ElementRef, EventEmitter, forwardRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomValidators } from '../../validators/custom.validators';
var NGX_DADATA_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return ForceAutocompleteComponent; }),
    multi: true
};
var ForceAutocompleteComponent = /** @class */ (function () {
    function ForceAutocompleteComponent(customValidators) {
        this.customValidators = customValidators;
        this._value = '';
        this.filteredOptions = [];
        this.required = false;
        this.label = '';
        this.disabled = null;
        this.selectOptions = [];
        this.control = new FormControl(null, [this.customValidators.autofillForceRequire]);
        this.optionSelected = new EventEmitter;
        this.onTouched = function () {
        };
        this.propagateChange = function () {
        };
    }
    ForceAutocompleteComponent.prototype.ngOnInit = function () {
        this.filteredOptions = this.selectOptions.slice();
        console.log(this.disabled);
        if (this.disabled) {
            this.control.disable();
        }
        else {
            this.control.enable();
        }
    };
    ForceAutocompleteComponent.prototype.onOptionSelected = function (e) {
        this.optionSelected.emit(e.option);
    };
    Object.defineProperty(ForceAutocompleteComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (v) {
            if (v !== this._value) {
                this._value = v;
                this.propagateChange(v);
            }
        },
        enumerable: true,
        configurable: true
    });
    ForceAutocompleteComponent.prototype.ngOnChanges = function (changes) {
        if (changes.value) {
            if (this.disabled) {
                this.control.disable();
            }
            else {
                this.control.enable();
            }
        }
    };
    ForceAutocompleteComponent.prototype.onInputBlur = function () {
        !this.disabled ? this.onTouched() : null;
    };
    ForceAutocompleteComponent.prototype.writeValue = function (value) {
        if (value !== undefined) {
            this._value = value;
        }
    };
    ForceAutocompleteComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.selectOptions.filter(function (option) { return option.name.toLowerCase().includes(filterValue); });
    };
    ForceAutocompleteComponent.prototype.changeAutoFill = function (event) {
        this.filteredOptions = this._filter(event.target.value);
    };
    ForceAutocompleteComponent.prototype.displayWith = function (obj) {
        return obj ? obj.name : undefined;
    };
    /**
     * Set the function to be called
     * when the control receives a change event.
     *
     * @param fn a function
     */
    ForceAutocompleteComponent.prototype.registerOnChange = function (fn) {
        // this.onSuggestionSelected = fn;
        this.propagateChange = fn;
    };
    /**
     * Set the function to be called
     * when the control receives a touch event.
     *
     * @param fn a function
     */
    ForceAutocompleteComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Implements disabled state for this element
     *
     * @param isDisabled
     */
    ForceAutocompleteComponent.prototype.setDisabledState = function (isDisabled) {
        this.disabled = isDisabled;
    };
    return ForceAutocompleteComponent;
}());
export { ForceAutocompleteComponent };
