<div class="main-container">

  <div class="row specialist-in-clinic-list" *ngIf="!preloader">
    <div class="col-xl-3 col-lg-4 col-md-6" *ngFor="let specialist of specialistsList; let i = index;">
      <div class="card-specialist-in-clinic">
        <div class="image">
          <img *ngIf="!specialist.Link" src="/assets/images/user-man-placeholder.png" alt="" >
          <img *ngIf="specialist.Link" src="{{specialist.Link}}" alt="">
        </div>
        <div class="caption">
          <div class="name">{{specialist.Name}}</div>
          <div class="post" *ngIf="specialist.Specialization.length">
            {{specialist.Specialization[0].Specialization | catalogName:doctorSpecializationCatalog}}
            <div class="more-popup"
                 *ngIf="specialist.Specialization.length > 1">
              <div class="dots-link">и Еще {{specialist.Specialization.length - 1}}</div>
              <div class="hidden-more-popup">
                <div *ngFor="let specialization of specialist.Specialization | slice:1; let i = index;">
                  {{specialization.Specialization | catalogName:doctorSpecializationCatalog}}
                </div>
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-small" (click)="checkBeforeRemove(specialist.SpecialistId, i)">Уволить</button>
        </div>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6">
      <div class="card-add" (click)="addSpecialist()">
        <div class="centered">
          <i class="if if-add-round icon-gradient"></i>
          <div class="text">Добавить специалиста</div>
        </div>
      </div>
    </div>
  </div>

  <app-preloader *ngIf="preloader"></app-preloader>
</div>
