<div class="main-container">
  <div class="form-group" *ngIf="!preloader">
    <app-force-autocomplete
      label="Врач"
      [required]="true"
      [selectOptions]="selectSpecialist"
      (optionSelected)="changeSelectSpecialist($event)"
    ></app-force-autocomplete>

    <div class="row flex-lg-nowrap" *ngIf="specialistSelected">
      <div class="col">
        <!--<mat-form-field >
          <mat-label>Выберите график <i class="danger">*</i></mat-label>
          <mat-select (selectionChange)="changeSelectSchedule($event)">
            <mat-option *ngFor="let option of selectSchedule"
                        [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>-->
        <app-force-autocomplete
          label="Выберите график"
          [disabled]="scheduleDisabled"
          [required]="true"
          [selectOptions]="selectSchedule"
          (optionSelected)="changeSelectSchedule($event)"
        ></app-force-autocomplete>
      </div>
      <div class="col-lg-auto">
        <button class="btn btn-primary"
                (click)="checkRemoveSchedule()"
                [disabled]="!scheduleId"
                matRipple>
           Удалить
        </button>
      </div>
      <div class="col-lg-auto">
        <button class="btn btn-primary"
                (click)="checkPublicationSchedule()"
                [disabled]="!scheduleId"
                matRipple>
          Опубликовать
        </button>
      </div>
    </div>
  </div>

  <form *ngIf="scheduleSelected && !preloader"
        class="form-group"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">

    <input type="hidden" formControlName="id">

    <div class="row">

      <div class="col-lg-6">
        <mat-form-field>
          <mat-label>Дата начала <i class="danger">*</i></mat-label>
          <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startDate"
            (dateChange)="changeStartDate($event)"
            (focus)="startDate.open()">

          <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>

          <mat-error *ngIf="form.get('startDate').hasError('required')">
            Поле не может быть пустым
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-lg-6">
        <mat-form-field>
          <mat-label>Дата окончания <i class="danger">*</i></mat-label>
          <input
            matInput
            formControlName="dueDate"
            [min]="minDateDueDate"
            [matDatepicker]="dueDate"
            (dateChange)="changeEndDate($event)"
            (focus)="dueDate.open()">

          <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
          <mat-datepicker #dueDate></mat-datepicker>

          <mat-error *ngIf="form.get('dueDate').hasError('required')">
            Поле не может быть пустым
          </mat-error>
          <mat-error *ngIf="form.get('dueDate').hasError('matDatepickerMin')">
            Дата окончания не может быть меньше даты начала
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div formArrayName="receptionTypes">
      <br>
      <div class="main-info-block" *ngFor="let receptionType of getReceptionTypes(form); let i = index">
        <div class="info-block-background" [formGroupName]="i">
          <button type="button"
                  class="remove-field"
                  (click)="removeReceptionType(i)"
                  *ngIf="!receptionType.get('id').value">
            <i class="if if-close"></i>
          </button>

          <h4>
            {{receptionType.get('specializationId').value | catalogName:selectSpecialization}}
          </h4>

          <input type="hidden" formControlName="id">

          <div class="row">
            <div class="col-lg-6">
              <mat-form-field>
                <mat-label>Специализация <i class="danger">*</i></mat-label>
                <mat-select formControlName="specializationId">
                  <mat-option *ngFor="let option of selectSpecialization"
                              [value]="option.value">
                    {{option.name}}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="receptionType.get('specializationId').hasError('required')">
                  Поле не может быть пустым
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field>
                <mat-label>Длительность приема <i class="danger">*</i></mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="duration">

                <mat-hint align="end">минуты</mat-hint>
                <mat-error *ngIf="receptionType.get('duration').hasError('required')">
                  Поле не может быть пустым
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field>
                <mat-label>Цена приема <i class="danger">*</i></mat-label>
                <input
                  type="number"
                  matInput
                  formControlName="price">

                <mat-hint align="end">руб.</mat-hint>
                <mat-error *ngIf="receptionType.get('price').hasError('required')">
                  Поле не может быть пустым
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-lg-6">
              <mat-form-field>
                <mat-label>Тип приема <i class="danger">*</i></mat-label>
                <mat-select formControlName="typeId">
                  <mat-option *ngFor="let option of selectTypeId"
                              [value]="option.value">
                    {{option.name}}
                  </mat-option>
                </mat-select>

                <mat-error *ngIf="receptionType.get('typeId').hasError('required')">
                  Поле не может быть пустым
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div formArrayName="scheduleTimetables">
            <br>
            <div class="main-info-block"
                 *ngFor="let scheduleTimetable of getScheduleTimetables(receptionType); let j = index">
              <div class="info-block-background" [formGroupName]="j">

                <h4>
                  {{scheduleTimetable.get('date').value | date:'EEEE' | titlecase}}
                </h4>

                <button type="button"
                        class="remove-field"
                        (click)="removeScheduleTimetables(i, j)"
                        *ngIf="!scheduleTimetable.get('id').value">
                  <i class="if if-close"></i>
                </button>

                <input type="hidden" formControlName="id">

                <div class="row">

                  <div class="col-lg-4">
                    <mat-form-field>
                      <mat-label>Дата <i class="danger">*</i></mat-label>
                      <input
                        matInput
                        formControlName="date"
                        [min]="minDate"
                        [max]="maxDate"
                        [matDatepicker]="date"
                        (focus)="date.open()">

                      <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                      <mat-datepicker #date></mat-datepicker>

                      <mat-error *ngIf="scheduleTimetable.get('date').hasError('required')">
                        Поле не может быть пустым
                      </mat-error>
                      <mat-error *ngIf="scheduleTimetable.get('date').hasError('matDatepickerMin')">
                        Дата не может быть меньше выбраной
                      </mat-error>
                      <mat-error *ngIf="scheduleTimetable.get('date').hasError('matDatepickerMax')">
                        Дата не может быть больше выбраной
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field>
                      <mat-label>Время начала <i class="danger">*</i></mat-label>
                      <input
                        type="text"
                        matInput
                        readonly
                        formControlName="startTime"
                        [max]="maxTime"
                        [ngxTimepicker]="startTime"
                        [format]="24">
                      <ngx-material-timepicker-toggle [for]="startTime" matSuffix>
                        <mat-icon ngxMaterialTimepickerToggleIcon svgIcon="clock"></mat-icon>
                      </ngx-material-timepicker-toggle>
                      <ngx-material-timepicker #startTime
                                               (timeSet)="changeMinTime($event, i, j, receptionType.get('duration').value, scheduleTimetable)"
                                               [ngxMaterialTimepickerTheme]="timePickerTheme">
                      </ngx-material-timepicker>

                      <mat-error *ngIf="scheduleTimetable.get('startTime').hasError('required')">
                        Поле не может быть пустым
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="col-lg-4">
                    <mat-form-field>
                      <mat-label>Время окончания <i class="danger">*</i></mat-label>
                      <input
                        type="text"
                        matInput
                        readonly
                        formControlName="dueTime"
                        [min]="minTimes[i][j]"
                        [max]="maxTime"
                        [ngxTimepicker]="dueTime"
                        [format]="24">
                      <ngx-material-timepicker-toggle [for]="dueTime" matSuffix>
                        <mat-icon ngxMaterialTimepickerToggleIcon svgIcon="clock"></mat-icon>
                      </ngx-material-timepicker-toggle>
                      <ngx-material-timepicker #dueTime
                                               [ngxMaterialTimepickerTheme]="timePickerTheme">
                      </ngx-material-timepicker>

                      <mat-error *ngIf="scheduleTimetable.get('dueTime').hasError('required')">
                        Поле не может быть пустым
                      </mat-error>
                      <mat-error *ngIf="scheduleTimetable.get('dueTime').hasError('minTimeSchedule')">
                        Вермя окончания не может быть меньше времени начала
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <button type="button"
                  class="btn btn-default"
                  (click)="addScheduleTimetables(i)"
                  matRipple>
            Добавить время
          </button>
        </div>
      </div>

    </div>
    <div class="last-buttons">
      <button type="button"
              class="btn btn-default"
              (click)="addReceptionType()"
              matRipple>
        Добавить прием
      </button>

      <button type="submit"
              class="btn btn-primary"
              [disabled]="form.invalid"
              matRipple>
        Сохранить
      </button>
    </div>
  </form>

  <div class="load-local-spinner" *ngIf="preloader">
    <img src="/assets/images/load-content-spinner.svg" alt="">
  </div>
</div>
