<div class="autocomplete">
  <!--<label for="address_input"></label>-->
  <input [disabled]="disabled  ? true : null"
         type="text"
         name="address"
         class="search form-control"
         #inputValue
         (input)="getData(inputValue.value)"
         [placeholder]="placeholder"
         (keyup.ArrowDown)="onArrowDown()"
         (keyup.ArrowUp)="onArrowUp()"
         (keyup.Enter)="onEnter()"
         (blur)="onInputBlur()"
         spellcheck="false"
         [(ngModel)]="value"
         [ngModel]="restrict"
         [ngModel]="locations"
         autocomplete="new-password" />
  <div *ngIf="data.length">
    <div class="autocomplete-items">
      <div class="autocomplele-item" *ngFor="let item of data;let i = index" (click)="onClick($event, item)" [id]="i+'item'">
        {{item.value}}
      </div>
    </div>
  </div>
</div>
