<div class="content-wrapper">
  <div class="main-title">
    Управление прайс листом
  </div>
  <div class="main-container" *ngIf="!preloader">
    <p *ngIf="!directions.length">В вашей клиники не создано направлений, <a [routerLink]="['/clinic', clinicService.activeClinic, 'edit']">добавте направления</a> что бы начать работу с прайс-листом.</p>
    <div class="cloud-navigation" *ngIf="directions.length">
      <div class="name">Навигация по направлениям:</div>
      <a *ngFor="let direction of directions; let i = index;"
         [href]="'/clinic/'+ clinicService.activeClinic +'/price#direction-' + i" class="cloud">
        {{direction | catalogName: selectClinicActions}}
      </a>
    </div>
    <form class="form-group"
          [formGroup]="form"
          (ngSubmit)="onSubmit()"
          *ngIf="directions.length">

      <div formArrayName="directions">

        <div *ngFor="let formDirection of getFormDirections(form); let i = index;">
          <h2 id="direction-{{i}}" class="price-direction-title">{{directions[i] | catalogName: selectClinicActions}}
            <button type="button"
                    class="if if-add-round"
                    (click)="addFormService(i)"></button>
          </h2>

          <div [formGroupName]="i">
            <div class="main-info-block-wrapper"
                 formArrayName="services"
                 *ngIf="getFormServices(formDirection).length">
              <div class="main-info-block" *ngFor="let price of getFormServices(formDirection); let j = index">
                <div class="info-block-background" [formGroupName]="j">
                  <button type="button"
                          class="remove-field"
                          (click)="removeFormService(i, j)">
                    <i class="if if-close"></i>
                  </button>

                  <input type="hidden" formControlName="id">

                  <div class="row">
                    <div class="col-lg-6">
                      <mat-form-field>
                        <mat-label>Услуга <i class="danger">*</i></mat-label>

                        <input type="text"
                               matInput
                               formControlName="clinicService"
                               [matAutocomplete]="auto"
                               (keyup)="changeAutoFill($event)">

                        <mat-error *ngIf="price.get('clinicService').hasError('required')">
                          Поле не может быть пустым
                        </mat-error>
                      </mat-form-field>

                      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn(filteredOptions)">
                        <mat-option *ngFor="let option of filteredOptions" [value]="option.value">
                          {{option.name}}
                        </mat-option>
                      </mat-autocomplete>

                    </div>
                    <div class="col-lg-3">
                      <mat-form-field>
                        <mat-label>Тип приема <i class="danger">*</i></mat-label>
                        <mat-select formControlName="type">
                          <mat-option *ngFor="let option of selectTypeId"
                                      [value]="option.value">
                            {{option.name}}
                          </mat-option>
                        </mat-select>

                        <mat-error *ngIf="price.get('type').hasError('required')">
                          Поле не может быть пустым
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field>
                        <mat-label>Цена <i class="danger">*</i></mat-label>
                        <input
                          type="number"
                          matInput
                          formControlName="cost">

                        <mat-error *ngIf="price.get('cost').hasError('required')">
                          Поле не может быть пустым
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p *ngIf="!getFormServices(formDirection).length">У вас нет услуг по этому направлению. Хотите добавить?</p>
            <br>
            <br>
            <div class="text-center">
              <button type="button" class="btn btn-primary"
                      (click)="addFormService(i)">
                Добавить
              </button>
            </div>
            <hr>
          </div>
        </div>
      </div>

      <div class="last-buttons">
        <button type="button" class="btn btn-default" [routerLink]="'/clinic/'+ clinicService.activeClinic">Отмена</button>
        <button type="submit"
                [disabled]="form.invalid"
                class="btn btn-primary">
          Сохранить
        </button>
      </div>
    </form>
  </div>
  <app-preloader *ngIf="preloader"></app-preloader>
</div>
