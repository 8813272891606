import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppointmentsTimeGridInterface } from '../../../interfaces/appointments/appointments-time-grid.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { api } from '../../../../../environments/api';
import { SelectInterface } from '../../../interfaces/select.interface';
import { ClinicService } from '../../../services/clinic.service';
import { DialogService } from '../../../services/dialog.service';
import { UserService } from '../../../services/user.service';
import { ClinicPriceListInterface } from '../../../interfaces/clinic/price-list/clinic-price-list.interface';
import { CatalogNamePipe } from '../../../pipes/catalog-name.pipe';
import { clinicServices } from '../../../catalogs/clinic.catalogs';
import { CreateAppointmentNewUserComponent } from '../create-appointment-new-user/create-appointment-new-user.component';
import { CreateAppointmentSearchUserComponent } from '../create-appointment-search-user/create-appointment-search-user.component';
import { ActivatedRoute } from '@angular/router';
import { AppointmentsMoveQueryParamsInterface } from '../../../interfaces/appointments/appointments-move-query-params.interface';
import { CreateAppointmentMoveComponent } from '../create-appointment-move/create-appointment-move.component';


@Component({
  selector: 'app-create-appointment-dialog',
  templateUrl: './create-appointment-dialog.component.html',
  styleUrls: ['./create-appointment-dialog.component.scss']
})
export class CreateAppointmentDialogComponent implements OnInit {

  appointment: AppointmentsTimeGridInterface = this.dialogData.item;

  preloader: boolean = true;

  @ViewChild('newUserComponent')
  private newUserComponent: CreateAppointmentNewUserComponent;

  @ViewChild('searchUserComponent')
  private searchUserComponent: CreateAppointmentSearchUserComponent;

  @ViewChild('moveComponent')
  private moveAppointmentComponent: CreateAppointmentMoveComponent;

  getClinicServices: ClinicPriceListInterface[];

  selectServices: SelectInterface[] = [];

  userType = 'new';

  success = false;
  moveAppointment = false;
  queryAppointment: AppointmentsMoveQueryParamsInterface;

  showNewUser: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    public dialogRef: MatDialogRef<CreateAppointmentDialogComponent>,
    private clinicService: ClinicService,
    private dialogService: DialogService,
    private userService: UserService,
    private catalogNamePipe: CatalogNamePipe,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    !api.production ? console.log('dialogData', this.dialogData) : null;

    this.getClinicServices = this.clinicService.getClinicFullData().AccClinicService;

    for (let item of this.getClinicServices) {
      if (item.ClinicService) {
        this.selectServices.push(
          {
            name: this.catalogNamePipe.transform(item.ClinicService, clinicServices),
            value: item.Id
          }
        );
      } else {
        this.selectServices.push(
          {
            name: item.CustomText,
            value: item.Id
          }
        );
      }
    }

    this.route.queryParams.subscribe((params:AppointmentsMoveQueryParamsInterface) => {
      !api.production ? console.log('params', params) : null;
      if(params && params.MoveAppointment) {
        this.moveAppointment = true;
        this.queryAppointment = params;
        this.userType = 'move';
      }

      this.preloader = false;
    });

    this.changeDetectorRef.detectChanges();
  }

  changeUserType(event) {
    console.log(event);
    this.userType == 'new' ? this.showNewUser = true : this.showNewUser = false;
    this.changeDetectorRef.detectChanges();
  }

  closeDialog() {
    this.dialogRef.close({
      success: this.success,
      index: this.dialogData.index
    });
  }

  setSuccess() {
    this.success = true;
    this.changeDetectorRef.detectChanges();
  }


}
