<div class="row flex-lg-nowrap" *ngIf="!hideAll && !searchPreloader">
  <div class="col">
    <mat-form-field>
      <mat-label>Телефон клиента <i class="danger">*</i></mat-label>
      <input type="text"
             matInput
             [textMask]="phoneMask"
             [formControl]="searchUserControl">
      <mat-error *ngIf="searchUserControl.hasError('required')">
        Поле не может быть пустым
      </mat-error>
      <mat-error *ngIf="searchUserControl.hasError('phone')">
        Неверный формат телефона
      </mat-error>
    </mat-form-field>
  </div>
  <div class="col-lg-auto">
    <button
      type="button"
      class="btn btn-primary no-margin"
      (click)="searchUser()"
      [disabled]="searchUserControl.invalid">
      Поиск
    </button>
  </div>
</div>

<form class="form-group"
      *ngIf="!hideForm && !hideAll"
      [formGroup]="form">

  <div class="row text-left">
    <div class="col-lg-6 main-info-block" *ngIf="user && user.Firstname">
      <div class="title">Имя</div>
      <div class="text">{{user.Firstname}}</div>
    </div>
    <div class="col-lg-6 main-info-block" *ngIf="user && user.Surname">
      <div class="title">Фамилия</div>
      <div class="text">{{user.Surname}}</div>
    </div>
  </div>
  <mat-form-field>
    <mat-label>Питомец <i class="danger">*</i></mat-label>
    <mat-select
      formControlName="pet">
      <mat-option *ngFor="let option of selectPets"
                  [value]="option.value">
        {{option.name}}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.get('pet').hasError('required')">
      Поле не может быть пустым
    </mat-error>
  </mat-form-field>
  <dadata-mat [type]="'address'"
              fromBound="city"
              toBound="house"
              limit="5"
              id="address"
              placeholder="Адрес"
              *ngIf="needAddress"
              [required]="true"
              [restrict]="true"
              [control]="form.controls.address"
              formControlName="address">
  </dadata-mat>
  <app-force-autocomplete
    formControlName="service"
    label="Услуга"
    [control]="form.controls.service"
    [required]="true"
    [selectOptions]="selectServices"
  ></app-force-autocomplete>
  <mat-form-field>
    <mat-label>Жалоба <i class="danger">*</i></mat-label>
    <textarea
      matInput
      mat-autosize
      formControlName="complaint">
    </textarea>
    <mat-error *ngIf="form.get('complaint').hasError('required')">
      Поле не может быть пустым
    </mat-error>
  </mat-form-field>
</form>

<app-preloader [static]="true"
               *ngIf="searchPreloader"
></app-preloader>
