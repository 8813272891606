import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../../environments/api';
import { CustomValidators } from '../../../shared/validators/custom.validators';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { specializations } from '../../../shared/catalogs/clinic.catalogs';
import { Subscription } from 'rxjs/Subscription';
import { AuthService } from '../../../shared/services/auth.service';
import { ClinicService } from '../../../shared/services/clinic.service';
import { UserService } from '../../../shared/services/user.service';
import { concatMap, tap } from 'rxjs/operators';
import { UserIdAndSecretInterface } from '../../../shared/interfaces/user/user-id-and-secret.interface';
import { SwitchRelationTypeResponseInterface } from '../../../shared/interfaces/user/switch-relation-type-response.interface';
import { PreSwitchRelationTypeInterface } from '../../../shared/interfaces/user/pre-switch-relation-type.interface';
import { PreClinicRegisterSpecialistInterface } from '../../../shared/interfaces/clinic/register-specialist/pre-clinic-register-specialist.interface';
import { FindUserResponseInterface } from '../../../shared/interfaces/user/find-user/find-user-response.interface';
import { SimpleRegisterResponseInterface } from '../../../shared/interfaces/auth/simple-register-response.interface';
import { DialogService } from '../../../shared/services/dialog.service';

@Component({
  selector: 'app-specialist-add-dialog',
  templateUrl: './specialist-add-dialog.component.html',
  styleUrls: ['./specialist-add-dialog.component.scss']
})
export class SpecialistAddDialogComponent implements OnInit {

  form: FormGroup;

  invite: boolean = false;
  preloader: boolean = false;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  signUpSubscribe: Subscription;
  findUserSubscribe: Subscription;

  selectSpecialization: SelectInterface[] = specializations;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SpecialistAddDialogComponent>,
    private customValidators: CustomValidators,
    private authService: AuthService,
    private clinicService: ClinicService,
    private userService: UserService,
    private dialogService: DialogService
  ) {
  }

  ngOnInit() {
    this.initForm();
  }

  /*
  * Инициализация формы
  * */

  initForm() {
    this.form = new FormGroup({
      'type': new FormControl('create', [Validators.required]),
      'email': new FormControl(null, [Validators.required, this.customValidators.email]),
      'firstName': new FormControl(null, [Validators.required]),
      'surName': new FormControl(null, [Validators.required]),
      'city': new FormControl(null, [Validators.required]),
      'specialization': new FormControl(null, [Validators.required])
    });
  }

  /*
  * Смена типа добавления специалиста
  * */

  changeType(data) {
    !api.production ? console.log('changeType', data.value) : null;

    this.invite = data.value === 'invite';

    if (this.invite) {
      this.form.get('firstName').disable();
      this.form.get('surName').disable();
      this.form.get('city').disable();
      this.form.get('specialization').disable();
    } else {
      this.form.get('firstName').enable();
      this.form.get('surName').enable();
      this.form.get('city').enable();
      this.form.get('specialization').enable();
    }
  }

  /*
  * Отправка формы
  * */

  onSubmit() {
    !api.production ? console.log('form', this.form) : null;

    this.preloader = true;

    const value = this.form.value;
    this.invite ? this.inviteSpecialist(value) : this.createSpecialist(value);
  }

  /*
  * Создание новго специалиста и отправка ему приглашения
  * */

  createSpecialist(value) {

    let request: PreClinicRegisterSpecialistInterface = {
      Firstname: value.firstName,
      Surname: value.surName,
      Email: value.email,
      TypeId: api.typesOfUsers.doctor,
      City: value.city,
      ContactSpecializat: []
    };

    for (let item of value.specialization) {
      request.ContactSpecializat.push(
        {
          Specialization: item
        }
      )
    }

    !api.production ? console.log('request', request) : null;


    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.signUpSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.signUpSubscribe = this.clinicService.registerSpecialist(request)
      .pipe(
        tap((response: SimpleRegisterResponseInterface) => {

          !api.production ? console.log('SimpleRegisterResponseInterface', response) : null;

          if (!response.SimpleRegistrationResult.Success) {
            clearTimeout(timeout);
            this.signUpSubscribe.unsubscribe();
            this.preloader = false;

            let title = this.errorTitle;
            let error = this.errorMessage;

            const warnings = response.SimpleRegistrationResult.Warnings;

            for (let key in warnings) {
              if (warnings[key].Code == 503) {
                title = 'Пользователь уже существует!';
                error = 'Пользователь с таким E-mail уже существует. Пожалуйста укажите другой E-mail.';
              }
            }

            this.dialogService.openStatusDialog(
              title,
              error,
              true
            );
          }

          return response;

        }, () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
          this.signUpSubscribe.unsubscribe();
          this.preloader = false;
        }),
        concatMap((response: SimpleRegisterResponseInterface) => {
          const userData: UserIdAndSecretInterface = response.SimpleRegistrationResult.UserData as UserIdAndSecretInterface;

          return this.switchType(userData.UserId);
        }),
      ).subscribe((response: SwitchRelationTypeResponseInterface) => {
        clearTimeout(timeout);
        this.switchTypeResponse(response, false);

      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
        this.signUpSubscribe.unsubscribe();
        this.preloader = false;
      });
  }

  /*
  * Поиск существуещего специалиста по email и отправка ему приглашения
  * */

  inviteSpecialist(value) {

    const request = value.email;

    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.findUserSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.findUserSubscribe = this.userService.findUserEmail(request)
      .pipe(
        tap((response: FindUserResponseInterface) => {
          !api.production ? console.log('FindUserResponseInterface', response) : null;

          this.preloader = false;

          if (!response.FindUserResult.Success) {
            clearTimeout(timeout);
            this.dialogService.showStandardError();
            this.findUserSubscribe.unsubscribe();
          }

        }, () => {
          clearTimeout(timeout);
          this.dialogService.showStandardError();
          this.findUserSubscribe.unsubscribe();
        }),
        concatMap((response: FindUserResponseInterface) => {

          const userData: UserIdAndSecretInterface = response.FindUserResult.UserData as UserIdAndSecretInterface;

          return this.switchType(userData.UserId);
        })
      ).subscribe((response: SwitchRelationTypeResponseInterface) => {
        clearTimeout(timeout);
        this.switchTypeResponse(response, true);
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
        this.findUserSubscribe.unsubscribe();
      });
  }

  /*
  * Запрос на отправку приглашения
  * */

  switchType(userId) {
    const request: PreSwitchRelationTypeInterface = {
      AccountId: this.clinicService.getClinicFullData().AccountData.AccountId,
      UserId: userId,
      LinkTypeId: api.switchRelationTypeList.wait
    };

    return this.userService.switchRelationType(request);
  }

  /*
  * Обработка ответа отправки приглашения
  * */

  switchTypeResponse(response, invite) {
    !api.production ? console.log('SwitchRelationTypeResponseInterface', response) : null;

    if (!response.SwitchRelationTypeResult.Success) {
      this.dialogService.showStandardError();
      this.findUserSubscribe.unsubscribe();
    } else {
      this.dialogRef.close({
        success: true,
        invite: invite
      });
    }
  }
}
