<form class="form-group"
      *ngIf="!hideForm"
      [formGroup]="form">

  <div class="row">

    <div class="col-lg-6">
      <dadata-mat [type]="'fio'"
                  [parts]="['NAME']"
                  limit="5"
                  placeholder="Имя"
                  [required]="true"
                  [control]="form.controls.firstName"
                  formControlName="firstName">
      </dadata-mat>
    </div>

    <div class="col-lg-6">
      <dadata-mat [type]="'fio'"
                  [parts]="['SURNAME']"
                  limit="5"
                  placeholder="Фамилия"
                  [required]="true"
                  [control]="form.controls.surName"
                  formControlName="surName">
      </dadata-mat>
    </div>

    <div class="col-lg-6">
      <mat-form-field>
        <mat-label>E-mail <i class="danger">*</i></mat-label>
        <input
          type="email"
          matInput
          id="email"
          formControlName="email">
        <mat-error *ngIf="form.get('email').hasError('required')">
          Поле не может быть пустым
        </mat-error>
        <mat-error
          *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
          Не верно указан E-mail
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-lg-6">
      <mat-form-field>
        <mat-label>Телефон <i class="danger">*</i></mat-label>
        <input
          type="tel"
          matInput
          [textMask]="phoneMask"
          formControlName="phone">
        <mat-error *ngIf="form.get('phone').hasError('required')">
          Поле не может быть пустым
        </mat-error>
        <mat-error *ngIf="form.get('phone').hasError('phone')">
          Неверный формат телефона
        </mat-error>
      </mat-form-field>
    </div>
    <div class="col-lg-6">
      <app-force-autocomplete
        formControlName="petType"
        label="Тип питомца"
        [control]="form.controls.petType"
        [required]="true"
        [selectOptions]="selectPetTypes"
      ></app-force-autocomplete>
    </div>

    <div class="col-lg-6">
      <mat-form-field>
        <mat-label>Имя питомца <i class="danger">*</i></mat-label>
        <input
          type="text"
          matInput
          formControlName="petName">
        <mat-error *ngIf="form.get('petName').hasError('required')">
          Поле не может быть пустым
        </mat-error>
      </mat-form-field>
    </div>

    <div class="col-12" *ngIf="needAddress">
      <dadata-mat [type]="'address'"
                  fromBound="city"
                  toBound="house"
                  limit="5"
                  id="address"
                  placeholder="Адрес"
                  [required]="true"
                  [restrict]="true"
                  [control]="form.controls.address"
                  formControlName="address">
      </dadata-mat>
    </div>

    <div class="col-12">
      <app-force-autocomplete
        formControlName="service"
        label="Услуга"
        [control]="form.controls.service"
        [required]="true"
        [selectOptions]="selectServices"
      ></app-force-autocomplete>
    </div>

    <div class="col-12">
      <mat-form-field>
        <mat-label>Жалоба <i class="danger">*</i></mat-label>
        <textarea
          matInput
          mat-autosize
          formControlName="complaint">
          </textarea>
        <mat-error *ngIf="form.get('complaint').hasError('required')">
          Поле не может быть пустым
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
