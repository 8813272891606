import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent, AccountDialog } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { ReactiveFormsModule } from '@angular/forms';

import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { ngfModule } from 'angular-file';
import { ImageCropperModule } from '../shared/plugins/cropper';
import { ClinicListComponent } from './clinic-list/clinic-list.component';
import { ClinicAddComponent } from './clinic-add/clinic-add.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import {
  DateAdapter, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule, MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule, MatNativeDateModule,
  MatSelectModule
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    AccountRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    SharedModule,
    ngfModule,
    ImageCropperModule,
    PipesModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule
  ],
  declarations: [
    AccountComponent,
    AccountEditComponent,
    AccountInfoComponent,
    AccountChangePasswordComponent,
    AccountDialog,
    ClinicListComponent,
    ClinicAddComponent
  ],
  entryComponents: [
    AccountDialog
  ],
  providers: [
    DatePipe,
    MatDatepickerModule,
    // {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class AccountModule { }
