import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { clinicActions, clinicDirections, clinicMetro } from '../../shared/catalogs/clinic.catalogs';
import { Subscription } from 'rxjs';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { BaseService } from '../../shared/services/base.service';
import { ClinicService } from '../../shared/services/clinic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClinicFormInterface } from '../../shared/interfaces/clinic/clinic-form.interface';
import { ClinicFullInterface } from '../../shared/interfaces/clinic/clinic-full.interface';
import { ClinicInsuranceComInterface } from '../../shared/interfaces/clinic/clinic-Insurance-com.interface';
import { ClinicDirectionsInterface } from '../../shared/interfaces/clinic/clinic-directions.interface';
import { ClinicEditResponseInterface } from '../../shared/interfaces/clinic/clinic-edit-response.interface';
import { CurrentClinicResponseInterface } from '../../shared/interfaces/clinic/current-clinic-response.interface';
import { CustomDirectionsInterface } from '../../shared/interfaces/clinic/custom-directions.interface';
import { MatDialog } from '@angular/material';
import { timepickerTheme } from '../../shared/const/const';
import { weekDays } from '../../shared/catalogs/main.catalog';
import { WorkTimeInterface } from '../../shared/interfaces/clinic/work-time-interface';
import { dateCompilationNoUtc, dateEncodeNoUtc, formatPhone, isEmpty } from '../../shared/functions/functions';
import { DatePipe } from '@angular/common';
import { RemoveAccountDetailInterface } from '../../shared/interfaces/clinic/remove-account-detail.interface';
import { DialogService } from '../../shared/services/dialog.service';
import { DeleteAccountDetailResponseInterface } from '../../shared/interfaces/clinic/delete-account-detail-response.interface';
import { CustomValidators } from '../../shared/validators/custom.validators';

@Component({
  selector: 'app-clinic-edit',
  templateUrl: './clinic-edit.component.html',
  styleUrls: ['./clinic-edit.component.scss']
})
export class ClinicEditComponent implements OnInit {

  preloader: boolean = false;
  form: FormGroup;

  cityFias: string;
  showMetro: boolean = false;
  // moscowFias = api.dadataFiases.moscow;

  timePickerTheme = timepickerTheme;

  selectMetro: SelectInterface[] = clinicMetro;
  selectClinicDirections: SelectInterface[] = clinicDirections;
  selectClinicActions: SelectInterface[] = clinicActions;
  listWeekDays: SelectInterface[] = weekDays;

  filteredActions: SelectInterface[] = [...this.selectClinicActions];

  currentClinicFullData: ClinicFullInterface;

  clinicDirections: FormArray;
  clinicInsuranceCompany: FormArray;
  clinicSchedule: FormArray;

  clinicEditSubscribe: Subscription;
  getCurrentClinicSubscribe: Subscription;
  clinicRemoveSubscribe: Subscription;

  staticClinicDirections: CustomDirectionsInterface[] = [];

  forRemove: RemoveAccountDetailInterface = {};

  clinicIndex: string;

  phoneMask: object = {
    mask: PhoneMask
  };

  constructor(
    private baseService: BaseService,
    private clinicService: ClinicService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private customValidators: CustomValidators,
  ) {
  }

  ngOnInit() {
    this.clinicIndex = this.route.parent.snapshot.paramMap.get('id');

    this.init();
  }

  init() {
    this.currentClinicFullData = this.clinicService.getClinicFullData();

    /*this.baseService.postSuggestion({
      query: this.currentClinicFullData.AccountData.City,
      count: 1
    })
      .subscribe((response: any) => {
        response.suggestions.length
          ? this.cityFias = response.suggestions[0].data.city_fias_id
          : null;
      });*/

    this.baseService.postSuggestion(this.currentClinicFullData.AccountData.City)
      .subscribe((response: any) => {
        response.suggestions.length
          ? this.cityFias = response.suggestions[0].data.city_fias_id
          : null;
      });

    let clinicDirectionsObject = {};

    this.currentClinicFullData.AccountDirections.forEach((el) => {
      if (!clinicDirectionsObject[el.Action]) {
        clinicDirectionsObject[el.Action] = {};
        clinicDirectionsObject[el.Action].action = el.Action;
        clinicDirectionsObject[el.Action].id = [];
        clinicDirectionsObject[el.Action].directions = [];
      }

      clinicDirectionsObject[el.Action].directions.push(el.DirectionType);
      clinicDirectionsObject[el.Action].id.push(el.Id);
    });

    for (let key in clinicDirectionsObject) {
      this.staticClinicDirections.push(clinicDirectionsObject[key]);
    }

    this.formInit();
  }

  private _filter(value: string): Object[] {
    const filterValue = value.toLowerCase();
    return this.selectClinicActions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  changeAutoFill(event, i) {
    // this.filteredActions[i] = this._filter(event.target.value) as SelectInterface[];
  }

  displayWith(obj?: any): string | undefined {
    return obj ? obj.name : undefined;
  }

  /*
  * Initialization form
  * */

  formInit() {
    this.form = new FormGroup({
      'name': new FormControl(
        this.currentClinicFullData.AccountData.AccountName,
        [Validators.required]
      ),
      'city': new FormControl(
        this.currentClinicFullData.AccountData.City,
        [Validators.required]
      ),
      'metro': new FormControl(
        this.currentClinicFullData.AccountMetro.length ? this.currentClinicFullData.AccountMetro[0].Metro : null
      ),
      'address': new FormControl(
        this.currentClinicFullData.AccountData.Address
      ),
      'phone': new FormControl(
        this.currentClinicFullData.AccountData.Phone,
        [Validators.required]),
      'site': new FormControl(
        this.currentClinicFullData.AccountData.Site
      ),
      'description': new FormControl(
        this.currentClinicFullData.AccountData.Description
      ),
      'googleMapsLink': new FormControl(
        this.currentClinicFullData.AccountData.GoogleMapsLink
      ),
      'yandexMapsLink': new FormControl(
        this.currentClinicFullData.AccountData.YandexMapsLink
      ),
      'clinicDirections': new FormArray([]),
      'insuranceCompanies': new FormArray([]),
      'clinicSchedule': new FormArray([])
    });

    this.clinicDirections = this.form.get('clinicDirections') as FormArray;
    this.clinicInsuranceCompany = this.form.get('insuranceCompanies') as FormArray;
    this.clinicSchedule = this.form.get('clinicSchedule') as FormArray;

    for (let key in weekDays) {

      if (this.currentClinicFullData.WorkTime.find(x => x.WeekDay == weekDays[key].value)) {

        const index = this.currentClinicFullData.WorkTime.findIndex(x => x.WeekDay == weekDays[key].value);

        let startTime = this.currentClinicFullData.WorkTime[index].StartTime;
        let endTime = this.currentClinicFullData.WorkTime[index].EndTime;

        let startTimeConvert = startTime ? this.datePipe.transform(dateEncodeNoUtc(startTime), 'HH:mm') : null;
        let endTimeConvert = endTime ? this.datePipe.transform(dateEncodeNoUtc(endTime), 'HH:mm') : null;

        this.clinicSchedule.push(
          new FormGroup({
            'weekDay': new FormControl(
              weekDays[key].value
            ),
            'startTime': new FormControl(startTimeConvert),
            'endTime': new FormControl(endTimeConvert),
            'holiday': new FormControl(this.currentClinicFullData.WorkTime[index].Holiday),
            'roundClock': new FormControl(this.currentClinicFullData.WorkTime[index].Roundclock)
          })
        );
      } else {
        this.clinicSchedule.push(
          new FormGroup({
            'weekDay': new FormControl(
              weekDays[key].value
            ),
            'startTime': new FormControl(null),
            'endTime': new FormControl(null),
            'holiday': new FormControl(null),
            'roundClock': new FormControl(null)
          })
        );
      }
    }

    const clinicInsuranceCompanyConst = this.currentClinicFullData.AccountInsuranceCom;

    if (clinicInsuranceCompanyConst.length) {
      for (let key in clinicInsuranceCompanyConst) {
        this.clinicInsuranceCompany.push(
          new FormGroup({
            'id': new FormControl(
              clinicInsuranceCompanyConst[key].Id
            ),
            'companyName': new FormControl(
              clinicInsuranceCompanyConst[key].CompanyName,
              [Validators.required]
            )
          })
        );
      }
    }

  }

  /*
  * Set city fias and check moscow for metro
  * */

  /*setCityFias(response) {
    if (response[0]) {
      this.cityFias = response[0].data.city_fias_id;

      this.showMetro = this.moscowFias == response[0].data.city_fias_id;
    } else {
      this.cityFias = response.data.city_fias_id;
      this.showMetro = this.moscowFias == response.data.city_fias_id;
    }
  }*/

  setCityFias(response) {
    this.cityFias = response;
    this.form.get('address').setValue('');
  }

  /*
  * Add clinic Direction item
  * */


  addClinicDirections(): void {
    // this.filteredActions.push(this.selectClinicActions);

    this.clinicDirections.push(
      new FormGroup({
        'id': new FormControl(),
        'clinicAction': new FormControl(null, [Validators.required, this.customValidators.autofillForceRequire]),
        'clinicDirections': new FormControl('', [Validators.required])
      })
    );
  }

  /*
  * Add clinic Insurance Company item
  * */

  addClinicInsuranceCompany(): void {
    this.clinicInsuranceCompany.push(
      new FormGroup({
        'id': new FormControl(),
        'companyName': new FormControl(null, [Validators.required])
      })
    );
  }

  /*
  * Remove clinic Direction item
  * */

  removeClinicDirections(i): void {
    // this.filteredActions.splice(i,1);

    this.clinicDirections.removeAt(i);
  }

  /*
  * Remove clinic Insurance company item
  * */

  removeClinicInsuranceCompany(i): void {

    console.log(this.clinicInsuranceCompany.controls[i].get('id').value);

    const id = this.clinicInsuranceCompany.controls[i].get('id').value;

    if (id) {
      if(!this.forRemove.AccountInsuranceCom) this.forRemove.AccountInsuranceCom = [];

      this.forRemove.AccountInsuranceCom.push(
        {
          Id: id
        }
      );
    }

    this.clinicInsuranceCompany.removeAt(i);
  }

  removeStaticDirections(i): void {

    const ids = this.staticClinicDirections[i].id;

    if(!this.forRemove.AccountDirections) this.forRemove.AccountDirections = [];

    for (let id of ids) {
      this.forRemove.AccountDirections.push(
        {
          Id: id
        }
      );
    }

    this.staticClinicDirections.splice(i, 1);
  }

  /*
  * Submit form
  * */

  onSubmit() {
    const value = this.form.value as ClinicFormInterface;
    this.preloader = true;

    /*
    * Create clinic register request
    * */

    let phone = null;

    if(value.phone) {
      phone = formatPhone(value.phone);
    }

    let request: ClinicFullInterface = {
      SourceID: api.sourceID,
      SourceSecretKey: api.sourceSecretKey,
      AccountData: {
        AccountId: this.currentClinicFullData.AccountData.AccountId,
        AccountName: value.name,
        Phone: phone,
        City: value.city,
        Site: value.site,
        Description: value.description,
        Address: value.address,
        GoogleMapsLink: value.googleMapsLink,
        YandexMapsLink: value.yandexMapsLink
      }
    };

    /*
    * Add clinic insurance companies into request
    * */

    let requestInsuranceCompanies: ClinicInsuranceComInterface[] = [];

    for (let key in value.insuranceCompanies) {
      if (value.insuranceCompanies[key]) {
        let data: ClinicInsuranceComInterface = {
          CompanyName: value.insuranceCompanies[key].companyName,
        };
        value.insuranceCompanies[key].id
          ? data.Id = value.insuranceCompanies[key].id
          : null;
        requestInsuranceCompanies.push(this.baseService.clearEmpty(data));
      }
    }

    /*
    * Add clinic Directions into request
    * */

    let requestClinicDirections: ClinicDirectionsInterface[] = [];

    for (let key in value.clinicDirections) {
      if (value.clinicDirections[key]) {
        let i = 0;
        for (let key2 in value.clinicDirections[key].clinicDirections) {
          if (value.clinicDirections[key].clinicDirections[key2]) {
            let data: ClinicDirectionsInterface = {
              Action: value.clinicDirections[key].clinicAction['value'],
              DirectionType: this.selectClinicDirections[i].value as string
            };
            value.clinicDirections[key].id
              ? data.Id = value.clinicDirections[key].id
              : null;
            requestClinicDirections.push(this.baseService.clearEmpty(data));
          }

          i++;
        }
      }
    }

    /*
    * Add clinic schedule into request
    * */

    let requestClinicSchedule: WorkTimeInterface[] = [];

    for (let key in value.clinicSchedule) {
      if (value.clinicSchedule[key]) {

        let startTimeVal = value.clinicSchedule[key].startTime;
        let endTimeVal = value.clinicSchedule[key].endTime;
        let startTime;
        let endTime;

        if (startTimeVal) {
          let date01 = new Date;
          let startTimeValSplit = value.clinicSchedule[key].startTime.split(':');

          date01.setHours(
            parseInt(startTimeValSplit[0]),
            parseInt(startTimeValSplit[1]),
            0);

          startTime = dateCompilationNoUtc(date01);
        }

        if (endTimeVal) {
          let date02 = new Date;
          let endTimeValSplit = value.clinicSchedule[key].endTime.split(':');

          date02.setHours(
            parseInt(endTimeValSplit[0]),
            parseInt(endTimeValSplit[1]),
            0);

          endTime = dateCompilationNoUtc(date02);
        }

        let data: WorkTimeInterface = {
          WeekDay: value.clinicSchedule[key].weekDay,
          StartTime: startTime,
          EndTime: endTime,
          Holiday: value.clinicSchedule[key].holiday,
          Roundclock: value.clinicSchedule[key].roundClock
        };
        requestClinicSchedule.push(this.baseService.clearEmpty(data));
      }
    }

    /*
    * Add clinic Metro into request
    * */

    if (value.metro) {
      request.AccountMetro = [];
      request.AccountMetro.push({
        Metro: value.metro
      });
    }

    requestClinicDirections.length
      ? request.AccountDirections = requestClinicDirections
      : null;

    requestInsuranceCompanies.length
      ? request.AccountInsuranceCom = requestInsuranceCompanies
      : null;

    requestClinicSchedule.length
      ? request.WorkTime = requestClinicSchedule
      : null;

    !api.production ? console.log('request', request) : null;

    if(isEmpty(this.forRemove)) {
      this.saveClinicData(request);
    } else {
      this.deleteFromClinicData(request);
    }

  }

  saveClinicData(request) {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.clinicEditSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.clinicEditSubscribe = this.clinicService.editClinic(request)
      .subscribe(
        (response: ClinicEditResponseInterface) => {
          !api.production ? console.log('EditAccountResult', response) : null;

          this.preloader = false;
          clearTimeout(timeout);

          if (response.EditAccountResult.Success) {
            this.dialogService.openStatusDialog(
              'Информация успешно изменена!',
              'Вы успешно изменили информацию о клинике',
              false,
              'back-info'
            );

            this.getNewClinicData();
          } else {
            this.dialogService.showStandardError();
          }
        }, () => {
          clearTimeout(timeout);
          this.preloader = false;
          this.dialogService.showStandardError();
        }
      );
  }

  getNewClinicData() {

    const timeout = setTimeout(() => {
      this.showRefreshError();
      this.getCurrentClinicSubscribe.unsubscribe();
    }, api.requestTimeout);

    const accountId = this.currentClinicFullData.AccountData.AccountId;

    this.getCurrentClinicSubscribe = this.clinicService.currentClinic(accountId)
      .subscribe((response: CurrentClinicResponseInterface) => {
          clearTimeout(timeout);
          this.preloader = false;

          if (response.CurrentClinicResult.Success) {
            this.clinicService.setClinicFullData(response.CurrentClinicResult);
            this.clearAll();
            this.init();
          } else {
            this.showRefreshError();
          }
        }, () => {
          clearTimeout(timeout);

        this.showRefreshError();
        }
      );
  }

  deleteFromClinicData(request) {
    const timeout = setTimeout(() => {
      this.dialogService.showStandardError();
      this.clinicRemoveSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.clinicRemoveSubscribe = this.clinicService.deleteAccountDetail(this.forRemove).subscribe(
      (response: DeleteAccountDetailResponseInterface) => {
        clearTimeout(timeout);
        !api.production ? console.log('DeleteAccountDetailResult', response) : null;

        if (response.DeleteAccountDetailResult.Success) {

          this.saveClinicData(request);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      }
    );
  }

  showRefreshError() {
    this.dialogService.openStatusDialog(
      'При обновлении данных произошла ошибка',
      'Ваши изменения были сохранены, но при обновлении данных произошла ошибка. Попробуйте заново открыть страницу.',
      true,
      'back'
    ).subscribe(response => {
      if(response === 'back') this.router.navigate(['/clinic', this.clinicService.activeClinic, 'info']);
    });
  }

  clearAll() {
    this.form.reset();

    this.staticClinicDirections = [];

    while (this.clinicDirections.length !== 0) {
      this.clinicDirections.removeAt(0);
    }
    while (this.clinicInsuranceCompany.length !== 0) {
      this.clinicInsuranceCompany.removeAt(0);
    }
    while (this.clinicSchedule.length !== 0) {
      this.clinicSchedule.removeAt(0);
    }
  }


  /*
  * Testing something
  * */

  test() {
    !api.production ? console.log('test-from', this.form) : null;
/*
    let test = '11:00';

    let date = new Date;

    date.setHours(parseInt(test.split(':')[0]), parseInt(test.split(':')[1]), 0);

    console.log(date);

    let unix = dateCompilationNoUtc(date);

    console.log(unix);

    let newDate = dateEncodeNoUtc(unix);

    console.log(newDate.getHours() + ':' + newDate.getMinutes());*/
  }

}
