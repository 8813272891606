<button class="close-mobile" (click)="closeMobileSidebar()">
  <i class="if if-close"></i>
</button>
<nav class="sidebar-navigation">
  <ul>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        routerLink="/account/clinic-list"
        routerLinkActive="active"
      >
        <i class="if if-clinic"></i>
        <span class="text">Мои клиники</span>
      </button>
    </li>
    <!--<li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-paws"></i>
        <span class="text">Клиенты</span>
      </button>
    </li>
    <li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-calendar"></i>
        <span class="text">Расписание приемов</span>
      </button>
    </li>-->
    <li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-support"></i>
        <span class="text">Мои сообщения</span>
      </button>
    </li>
  </ul>
</nav>
<div class="sidebar-socials">
  <div class="title">Мы в соц. сетях</div>
  <div class="socials-list">
    <a href="https://vk.com/appmarko" target="_blank"
      ><i class="if if-vkontakte"></i
    ></a>
  </div>
</div>
