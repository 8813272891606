import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { api } from '../../../environments/api';
import { Subscription } from 'rxjs/Subscription';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { CropperSettings, ImageCropperComponent } from '../../shared/plugins/cropper';
import { downscaleImage } from '../../shared/functions/functions';
import { ClinicInterface } from '../../shared/interfaces/clinic/clinic.interface';
import { ClinicService } from '../../shared/services/clinic.service';
import { ClinicEditResponseInterface } from '../../shared/interfaces/clinic/clinic-edit-response.interface';
import { CurrentClinicResponseInterface } from '../../shared/interfaces/clinic/current-clinic-response.interface';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../../account/account.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-clinic-change-photo',
  templateUrl: './clinic-change-photo.component.html',
  styleUrls: ['./clinic-change-photo.component.scss']
})
export class ClinicChangePhotoComponent implements OnInit {
  files: File[] = [];
  showPreview: boolean = false;
  isFile: boolean = false;
  preloaderVisibility: boolean = false;

  timeout: boolean = true;
  timeout2: boolean = true;
  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  changePhotoSubscribe: Subscription;
  currentClinicSubscribe: Subscription;

  lastInvalidFiles: File[];
  showError: boolean = false;
  errorText: string;

  data: any;
  cropperSettings: CropperSettings;
  cropperSettings2: CropperSettings;
  currentAvatar = this.dialogData.avatar;
  avatarId = this.dialogData.avatarId;
  clinicData: ClinicInterface;

  @ViewChild('cropper', undefined) cropper: ImageCropperComponent;
  @ViewChild('cropperWrapper') cropperWrapper: ElementRef;

  constructor(
    private clinicService: ClinicService,
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private router: Router,
    public dialogRef: MatDialogRef<ClinicChangePhotoComponent>,
  ) {
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;
    this.cropperSettings.keepAspect = false;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 460;

    this.cropperSettings.minWidth = 60;
    this.cropperSettings.minHeight = 60;

    this.cropperSettings.rounded = true;
    this.cropperSettings.minWithRelativeToResolution = false;

    this.cropperSettings.cropperClass = 'cropper-none';
    this.cropperSettings.croppingClass = 'cropper-cropping';
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.dynamicSizing = true;
    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings.fileType = 'image/jpeg';

    this.cropperSettings2 = new CropperSettings();
    this.cropperSettings2.width = 200;
    this.cropperSettings2.height = 200;
    this.cropperSettings2.keepAspect = false;

    this.cropperSettings2.croppedWidth = 200;
    this.cropperSettings2.croppedHeight = 200;

    this.cropperSettings2.canvasWidth = 500;
    this.cropperSettings2.canvasHeight = 300;

    this.cropperSettings2.minWidth = 100;
    this.cropperSettings2.minHeight = 100;

    this.cropperSettings2.rounded = true;
    this.cropperSettings2.minWithRelativeToResolution = false;

    this.cropperSettings2.cropperDrawSettings.strokeColor = 'rgba(255,255,255,1)';
    this.cropperSettings2.cropperDrawSettings.strokeWidth = 2;
    this.cropperSettings2.noFileInput = true;
    this.cropperSettings2.fileType = 'image/jpeg';

    this.data = {};
  }

  ngOnInit() {
    this.clearFile();
    this.clinicData = this.clinicService.getClinicFullData().AccountData;
  }

  changeFiles() {
    if(this.files.length) {
      this.showError = false;
      this.showPreview = true;

      const file = this.files[0];

      let reader = new FileReader();

      let image: any = new Image();
      let t = this;

      reader.readAsDataURL(file);
      reader.onload = () => {
        image.src = reader.result;
        t.cropper.setImage(image);
        this.isFile = true;
      }

    } else {
      this.showPreview = false;
      this.isFile = false;
    }
  }

  lastInvalidsChange() {
    if(this.lastInvalidFiles) {
      this.showError = true;
      this.errorText = this.lastInvalidFiles[0].type == 'fileSize' ? 'Размер файла слишком большой': 'Неизвестаня ошибка';
    } else {
      this.showError = false;
    }

  }

  clearFile() {
    this.showPreview = false;
    this.isFile = false;
    this.showError = false;
    this.files = [];
  }

  changePhoto() {

    const name = this.files[0].name;

    const content = downscaleImage(this.data.image,700).split(',')[1];

    let request;

    if(this.avatarId) {
      request = {
        Id: this.avatarId,
        Name: name,
        Content: content
      };
    } else {
      request = {
        Name: name,
        Content: content
      };
    }

    this.preloaderVisibility = true;

    this.changePhotoSubscribe = this.clinicService.changePhoto(request, this.clinicData.AccountId)
      .subscribe((response: ClinicEditResponseInterface) => {
        console.log(response);

        if(response.EditAccountResult.Success) {

          this.timeout = false;

          this.currentClinicSubscribe = this.clinicService.currentClinic(this.clinicData.AccountId)
            .subscribe((response: CurrentClinicResponseInterface) => {
              console.log('CurrentClinicResult', response);
              this.preloaderVisibility = false;
              this.timeout2 = false;

              if(response.CurrentClinicResult.Success) {
                // this.clinicService.setClinicFullData(response.CurrentClinicResult);

                this.clinicService.clinicFullData.Files = response.CurrentClinicResult.Files;
                this.clearFile();
                this.dialogRef.close({
                  success: true
                });

              } else {
                this.openDialog(
                  this.errorTitle,
                  this.errorMessage,
                  true
                );
              }
            }, error => {
              this.timeout2 = false;
              this.openDialog(
                this.errorTitle,
                this.errorMessage,
                true
              );
            });

          setTimeout(() => {
            if (this.timeout2) {
              this.preloaderVisibility = false;
              this.clearFile();
              this.showError = true;
              this.errorText = api.mainErrorMessage;
              this.changePhotoSubscribe.unsubscribe();
            }
          }, 30000);

        } else {
          this.preloaderVisibility = false;
          this.timeout = false;
          this.clearFile();
          this.showError = true;
          this.errorText = api.mainErrorMessage;
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.clearFile();
        this.showError = true;
        this.errorText = api.mainErrorMessage;
        this.changePhotoSubscribe.unsubscribe();
      }
    }, 30000);
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.preloaderVisibility = false;

      result == 'back-list' ? this.router.navigate(['/account', 'clinic-list']) : null;
    });
  }
}
