<div class="auth-background-image">
  <img src="assets/images/auth-image-04.jpg" alt="">
</div>

<div class="auth-centered">
  <form
    class="auth-form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()">

    <div class="auth-form-title">Забыли пароль?</div>

    <label [class.auth-error]="form.get('emailPhone').invalid && form.get('emailPhone').touched">
      <input
        type="email"
        placeholder="Email"
        id="email-phone"
        formControlName="emailPhone">
      <i class="if if-light-envelope"></i>

      <span class="auth-error-message"
            *ngIf="form.get('emailPhone').invalid && form.get('emailPhone').touched">

        <span *ngIf="form.get('emailPhone')['errors']['required']">
          Поле не может быть пустым
        </span>
        <span *ngIf="form.get('emailPhone')['errors']['pattern']">
          Не верно указан E-mail
        </span>
      </span>
    </label>

    <button
      type="submit"
      class="auth-button"
      [disabled]="form.invalid">

      Восстановить
    </button>
  </form>
  <div class="auth-links">
    <a routerLink="/auth/sign-in">Войти</a>
    <a routerLink="/auth/sign-up">Регистрация</a>
  </div>
</div>

<div class="load-spinner" *ngIf="preloaderVisibility">
  <img src="assets/images/load-spinner.svg" alt="">
</div>
