<app-header class="app-header"
            [class.sidebar-collapsed]="!userService.showSidebar"
            [userData]="userData">
</app-header>
<app-sidebar-clinic class="app-sidebar"
             [class.sidebar-collapsed]="!userService.showSidebar"
             [class.show]="userService.showMobileSidebar">
</app-sidebar-clinic>

<div class="content-background"
     [class.sidebar-collapsed]="!userService.showSidebar"
     *ngIf="!preloaderVisibility">
  <router-outlet></router-outlet>
</div>

<div class="load-content-spinner" *ngIf="preloaderVisibility">
  <img src="assets/images/load-content-spinner.svg" alt="">
</div>
