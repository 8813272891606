/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dadata.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./dadata.component";
import * as i5 from "../../services/dadata.service";
var styles_DadataComponent = [i0.styles];
var RenderType_DadataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DadataComponent, data: {} });
export { RenderType_DadataComponent as RenderType_DadataComponent };
function View_DadataComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "autocomplele-item"]], [[8, "id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick($event, _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = (_v.context.index + "item"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 1, 0, currVal_1); }); }
function View_DadataComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "autocomplete-items"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DadataComponent_2)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DadataComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { inputValue: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "autocomplete"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["inputValue", 1]], null, 5, "input", [["autocomplete", "new-password"], ["class", "search form-control"], ["name", "address"], ["spellcheck", "false"], ["type", "text"]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "keyup.ArrowDown"], [null, "keyup.ArrowUp"], [null, "keyup.Enter"], [null, "blur"], [null, "ngModelChange"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (_co.getData(i1.ɵnov(_v, 2).value) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.ArrowDown" === en)) {
        var pd_5 = (_co.onArrowDown() !== false);
        ad = (pd_5 && ad);
    } if (("keyup.ArrowUp" === en)) {
        var pd_6 = (_co.onArrowUp() !== false);
        ad = (pd_6 && ad);
    } if (("keyup.Enter" === en)) {
        var pd_7 = (_co.onEnter() !== false);
        ad = (pd_7 && ad);
    } if (("blur" === en)) {
        var pd_8 = (_co.onInputBlur() !== false);
        ad = (pd_8 && ad);
    } if (("ngModelChange" === en)) {
        var pd_9 = ((_co.value = $event) !== false);
        ad = (pd_9 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(5, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], isDisabled: [1, "isDisabled"], model: [2, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(7, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DadataComponent_1)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = "address"; var currVal_9 = (_co.disabled ? true : null); var currVal_10 = _co.value; _ck(_v, 5, 0, currVal_8, currVal_9, currVal_10); var currVal_11 = _co.data.length; _ck(_v, 9, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.placeholder; var currVal_1 = i1.ɵnov(_v, 7).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 7).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 7).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 7).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 7).ngClassValid; var currVal_6 = i1.ɵnov(_v, 7).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 7).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_DadataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dadata", [], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onOutsideClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_DadataComponent_0, RenderType_DadataComponent)), i1.ɵprd(5120, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DadataComponent]), i1.ɵdid(2, 638976, null, 0, i4.DadataComponent, [i5.DadataService, i1.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DadataComponentNgFactory = i1.ɵccf("dadata", i4.DadataComponent, View_DadataComponent_Host_0, { config: "config", apiKey: "apiKey", disabled: "disabled", type: "type", limit: "limit", placeholder: "placeholder", fromBound: "fromBound", toBound: "toBound", restrict: "restrict", locations: "locations", parts: "parts" }, { selectedSuggestion: "selectedSuggestion", selected: "selected", suggestionEvent: "suggestion" }, []);
export { DadataComponentNgFactory as DadataComponentNgFactory };
