import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialistsComponent } from './specialists.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpecialistsRoutingModule } from './specialists-routing.module';
import { SpecialistsListComponent } from './specialists-list/specialists-list.component';
import { SpecialistsAddComponent } from './specialists-add/specialists-add.component';
import { MatDialogModule, MatFormFieldModule, MatInputModule, MatSelectModule } from '@angular/material';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { SpecialistAddDialogComponent } from './specialist-add-dialog/specialist-add-dialog.component';
import { SharedModule } from '../../shared/shared.module';
import { SpecialistsInvitedComponent } from './specialists-invited/specialists-invited.component';

@NgModule({
  imports: [
    CommonModule,
    SpecialistsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
    MatSelectModule,
    MatDialogModule
  ],
  declarations: [
    SpecialistsComponent,
    SpecialistsListComponent,
    SpecialistsAddComponent,
    SpecialistAddDialogComponent,
    SpecialistsInvitedComponent
  ],
  entryComponents: [
    SpecialistAddDialogComponent
  ]
})
export class SpecialistsModule { }
