import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { api } from '../../../../environments/api';
import { timepickerTheme } from '../../../shared/const/const';
import { ClinicSpecialistScheduleInterface } from '../../../shared/interfaces/clinic/schedules/clinic-specialist-schedule.interface';
import { ClinicListSpecialistsInterface } from '../../../shared/interfaces/clinic/clinic-list-specialists.interface';
import { SelectInterface } from '../../../shared/interfaces/select.interface';
import { specializations, typeAppointment, weekDay } from '../../../shared/catalogs/clinic.catalogs';
import { ClinicService } from '../../../shared/services/clinic.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { GetClinicSpecialistsResponseInterface } from '../../../shared/interfaces/clinic/get-clinic-specialists-response.interface';
import { CurrentSpecialistScheduleResponseInterface } from '../../../shared/interfaces/clinic/schedules/current-specialist-schedule.response.interface';
import { ReceptionTypesInterface } from '../../../shared/interfaces/clinic/schedules/reception-types.interface';
import { ScheduleTimetablesInterface } from '../../../shared/interfaces/clinic/schedules/schedule-timetables.interface';
import { dateCompilation, dateEncode, dateEncodeNoUtc, timeCompilation } from '../../../shared/functions/functions';
import { ClinicSpecialistScheduleResponseInterface } from '../../../shared/interfaces/clinic/schedules/clinic-specialist-schedule-response.interface';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { IdInterface } from '../../../shared/interfaces/clinic/schedules/id.interface';
import { PublishScheduleResponseInterface } from '../../../shared/interfaces/clinic/schedules/publish-schedule-response.interface';
import { DialogService } from '../../../shared/services/dialog.service';
import { CustomValidators } from '../../../shared/validators/custom.validators';
import * as moment from 'moment';
import { DeleteScheduleResponsiveInterface } from '../../../shared/interfaces/clinic/schedules/delete-schedule-responsive.interface';

@Component({
  selector: 'app-schedule-edit',
  templateUrl: './schedule-edit.component.html',
  styleUrls: ['./schedule-edit.component.scss']
})
export class ScheduleEditComponent implements OnInit {

  preloader: boolean = true;
  specialistSelected: boolean = false;
  scheduleSelected: boolean = false;

  form: FormGroup;
  minDate: Date = null;
  maxDate: Date = null;
  minDateDueDate: Date = new Date();
  minDateFirst: Date = null;
  minTimes = [];
  maxTime = '11:59 pm';

  getClinicSpecialistSubscribe: Subscription;
  getClinicSpecialistSchedulesSubscribe: Subscription;
  addClinicSpecialistScheduleSubscribe: Subscription;
  publicationScheduleSubscribe: Subscription;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;

  timePickerTheme = timepickerTheme;

  specialistId: string = '';
  scheduleId: string = '';
  scheduleDisabled = false;

  schedules: ClinicSpecialistScheduleInterface[] = [];
  specialistsList: ClinicListSpecialistsInterface[] = [];

  selectSpecialist: SelectInterface[] = [];
  selectSchedule: SelectInterface[] = [];
  selectSpecialization: SelectInterface[] = specializations;
  selectTypeId: SelectInterface[] = typeAppointment;

  constructor(
    public clinicService: ClinicService,
    private router: Router,
    private datePipe: DatePipe,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private dialogService: DialogService,
    private customValidators: CustomValidators
  ) {
    iconRegistry.addSvgIcon(
      'clock',
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/clock.svg'));
  }

  ngOnInit() {
    this.getClinicSpecialist();
    this.initForm();
  }

  getClinicSpecialist() {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getClinicSpecialistSubscribe.unsubscribe();
    }, api.requestTimeout);

    const request = this.clinicService.getClinicFullData().AccountData.AccountId;

    this.getClinicSpecialistSubscribe = this.clinicService.getClinicSpecialists(request)
      .subscribe((response: GetClinicSpecialistsResponseInterface) => {
        !api.production ? console.log('GetSpecialistResult', response) : null;

        clearTimeout(timeout);
        this.preloader = false;

        if (response.GetSpecialistResult.Success) {
          this.specialistsList = response.GetSpecialistResult.Specialists;
          this.initSelect();
        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        this.dialogService.showStandardError();
        clearTimeout(timeout);
      });
  }

  /*
  * Инициализация select
  * */

  initSelect() {
    for (let item of this.specialistsList) {
      this.selectSpecialist.push(
        {
          value: item.SpecialistId,
          name: item.Name
        }
      );
    }
  }

  /*
  * Действие при смене специалиста
  * */

  changeSelectSpecialist(event) {
    this.clearAll();
    console.log(event);
    this.specialistId = event.value.value;

    this.generateSelectSpecialistSchedules(event.value.value);
  }

  generateSelectSpecialistSchedules(specialistId) {
    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.getClinicSpecialistSchedulesSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.getClinicSpecialistSchedulesSubscribe ? this.getClinicSpecialistSchedulesSubscribe.unsubscribe() : null;

    /*
    * Получаем список всех графиков выбранного специалиста
    * */

    this.getClinicSpecialistSchedulesSubscribe = this.clinicService.getSpecialistSchedules(specialistId)
      .subscribe((response: CurrentSpecialistScheduleResponseInterface) => {
        !api.production ? console.log('CurrentSpecialistScheduleResponseInterface', response) : null;
        clearTimeout(timeout);

        if (response.CurrentSpecialistScheduleResult.Success) {
          this.specialistSelected = true;

          this.schedules = response.CurrentSpecialistScheduleResult.SpecialistSchedules;

          if (this.schedules.length) {
            /*
            * Добавляем Id и название графиков в select для выбора готовых неопубликованых графиков
            * */
            for (let item of this.schedules) {

              if (!item.IsPublished) {
                let startDate = item.StartDate;
                let dueDate = item.DueDate;
                startDate = this.datePipe.transform(dateEncodeNoUtc(startDate), 'dd MMMM yyyy');
                dueDate = this.datePipe.transform(dateEncodeNoUtc(dueDate), 'dd MMMM yyyy');

                this.selectSchedule.push(
                  {
                    'name': startDate + ' - ' + dueDate,
                    'value': item.Id
                  }
                );
              }
            }
          }

          this.scheduleDisabled = !this.selectSchedule.length;
          console.log(this.scheduleDisabled);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        this.dialogService.showStandardError();
        clearTimeout(timeout);
      });
  }

  /*
  * Действие при смене графика
  * */

  changeSelectSchedule(event) {

    this.clearForm();

    this.scheduleId = event.value.value;

    const schedule = this.schedules.filter(x => x.Id === event.value.value)[0] as ClinicSpecialistScheduleInterface;
    this.minTimes = [];

    const startDate = dateEncodeNoUtc(schedule.StartDate);
    const dueDate = dateEncodeNoUtc(schedule.DueDate);

    this.form.patchValue(
      {
        'id': schedule.Id,
        'startDate': dateEncodeNoUtc(schedule.StartDate),
        'dueDate': dateEncodeNoUtc(schedule.DueDate)
      }
    );

    this.minDateDueDate = dateEncodeNoUtc(schedule.StartDate);
    this.minDate = dateEncodeNoUtc(schedule.StartDate);
    this.maxDate = dateEncodeNoUtc(schedule.DueDate);

    const receptionTypes: ReceptionTypesInterface[] = schedule.ReceptionTypes;

    if (receptionTypes.length) {

      const formReceptionTypes: FormArray = this.form.get('receptionTypes') as FormArray;

      let i = 0;

      for (let receptionType of receptionTypes) {

        /*
        * Перебираем и добавляем receptionTypes если они есть
        * */

        formReceptionTypes.push(
          new FormGroup(
            {
              'id': new FormControl(receptionType.Id),
              'duration': new FormControl(parseInt(String(receptionType.Duration)), [Validators.required]),
              'price': new FormControl(receptionType.Price, [Validators.required]),
              'typeId': new FormControl(receptionType.TypeId, [Validators.required]),
              'specializationId': new FormControl(receptionType.SpecializationId, [Validators.required]),
              'scheduleTimetables': new FormArray([])
            }
          )
        );

        this.minTimes.push([]);

        const scheduleTimetables: ScheduleTimetablesInterface[] = receptionType.ScheduleTimetables;

        scheduleTimetables.sort((a, b) => <any>(dateEncodeNoUtc(a.Date)) - <any>(dateEncodeNoUtc(b.Date)));

        if (scheduleTimetables.length) {

          /*
          * Перебираем и добавляем scheduleTimetables если они есть
          * */

          const formScheduleTimetables: FormArray = formReceptionTypes.controls[i].get('scheduleTimetables') as FormArray;

          let j = 0;

          for (let scheduleTimetable of scheduleTimetables) {

            let startTimeConvert = scheduleTimetable.StartDate ? this.datePipe.transform(dateEncodeNoUtc(scheduleTimetable.StartDate), 'HH:mm') : null;
            let endTimeConvert = scheduleTimetable.DueDate ? this.datePipe.transform(dateEncodeNoUtc(scheduleTimetable.DueDate), 'HH:mm') : null;
            let dateConvert = scheduleTimetable.Date ? dateEncodeNoUtc(scheduleTimetable.Date) : null;

            if (scheduleTimetable.StartDate) {
              let minTime = moment(dateEncodeNoUtc(scheduleTimetable.StartDate));
              minTime.add(receptionType.Duration, 'minute');
              this.minTimes[i].push(minTime.format('kk:mm'));
            } else {
              this.minTimes[i].puqsh(null);
            }

            let newDate = null;

            if (scheduleTimetable.Date) {
              const diffDate = moment(dateEncode(scheduleTimetable.Date)).diff(startDate, 'days');
              newDate = moment().add({days: diffDate});
            }

            formScheduleTimetables.push(
              new FormGroup(
                {
                  'id': new FormControl(scheduleTimetable.Id),
                  'date': new FormControl(dateConvert, [Validators.required]),
                  'startTime': new FormControl(startTimeConvert, [Validators.required]),
                  'dueTime': new FormControl(endTimeConvert, [Validators.required, this.customValidators.minTimeSchedule])
                }
              )
            );

            formScheduleTimetables.controls[j].get('date').markAsTouched();
            formScheduleTimetables.controls[j].get('startTime').markAsTouched();
            formScheduleTimetables.controls[j].get('dueTime').markAsTouched();

            j++;
          }
        }

        i++;
      }
    }

    !api.production ? console.log('form', this.form) : null;

    this.scheduleSelected = true;
  }

  /*
  * Очистка всех переменных
  * */

  clearAll() {
    this.specialistSelected = false;
    this.scheduleSelected = false;
    this.selectSchedule = [];
    this.schedules = [];
    this.minTimes = [];
    this.minDate = null;
    this.maxDate = null;
    this.clearForm();
  }

  /*
  * Очистка формы
  * */

  clearForm() {
    this.form.get('id').setValue(null);
    this.form.get('startDate').setValue(null);
    this.form.get('dueDate').setValue(null);

    const receptionTypes: FormArray = this.form.get('receptionTypes') as FormArray;

    while (receptionTypes.length !== 0) {
      receptionTypes.removeAt(0);
    }
  }

  /*
  * Создание формы
  * */

  initForm() {
    this.form = new FormGroup(
      {
        'id': new FormControl(null),
        'startDate': new FormControl(null, [Validators.required]),
        'dueDate': new FormControl(null, [Validators.required]),
        'receptionTypes': new FormArray([])
      }
    );
  }

  /*
  * Создание массива ReceptionTypes для формы
  * */

  initReceptionTypes() {
    return new FormGroup(
      {
        'id': new FormControl(null),
        'duration': new FormControl(null, [Validators.required]),
        'price': new FormControl(null, [Validators.required]),
        'typeId': new FormControl(null, [Validators.required]),
        'specializationId': new FormControl(null, [Validators.required]),
        'scheduleTimetables': new FormArray([], [Validators.required])
      }
    );
  }

  /*
  * Создание массива ScheduleTimetables для формы
  * */

  initScheduleTimetables() {
    return new FormGroup(
      {
        'id': new FormControl(null),
        'date': new FormControl(null, [Validators.required]),
        'startTime': new FormControl(null, [Validators.required]),
        'dueTime': new FormControl(null, [Validators.required, this.customValidators.minTimeSchedule])
      }
    );
  }

  addReceptionType() {
    const controls: FormArray = this.form.get('receptionTypes') as FormArray;

    controls.push(
      this.initReceptionTypes()
    );

    this.minTimes.push([]);
  }

  addScheduleTimetables(i) {
    const parent: FormArray = this.form.get('receptionTypes') as FormArray;
    const controls: FormArray = parent.controls[i].get('scheduleTimetables') as FormArray;

    controls.push(
      this.initScheduleTimetables()
    );

    this.minTimes[i].push(null);
  }

  getReceptionTypes(form) {
    return form.controls.receptionTypes.controls;
  }

  getScheduleTimetables(form) {
    return form.controls.scheduleTimetables.controls;
  }

  removeReceptionType(i) {
    const controls: FormArray = this.form.get('receptionTypes') as FormArray;
    controls.removeAt(i);
    this.minTimes.splice(i, 1);
  }

  removeScheduleTimetables(i, j) {
    const controls: FormArray = this.form.get(['receptionTypes', i, 'scheduleTimetables']) as FormArray;
    controls.removeAt(j);
    this.minTimes[i].splice(j, 1);
  }

  changeStartDate(event) {
    this.minDateDueDate = event.value;
    this.minDate = event.value;

    this.form.get('dueDate').markAsTouched();
    this.form.get('dueDate').updateValueAndValidity();

    this.form.controls.startDate.valid ? this.form.controls.dueDate.enable() : this.form.controls.dueDate.disable();
  }

  changeEndDate(event) {
    this.maxDate = event.value;
  }

  changeMinTime(event, i, j, duration, scheduleTimetable) {
    let minTime = moment(new Date());
    const splitTime = event.split(':');
    minTime.hour(splitTime[0]).minute(splitTime[1]);
    this.minTimes[i][j] = minTime.add(duration, 'minutes').format('kk:mm');

    setTimeout(() => {
      scheduleTimetable.get('dueTime').updateValueAndValidity();
    }, 100);

  }

  onSubmit() {
    const values = this.form.value;

    !api.production ? console.log('form', this.form) : null;

    let receptionTypes: ReceptionTypesInterface[] = [];
    let i = 0;

    for (let receptionType of values.receptionTypes) {

      let item: ReceptionTypesInterface = {
        TypeId: receptionType.typeId,
        Duration: receptionType.duration,
        Price: parseInt(receptionType.price),
        SpecializationId: receptionType.specializationId
      };

      receptionType.id ? item.Id = receptionType.id : null;

      receptionTypes.push(item);

      if (receptionType.scheduleTimetables) {
        let scheduleTimetables: ScheduleTimetablesInterface[] = [];

        for (let scheduleTimetable of values.receptionTypes[i].scheduleTimetables) {

          const startTime = scheduleTimetable.startTime;
          const dueTime = scheduleTimetable.dueTime;
          let startDate = moment(scheduleTimetable.date);
          let dueDate = moment(startDate);

          console.log(startDate);

          let startTimeSplit = startTime.split(':');
          let dueTimeSplit = dueTime.split(':');

          const newStartTime = startDate.hour(
            parseInt(startTimeSplit[0])
          ).minute(
            parseInt(startTimeSplit[1])
          ).second(0);

          const newDueTime = dueDate.hour(
            parseInt(dueTimeSplit[0])
          ).minute(
            parseInt(dueTimeSplit[1])
          ).second(0);

          newDueTime < newStartTime ? newDueTime.add(1, 'day') : null;

          let item2: ScheduleTimetablesInterface = {
            Date: dateCompilation(scheduleTimetable.date),
            StartDate: dateCompilation(newStartTime),
            DueDate: dateCompilation(newDueTime)
          };

          scheduleTimetable.id ? item2.Id = scheduleTimetable.id : null;

          scheduleTimetables.push(item2);
        }

        receptionTypes[i].ScheduleTimetables = scheduleTimetables;
      }
    }

    const startDateMain = moment(values.startDate).startOf('day');
    const dueDateMain = moment(values.dueDate).endOf('day');

    let request: ClinicSpecialistScheduleInterface = {
      Id: this.scheduleId,
      SpecialistId: this.specialistId,
      StartDate: dateCompilation(startDateMain),
      DueDate: dateCompilation(dueDateMain)
    };

    request.ReceptionTypes = receptionTypes;

    !api.production ? console.log('fast-test', request) : null;

    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.addClinicSpecialistScheduleSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.addClinicSpecialistScheduleSubscribe = this.clinicService.addClinicSpecialistSchedule(request)
      .subscribe((response: ClinicSpecialistScheduleResponseInterface) => {
        clearTimeout(timeout);
        !api.production ? console.log('CreateSpecialistScheduleResult', response) : null;

        if (response.CreateSpecialistScheduleResult.Success) {
          this.clinicService.openStandardDialog(
            'Вы успешно изменили график работы',
            'Вы изменили график работы для специалиста. Не забудьте его опубликовать!'
          );

          this.clearAll();
          this.selectSchedule = [];
          this.scheduleId = '';
          this.schedules = [];
          this.generateSelectSpecialistSchedules(this.specialistId);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });

  }

  checkPublicationSchedule() {
    this.dialogService.openSubmitDialog(
      'Подтвердите действие!',
      'Вы действительно хотите опубликовать график? После этого он будет виден всем клиентам и дальнейшее изменение в текущем интерфейсе будет не возможно.'
    ).subscribe(response => {
      response ? this.publicationSchedule() : null;
    });
  }

  checkRemoveSchedule() {
    this.dialogService.openSubmitDialog(
      'Подтвердите действие!',
      'Вы действительно хотите удалить график? После этого он будет удален безвозвратно и его востановление будет не возможно.'
    ).subscribe(response => {
      response ? this.removeSchedule() : null;
    });
  }

  publicationSchedule() {
    let request: IdInterface[] = [];

    request.push({
      Id: this.scheduleId
    });

    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.addClinicSpecialistScheduleSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.publicationScheduleSubscribe = this.clinicService.publicationSchedule(request)
      .subscribe((response: PublishScheduleResponseInterface) => {
        !api.production ? console.log('CreateSpecialistScheduleResult', response) : null;
        clearTimeout(timeout);

        if (response.PublishSpecialistScheduleResult.Success) {
          this.dialogService.openStatusDialog(
            'Вы успешно опубликовали график',
            'Вы опубликовали график работы специалиста. Теперь пользователи и администраторы смогут записываться на приемы!'
          );

          this.clearAll();
          this.selectSchedule = [];
          this.scheduleId = '';
          this.schedules = [];
          this.generateSelectSpecialistSchedules(this.specialistId);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });

  }

  removeSchedule() {
    let request: IdInterface = {
      Id: this.scheduleId
    };

    const timeout = setTimeout(() => {
      this.preloader = false;
      this.dialogService.showStandardError();
      this.addClinicSpecialistScheduleSubscribe.unsubscribe();
    }, api.requestTimeout);

    this.publicationScheduleSubscribe = this.clinicService.deleteSchedule(request)
      .subscribe((response: DeleteScheduleResponsiveInterface) => {
        !api.production ? console.log('RemoveSpecialistScheduleResult', response) : null;
        clearTimeout(timeout);

        if (response.RemoveSpecialistScheduleResult.Success) {
          this.dialogService.openStatusDialog(
            'Вы успешно удалили график',
            'Вы удалили график работы специалиста.'
          );

          this.clearAll();
          this.selectSchedule = [];
          this.scheduleId = '';
          this.schedules = [];
          this.generateSelectSpecialistSchedules(this.specialistId);

        } else {
          this.dialogService.showStandardError();
        }
      }, () => {
        clearTimeout(timeout);
        this.dialogService.showStandardError();
      });
  }

}
