<div class="auth-background">
  <div routerLink="/" class="auth-logo">
    <img src="assets/images/logo-white.svg" alt="">
  </div>

  <div class="auth-background-image">
    <img src="assets/images/auth-image-05.jpg" alt="">
  </div>

  <div class="auth-text-content">
    <h1>Пользовательское соглашение сервисов ООО «Зинграфен»</h1>
    <h2>Общие положения.</h2>
    <ol>
      <li> ООО &laquo;Зинграфен&raquo; (далее &mdash; &laquo;Marko&raquo;) предлагает Пользователю сети Интернет
        (далее &mdash; &laquo;Пользователь&raquo;) &mdash; использовать свои сервисы на условиях, изложенных в
        настоящем Пользовательском соглашении (далее &mdash; &laquo;Соглашение&raquo;, &laquo;ПС&raquo;).
        Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке,
        предусмотренном п. 1.4 Соглашения.
      </li>
      <li> &laquo;Marko&raquo; предлагает Пользователям доступ к широкому спектру сервисов, включая средства
        ведения и хранения медицинской документации, получения удаленных консультаций медицинского персонала,
        размещения и хранения разного рода информации и материалов (контента), персонализации контента, совершения
        покупок, иные сервисы. Все существующие на момент выражения Пользователем согласия с условиями Соглашения
        сервисы, а также любое развитие их и/или добавление новых является предметом настоящего Соглашения.
      </li>
      <li> Право пользования сервисами &laquo;Marko&raquo;, за исключением некоторых Дополнительных сервисов,
        предоставляется Пользователю бесплатно. Право использования Дополнительных сервисов считается
        предоставленным в момент активации Дополнительных сервисов Пользователем. Внесение оплаты за использование
        некоторых дополнительных сервисов осуществляется Пользователем в соответствии в правилами осуществления
        платежей (<a href="#">https:// mymarko.ru</a>).
      </li>
      <li> Использование сервисов &laquo;Marko&raquo; регулируется настоящим Соглашением, Политикой
        конфиденциальности, а также условиями использования отдельных сервисов. Соглашение может быть изменено
        администратором веб-сервиса &laquo;Marko&raquo; (Обществом с ограниченной ответственностью &laquo;Зинграфен&raquo;)
        без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее
        размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой
        редакцией Соглашения. Действующая редакция ПС всегда находится на странице по адресу:
        <a href="#">https://mymarko.ru/agreement</a>.
      </li>
      <li> Начиная использовать какой-либо сервис / его отдельные функции и пройдя процедуру регистрации,
        Пользователь считается принявшим условия Соглашения в полном объеме, без всяких оговорок и исключений. В
        случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не в праве использовать
        сервисы &laquo;Marko&raquo;. В случае если &laquo;Marko&raquo; были внесены какие-либо изменения в
        Соглашение в порядке, предусмотренном пунктом 1.3. Соглашения, с которыми Пользователь не согласен, он
        обязан прекратить использование сервисов &laquo;Marko&raquo;.
      </li>
    </ol>
    <h2>Регистрация Пользователя. Учетная запись Пользователя.</h2>
    <ol>
      <li> Для того чтобы воспользоваться некоторыми сервисами &laquo;Marko&raquo; или некоторыми отдельными
        функциями сервисов, Пользователю необходимо пройти процедуру регистрации, в результате которой для
        Пользователя будет создана уникальная учетная запись.
      </li>
      <li> Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам,
        предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь
        предоставляет неверную информацию или у &laquo;Marko&raquo; есть основания полагать, что предоставленная
        Пользователем информация неполна или недостоверна, &laquo;Marko&raquo; имеет право по своему усмотрению
        заблокировать либо удалить учетную запись Пользователя и отказать Пользователю в использовании своих
        сервисов (либо их отдельных функций).
      </li>
      <li> &laquo;Marko&raquo; оставляет за собой право в любой момент потребовать от Пользователя подтверждения
        данных, указанных при регистрации, и запросить в связи с этим подтверждающие документы, непредоставление
        которых, по усмотрению &laquo;Marko&raquo;, может быть приравнено к предоставлению недостоверной
        информации и повлечь последствия, предусмотренные п. 2.2. Соглашения.
      </li>
      <li> Персональная информация Пользователя, содержащаяся в учетной записи Пользователя, хранится и
        обрабатывается &laquo;Marko&raquo; в соответствии с условиями Политики конфиденциальности
        (https://mymarko.ru/agreement).
      </li>
      <li> Логин и пароль для доступа к учетной записи Пользователя: при регистрации Пользователь самостоятельно
        выбирает себе логин (уникальное символьное имя учетной записи Пользователя) и пароль для доступа к учетной
        записи. &laquo;Marko&raquo; вправе запретить использование определенных логинов, а также устанавливать
        требования к логину и паролю (длина, допустимые символы и т.д.).
      </li>
      <li> Пользователь самостоятельно несет ответственность за безопасность (устойчивость к угадыванию)
        выбранного им пароля, а также самостоятельно обеспечивает конфиденциальность своего пароля. Пользователь
        самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с
        использованием сервисов &laquo;Marko&raquo; под учетной записью Пользователя, включая случаи добровольной
        передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в
        том числе по договорам или соглашениям). При этом все действия в рамках или с использованием сервисов
        &laquo;Marko&raquo; под учетной записью Пользователя считаются произведенными самим Пользователем, за
        исключением случаев, когда Пользователь, в порядке, предусмотренном п. 2.7., уведомил &laquo;Marko&raquo;
        о несанкционированном доступе к сервисам &laquo;Marko&raquo; с использованием учетной записи Пользователя
        и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.
      </li>
      <li> Пользователь обязан немедленно уведомить &laquo;Marko&raquo; о любом случае несанкционированного (не
        разрешенного Пользователем) доступа к сервисам &laquo;Marko&raquo; с использованием учетной записи
        Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля. В целях
        безопасности, Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей
        учетной записью (кнопка &laquo;Выход&raquo;) по окончании каждой сессии работы с сервисами &laquo;Marko&raquo;.
        &laquo;Marko&raquo; не отвечает за возможную потерю или порчу данных, а также другие последствия любого
        характера, которые могут произойти из-за нарушения Пользователем положений этой части Соглашения.
      </li>
      <li> Использование Пользователем своей учетной записи: Пользователь не в праве воспроизводить, повторять и
        копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей какие-либо
        части сервисов &laquo;Marko&raquo; (включая контент, доступный Пользователю посредством сервисов), или
        доступ к ним, кроме тех случаев, когда Пользователь получил такое разрешение от &laquo;Marko&raquo;, либо
        когда это прямо предусмотрено Пользовательским соглашением какого-либо сервиса.
      </li>
      <li> Прекращение регистрации: администратор веб-сервиса &laquo;Marko&raquo; вправе заблокировать или удалить
        учетную запись Пользователя, а также запретить доступ с использованием какой-либо учетной записи к
        определенным сервисам &laquo;Marko&raquo;, и удалить любой контент без объяснения причин, в том числе в
        случае нарушения Пользователем условий Соглашения или условий иных документов, предусмотренных п. 1.3.
        Соглашения.
      </li>
    </ol>
    <h2>Удаление учетной записи Пользователя.</h2>
    <ol>
      <li> Пользователь вправе в любой момент удалить свою учетную запись на всех сервисах &laquo;Marko&raquo; или
        прекратить ее действие в отношении некоторых из них, направив со своей электронной почты письменное
        заявление по электронному адресу: support@mymaro.ru,
      </li>
      <li> Удаление учетной записи администратором веб-сервиса &laquo;Marko&raquo; осуществляется в следующем
        порядке:
        <ol>
          <li> Учетная запись блокируется на срок один месяц, в течение которого размещенные с ее использованием
            контент и иные Пользовательские данные не удаляются, однако доступ к ним становится невозможен как для
            Пользователя &mdash; владельца учетной записи, так и для других пользователей.
          </li>
          <li> Если в течение указанного выше срока учетная запись Пользователя будет восстановлена, доступ к
            указанным данным возобновляется в объеме, существовавшем на момент блокирования (за исключением
            контента, нарушающего условия Соглашения или иных документов, регулирующих соответствующий сервис).
          </li>
          <li> Если в течение указанного выше срока учетная запись Пользователя не будет восстановлена, весь
            контент, размещенный с ее использованием, будет удален, а логин будет доступен для использования
            другим Пользователям. С этого момента восстановление учетной записи, какой-либо информации,
            относящейся к ней, а равно доступов к сервисам &laquo;Marko&raquo; с использованием этой учетной
            записи &mdash; невозможны.
          </li>
        </ol>
      </li>
    </ol>
    <h2>Общие положения об использовании и хранении.</h2>
    <ol>
      <li> &laquo;Marko&raquo; вправе устанавливать ограничения в использовании сервисов для всех Пользователей,
        либо для отдельных категорий Пользователей (в зависимости от места пребывания Пользователя, языка, на
        котором предоставляется сервис и т.д.), в том числе: наличие / отсутствие отдельных функций сервиса,
        любого другого контента, максимальное количество сообщений, которые могут быть отправлены или получены
        одним зарегистрированным Пользователем, максимальный размер дискового пространства, максимальное
        количество обращений к сервису за указанный период времени, максимальный срок хранения контента,
        специальные параметры загружаемого контента и т.д. &laquo;Marko&raquo; может запретить автоматическое
        обращение к своим сервисам, а также прекратить прием любой информации, сгенерированной автоматически
        (например, почтового спама).
      </li>
      <li> &laquo;Marko&raquo; вправе посылать своим Пользователям информационные сообщения.</li>
    </ol>
    <h3>Контент Пользователя.</h3>
    <ol>
      <li> Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем
        контента требованиям действующего законодательства, включая ответственность перед третьим лицами в
        случаях, когда размещение Пользователем того или иного контента или содержание контента нарушает права и
        законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные
        права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.
      </li>
      <li> Пользователь признает и соглашается с тем, что &laquo;Marko&raquo; не обязан просматривать контент
        любого вида, размещаемый и/или распространяемый Пользователем посредством сервисов &laquo;Marko&raquo;, а
        также то, что &laquo;Marko&raquo; имеет право (но не обязанность) по своему усмотрению отказать
        Пользователю в размещении и/или распространении им контента или удалить любой контент, который доступен
        посредством сервисов &laquo;Marko&raquo;. Пользователь осознает и согласен с тем, что он должен
        самостоятельно оценивать все риски, связанные с использованием контента, включая оценку надежности,
        полноты или полезности этого контента.
      </li>
      <li> Пользователь осознает и соглашается с тем, что технология работы сервисов может потребовать копирование
        (воспроизведение) контента Пользователя &laquo;Marko&raquo;, а также переработки его &laquo;Marko&raquo;
        для соответствия техническим требованиям того или иного сервиса.
      </li>
    </ol>
    <h2>Условия использования сервисов &laquo;Marko&raquo;.</h2>
    <ol>
      <li> Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с
        использованием Сервиса, в том числе, если такие действия приведут к нарушению прав и законных интересов
        третьих лиц, а также за соблюдение законодательства при использовании Сервиса.
      </li>
      <li> При использовании сервисов &laquo;Marko&raquo; Пользователь не вправе:
        <ol>
          <li> Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент,
            который является незаконным, вредоносным, клеветническим, оскорбляет нравственность, демонстрирует
            (или является пропагандой) насилия и жестокости, нарушает права интеллектуальной собственности,
            пропагандирует ненависть и/или дискриминацию людей по расовому, этническому, половому, религиозному,
            социальному признакам, содержит оскорбления в адрес каких-либо лиц или организаций, содержит элементы
            (или является пропагандой) порнографии, детской эротики, представляет собой рекламу (или является
            пропагандой) услуг сексуального характера (в том числе под видом иных услуг), разъясняет порядок
            изготовления, применения или иного использования наркотических веществ или их аналогов, взрывчатых
            веществ или иного оружия.
          </li>
          <li> Нарушать права третьих лиц, в том числе несовершеннолетних, и/или причинять им вред в любой
            форме.
          </li>
          <li> Выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на
            то прав, в том числе за сотрудников &laquo;Marko&raquo;, за модераторов форумов, за владельца сайта, а
            также применять любые другие формы и способы незаконного представительства других лиц в сети, а также
            вводить пользователей или &laquo;Marko&raquo; в заблуждение относительно свойств и характеристик
            каких-либо субъектов или объектов.
          </li>
          <li> Загружать, посылать, передавать или любым другим способом размещать и/или распространять контент,
            при отсутствии прав на такие действия согласно законодательству или каким-либо договорным отношениям.
          </li>
          <li> Загружать, посылать, передавать или любым другим способом размещать и/или распространять не
            разрешенную специальным образом рекламную информацию, спам (в том числе и поисковый), списки чужих
            адресов электронной почты, схемы &laquo;пирамид&raquo;, многоуровневого (сетевого) маркетинга (MLM),
            системы интернет-заработка и e-mail- бизнесов, &laquo;письма счастья&raquo;, а также использовать
            сервисы &laquo;Marko&raquo; для участия в этих мероприятиях, или использовать сервисы &laquo;Marko&raquo;,
            исключительно для перенаправления пользователей на страницы других доменов.
          </li>
          <li> Загружать, посылать, передавать или любым другим способом размещать и/или распространять какие-либо
            материалы, содержащие вирусы или другие компьютерные коды, файлы или программы, предназначенные для
            нарушения, уничтожения либо ограничения функциональности любого компьютерного или
            телекоммуникационного оборудования или программ, для осуществления несанкционированного доступа, а
            также серийные номера к коммерческим программным продуктам и программы для их генерации, логины,
            пароли и прочие средства для получения несанкционированного доступа к платным ресурсам в Интернете, а
            также размещения ссылок на вышеуказанную информацию.
          </li>
          <li> Несанкционированно собирать и хранить персональные данные других лиц.</li>
          <li> Нарушать нормальную работу веб-сайтов и сервисов &laquo;Marko&raquo;.</li>
          <li> Размещать ссылки на ресурсы сети, содержание которых противоречит действующему законодательству
            РФ.
          </li>
          <li> Способствовать действиям, направленным на нарушение ограничений и запретов, налагаемых
            Соглашением.
          </li>
          <li> Другим образом нарушать нормы законодательства РФ, а также нормы международного права.</li>
        </ol>
      </li>
    </ol>
    <h2>Исключительные права на содержание сервисов и контент.</h2>
    <ol>
      <li> Все объекты, доступ к которым возможен при помощи сервисов &laquo;Marko&raquo;, включая элементы
        дизайна, текст, графические изображения, иллюстрации, видео, программы для ЭВМ, базы данных, музыка, звуки
        и другие объекты (далее &mdash; &laquo;содержание сервисов&raquo;), а также любой контент, размещенный на
        сервисах &laquo;Marko&raquo;, являются объектами исключительных прав &laquo;Marko&raquo;, Пользователей и
        других правообладателей.
      </li>
      <li> Использование контента, а также каких-либо иных элементов сервисов возможно только в рамках
        функционала, предлагаемого тем или иным сервисом. Никакие элементы содержания сервисов &laquo;Marko&raquo;,
        а также любой контент, размещенный на сервисах &laquo;Marko&raquo;, не могут быть использованы иным
        образом без предварительного разрешения правообладателя. Под использованием подразумеваются, в том числе:
        воспроизведение, копирование, переработка, распространение на любой основе, отображение во фрейме и т.д.
        Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования того
        или иного сервиса &laquo;Marko&raquo;.
      </li>
      <li> Использование Пользователем элементов содержания сервисов, а также любого контента для личного
        некоммерческого использования, допускается при условии сохранения всех знаков охраны авторского права,
        смежных прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима)
        автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном
        виде. Исключение составляют случаи, прямо предусмотренные законодательством РФ или Пользовательскими
        соглашениями того или иного сервиса &laquo;Marko&raquo;.
      </li>
    </ol>
    <h2>Сайты и контент третьих лиц.</h2>
    <ol>
      <li> Сервисы &laquo;Marko&raquo; могут содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц).
        Указанные третьи лица и их контент не проверяются &laquo;Marko&raquo; на соответствие тем или иным
        требованиям (достоверности, полноты, законности и т.п.). &laquo;Marko&raquo; не несет ответственность за
        любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с
        использованием сервисов, в том числе, за любые мнения или утверждения, выраженные на сайтах третьих лиц,
        рекламу и т.п., а также за доступность таких сайтов или контента и последствия их использования
        Пользователем.
      </li>
      <li> Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или
        некоммерческого характера, размещенная на Сайте, не является одобрением или рекомендацией данных продуктов
        (услуг, деятельности) со стороны &laquo;Marko&raquo;, за исключением случаев, когда на это прямо
        указывается на ресурсах &laquo;Marko&raquo;.
      </li>
    </ol>
    <h2>Реклама на сервисах &laquo;Marko&raquo;.</h2>
    <ol>
      <li> &laquo;Marko&raquo; несет ответственность за рекламу, размещенную на сервисах &laquo;Marko&raquo;, в
        пределах, установленных законодательством РФ.
      </li>
    </ol>
    <h2>Отсутствие гарантий, ограничение ответственности.</h2>
    <ol>
      <li> Пользователь использует Сервисы &laquo;Marko&raquo; на свой собственный риск. Сервисы предоставляются
        &laquo;как есть&raquo;. &laquo;Marko&raquo; не принимает на себя никакой ответственности (в том числе за
        соответствие сервисов целям Пользователя), за исключением случаев, прямо предусмотренных настоящим
        Соглашением.
      </li>
      <li> &laquo;Marko&raquo; не гарантирует, что: сервисы соответствуют / будут соответствовать требованиям
        Пользователя; сервисы будут предоставляться непрерывно, быстро, надежно и без ошибок; результаты, которые
        могут быть получены с использованием сервисов, будут точными и надежными и могут использоваться для
        каких-либо целей или в каком-либо качестве (например, для установления и/или подтверждения каких-либо
        фактов); качество какого-либо продукта, услуги, информации и пр., полученных с использованием сервисов,
        будет соответствовать ожиданиям Пользователя;
      </li>
      <li> Любые информацию и/или материалы (в том числе загружаемое ПО, письма, какие-либо инструкции и
        руководства к действию и т.д.), доступ к которым Пользователь получает с использованием сервисов &laquo;Marko&raquo;,
        Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за
        возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который
        это может причинить компьютеру Пользователя или третьим лицам, за потерю данных или любой другой вред.
      </li>
      <li> &laquo;Marko&raquo; не несет ответственности за любые виды убытков, произошедшие вследствие
        использования Пользователем сервисов &laquo;Marko&raquo; или отдельных частей/функций сервисов.
      </li>
      <li> При любых обстоятельствах ответственность &laquo;Marko&raquo; в соответствии со статьей 15 Гражданского
        кодекса России ограничена 10 000,00 (Десятью тысячами) рублей РФ и возлагается на веб-сервис при наличии в
        его действиях вины.
      </li>
    </ol>
    <h2>Консультации специалистов в системе &laquo;Marko&raquo;.</h2>
    <ol>
      <li> Врачи, осуществляющие консультации Пользователя посредством системы &laquo;Marko&raquo;, имеют все
        разрешительные документы и лицензии для работы врачом на территории РФ.
      </li>
      <li> Рекомендации врачей, полученные посредством общения через сервисы &laquo;Marko&raquo; не являются
        обязательными к выполнению Пользователем.
      </li>
      <li> Услуги сервисов системы &laquo;Marko&raquo; по получению Пользователем консультаций, рекомендаций
        врачей, хранению личной медицинской информации, иные услуги не являются медицинскими услугами, а относятся
        к категории информационно-технологических услуг.
      </li>
    </ol>
    <h2>Иные положения.</h2>
    <ol>
      <li> Настоящее Соглашение представляет собой договор между Пользователем и &laquo;Marko&raquo; относительно
        порядка использования сервисов и заменяет собой все предыдущие соглашения между Пользователем и &laquo;Marko&raquo;.
      </li>
      <li> Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации.
        Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством
        Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением,
        разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам
        российского права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином &laquo;законодательство&raquo;
        понимается законодательство Российской Федерации.
      </li>
      <li> Ввиду безвозмездности услуг, оказываемых в рамках настоящего Соглашения, за исключением предоставления
        отдельных Дополнительных сервисов, нормы о защите прав потребителей, предусмотренные законодательством
        Российской Федерации, не могут быть к применимыми к отношениям между Пользователем и &laquo;Marko&raquo;.
      </li>
      <li> Ничто в Соглашении не может пониматься как установление между Пользователем и &laquo;Marko&raquo;
        агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного
        найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.
      </li>
      <li> Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны
        недействительными или не имеющими юридической силы, это не окажет влияния на действительность или
        применимость остальных положений Соглашения.
      </li>
      <li> Бездействие со стороны &laquo;Marko&raquo; в случае нарушения Пользователем либо иными Пользователями
        положений Соглашений не лишает &laquo;Marko&raquo; права предпринять соответствующие действия в защиту
        своих интересов позднее, а также не означает отказа &laquo;Marko&raquo; от своих прав в случае совершения
        в последующем подобных либо сходных нарушений.
      </li>
      <li> Настоящее Соглашение составлено на русском языке и в некоторых случаях может быть предоставлено
        Пользователю для ознакомления на другом языке. В случае расхождения русскоязычной версии Соглашения и
        версии Соглашения на ином языке, применяются положения русскоязычной версии настоящего Соглашения.
      </li>
    </ol>
    <p>Принимая условия настоящего Соглашения, Пользователь тем самым дает свое согласие на обработку его
      персональных данных, согласно Федеральному закону от 27.07.2006 No 152-ФЗ &laquo;О персональных данных&raquo;,
      для целей обеспечения удаленного доступа к личному кабинету Пользователя, предоставления всех сервисов
      &laquo;Marko&raquo; и для оказания услуг Пользователю. К персональным данным, на обработку которых дает
      согласие Пользователь, относятся: Ф.И.О., дата рождения, пол, город проживания, телефон и адрес электронной
      почты, файлы cookie, медицинские и иные сведения, составляющие врачебную тайну, согласно ст. 13 Федерального
      закона от 21.11.2011 No 323-ФЗ &laquo;Об основах охраны здоровья граждан в Российской Федерации&raquo;,
      размещаемые в личном кабинете медицинским учреждением, с которым Пользователь заключил партнерский договор,
      либо размещаемые Пользователем самостоятельно. Настоящее согласие действует со дня его подписания до дня
      отзыва в письменной форме.</p>
  </div>
</div>

