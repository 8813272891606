import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { ClinicComponent } from './clinic.component';
import { ClinicInfoComponent } from './clinic-info/clinic-info.component';
import { ClinicEditComponent } from './clinic-edit/clinic-edit.component';
import { ClinicPriceComponent } from './clinic-price/clinic-price.component';

const routes: Routes = [
  {
    path: 'clinic/:id', component: ClinicComponent, canActivate: [AuthGuard], children: [
      {path: '', redirectTo: 'info', pathMatch: 'full'},
      {path: 'info', component: ClinicInfoComponent},
      {path: 'price', component: ClinicPriceComponent},
      {path: 'edit', component: ClinicEditComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClinicRoutingModule {
}
