<mat-dialog-content class="mat-typography">
  <div class="dialog-title">Добавить специалиста в клинику</div>

  <form class="form-group"
        *ngIf="!preloader"
        [formGroup]="form"
        (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-md-6 col-12">
        <mat-form-field>
          <mat-label>Тип добавления специалиста <i class="danger">*</i></mat-label>
          <mat-select formControlName="type" (selectionChange)="changeType($event)">
            <mat-option value="create">
              Создать нового
            </mat-option>
            <mat-option value="invite">
              Специалист уже зарегистрирован
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.get('type').hasError('required')">
            Поле не может быть пустым
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12">
        <mat-form-field>
          <mat-label>E-mail <i class="danger">*</i></mat-label>
          <input
            type="email"
            matInput
            id="email"
            formControlName="email">
          <mat-error *ngIf="form.get('email').hasError('required')">
            Поле не может быть пустым
          </mat-error>
          <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
            Не верно указан E-mail
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 col-12" *ngIf="!invite">
        <dadata-mat [type]="'fio'"
                    [parts]="['NAME']"
                    limit="5"
                    id="first-name"
                    placeholder="Имя"
                    [required]="true"
                    [control]="form.controls.firstName"
                    formControlName="firstName">
        </dadata-mat>
      </div>
      <div class="col-md-6 col-12" *ngIf="!invite">
        <dadata-mat [type]="'fio'"
                    [parts]="['SURNAME']"
                    limit="5"
                    id="sur-name"
                    placeholder="Фамилия"
                    [required]="true"
                    [control]="form.controls.surName"
                    formControlName="surName">
        </dadata-mat>
      </div>
      <div class="col-md-6 col-12" *ngIf="!invite">
        <dadata-mat [type]="'address'"
                    fromBound="city"
                    limit="5"
                    id="city"
                    placeholder="Город"
                    [required]="true"
                    [cityFiasCheck]="true"
                    [control]="form.controls.city"
                    formControlName="city">
        </dadata-mat>
      </div>
      <div class="col-md-6 col-12" *ngIf="!invite">
        <mat-form-field>
          <mat-label>Специализация <i class="danger">*</i></mat-label>
          <mat-select formControlName="specialization" multiple>
            <mat-option *ngFor="let option of selectSpecialization"
                        [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.get('specialization').hasError('required')">
            Поле не может быть пустым
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <app-preloader *ngIf="preloader"></app-preloader>

</mat-dialog-content>
<mat-dialog-actions align="center" class="dialog-buttons">
  <button type="button" mat-dialog-close class="btn btn-default btn-small">Отмена</button>
  <button type="button" class="btn btn-primary btn-small" [disabled]="form.invalid" (click)="onSubmit()" *ngIf="!invite">Создать специалиста</button>
  <button type="button" class="btn btn-primary btn-small" [disabled]="form.invalid" (click)="onSubmit()" *ngIf="invite">Пригласить специалиста</button>
</mat-dialog-actions>
