import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { AccountComponent } from './account.component';
import { AccountEditComponent } from './account-edit/account-edit.component';
import { AccountInfoComponent } from './account-info/account-info.component';
import { AccountChangePasswordComponent } from './account-change-password/account-change-password.component';
import { ClinicListComponent } from './clinic-list/clinic-list.component';
import { ClinicAddComponent } from './clinic-add/clinic-add.component';

const routes: Routes = [
  {path: 'account', component: AccountComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'info', pathMatch: 'full' },
      { path: 'info', component: AccountInfoComponent },
      { path: 'edit', component: AccountEditComponent },
      { path: 'change-password', component: AccountChangePasswordComponent },
      { path: 'clinic-list', component: ClinicListComponent },
      { path: 'clinic-add', component: ClinicAddComponent }
    ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule {}
