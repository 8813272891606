<div class="content-wrapper">
  <div class="main-title">
    Регистрация клиники
  </div>

  <div class="main-container" *ngIf="!preloaderVisibility">
    <form class="form-group"
          [formGroup]="form"
          (ngSubmit)="onSubmit()">

      <mat-form-field>
        <mat-label> Название клиники <i class="danger">*</i></mat-label>
        <input
          type="text"
          matInput
          formControlName="name">

        <mat-error *ngIf="form.get('name').hasError('required')">
          Поле не может быть пустым
        </mat-error>
      </mat-form-field>

      <dadata-mat [type]="'address'"
                  fromBound="city"
                  limit="5"
                  placeholder="Город"
                  [required]="true"
                  [cityFiasCheck]="true"
                  [control]="form.controls.city"
                  (selected)="setCityFias($event)"
                  formControlName="city">
      </dadata-mat>

      <dadata-mat [type]="'address'"
                  fromBound="street"
                  toBound="house"
                  limit="5"
                  placeholder="Адрес"
                  [locations]="[{'city_fias_id': cityFias}]"
                  [restrict]="true"
                  [control]="form.controls.address"
                  formControlName="address">
      </dadata-mat>

      <mat-form-field *ngIf="showMetro">
        <mat-label>Метро</mat-label>
        <mat-select formControlName="metro">
          <mat-option *ngFor="let option of selectMetro"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Телефон клиники <i class="danger">*</i></mat-label>
        <input
          type="tel"
          matInput
          [textMask]="phoneMask"
          formControlName="phone">
      </mat-form-field>

      <mat-form-field>
        <mat-label>Сайт</mat-label>
        <input
          type="text"
          matInput
          formControlName="site">
      </mat-form-field>

      <h2>Направления клиники</h2>

      <div formArrayName="clinicDirections">
        <div class="main-info-block" *ngFor="let item of clinicDirections.controls; let i = index">
          <div class="info-block-background">

            <mat-form-field>
              <mat-label>Выберите направление <i class="danger">*</i></mat-label>
              <mat-select [formControl]="item['controls']['clinicAction']">
                <mat-option *ngFor="let option of selectClinicActions"
                            [value]="option.value">
                  {{option.name}}
                </mat-option>
              </mat-select>

              <mat-error *ngIf="item['controls']['clinicAction'].invalid">
                Поле не может быть пустым
              </mat-error>
            </mat-form-field>

            <mat-form-field>
              <mat-label>Выберите услуги по направлению <i class="danger">*</i></mat-label>
              <mat-select multiple
                          [formControl]="item['controls']['clinicDirections']">
                <mat-option *ngFor="let option of selectClinicDirections"
                            [value]="option.value">
                  {{option.name}}
                </mat-option>
              </mat-select>

              <mat-error *ngIf="item['controls']['clinicDirections'].invalid">
                Поле не может быть пустым
              </mat-error>
            </mat-form-field>

            <input type="hidden"
                   [formControl]="item['controls']['id']">

            <button type="button"
                    class="remove-field"
                    *ngIf="!item['controls']['id'].value"
                    (click)="removeClinicDirections(i)">
              <i class="if if-close"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button type="button"
                class="btn btn-primary"
                (click)="addClinicDirections()">
          Добавить
        </button>
      </div>

      <h2>Страховые компании</h2>

      <div formArrayName="insuranceCompanies">
        <div class="main-info-block" *ngFor="let item of clinicInsuranceCompany.controls; let i = index">
          <div class="info-block-background">

            <mat-form-field>
              <mat-label> Название компании <i class="danger">*</i></mat-label>
              <input
                type="text"
                matInput
                [formControl]="item['controls']['companyName']">

              <mat-error *ngIf="item['controls']['companyName'].invalid">
                Поле не может быть пустым
              </mat-error>
            </mat-form-field>

            <input type="hidden"
                   [formControl]="item['controls']['id']">

            <button type="button"
                    class="remove-field"
                    *ngIf="!item['controls']['id'].value"
                    (click)="removeClinicInsuranceCompany(i)">
              <i class="if if-close"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button type="button"
                class="btn btn-primary"
                (click)="addClinicInsuranceCompany()">
          Добавить
        </button>
      </div>

      <h2>График работы клиники</h2>

      <div formArrayName="clinicSchedule">
        <div class="clinic-schedule-item" *ngFor="let item of clinicSchedule.controls; let i = index">
          <div class="clinic-schedule-item">
            <div class="name">{{item['controls']['weekDay'].value | catalogName: listWeekDays}}</div>
            <div class="time">
              <span>с</span>
              <mat-form-field>
                <input
                  type="text"
                  matInput
                  readonly
                  [formControl]="item['controls']['startTime']"
                  [ngxTimepicker]="startTime"
                  [format]="24"
                  [appDisableControl]="item['controls']['roundClock'].value || item['controls']['holiday'].value">
              </mat-form-field>
              <ngx-material-timepicker #startTime [ngxMaterialTimepickerTheme]="timePickerTheme"></ngx-material-timepicker>
            </div>
            <div class="time">
              <span>до</span>
              <mat-form-field>
                <input
                  type="text"
                  matInput
                  readonly
                  [formControl]="item['controls']['endTime']"
                  [ngxTimepicker]="endTime"
                  [format]="24"
                  [appDisableControl]="item['controls']['roundClock'].value || item['controls']['holiday'].value">
              </mat-form-field>
              <ngx-material-timepicker #endTime [ngxMaterialTimepickerTheme]="timePickerTheme"></ngx-material-timepicker>
            </div>
            <mat-checkbox [formControl]="item['controls']['holiday']"
                          [appDisableControl]="item['controls']['roundClock'].value">
              Выходной
            </mat-checkbox>
            <mat-checkbox [formControl]="item['controls']['roundClock']"
                          [appDisableControl]="item['controls']['holiday'].value">
              Круглосуточно
            </mat-checkbox>
          </div>
        </div>
      </div>

      <h2>Описание клиники</h2>

      <mat-form-field>
        <mat-label>Описание клиники</mat-label>
        <textarea
          matInput
          mat-autosize
          formControlName="description">
        </textarea>
      </mat-form-field>

      <div class="last-buttons">
        <button type="button"
                class="btn btn-default"
                routerLink="/account">
          Отмена
        </button>
        <button type="submit"
                class="btn btn-primary"
                [disabled]="form.invalid">
          Сохранить
        </button>
      </div>
    </form>
  </div>

  <div class="load-content-spinner" *ngIf="preloaderVisibility">
    <img src="assets/images/load-content-spinner.svg" alt="">
  </div>
</div>
