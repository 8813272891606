import { OnInit } from '@angular/core';
import { ClinicService } from '../../shared/services/clinic.service';
import { clinicActions, clinicServices, typeId } from '../../shared/catalogs/clinic.catalogs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { BaseService } from '../../shared/services/base.service';
import { DialogService } from '../../shared/services/dialog.service';
import { isEmpty } from '../../shared/functions/functions';
import { Router } from '@angular/router';
var ClinicPriceComponent = /** @class */ (function () {
    function ClinicPriceComponent(clinicService, baseServices, dialogService, router) {
        this.clinicService = clinicService;
        this.baseServices = baseServices;
        this.dialogService = dialogService;
        this.router = router;
        this.directions = [];
        this.preloader = true;
        this.selectClinicActions = clinicActions;
        this.selectClinicServices = clinicServices;
        this.selectTypeId = typeId;
        this.filteredOptions = this.selectClinicServices.slice();
        this.forRemove = {};
        this.accountId = this.clinicService.clinicFullData.AccountData.AccountId;
    }
    ClinicPriceComponent.prototype.ngOnInit = function () {
        this.initForm();
        this.initDirections();
        this.initPriceList();
    };
    ClinicPriceComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.selectClinicServices.filter(function (option) { return option.name.toLowerCase().includes(filterValue); });
    };
    ClinicPriceComponent.prototype.changeAutoFill = function (event) {
        this.filteredOptions = this._filter(event.target.value);
    };
    ClinicPriceComponent.prototype.displayFn = function (options) {
        return function (value) {
            var correspondingOption = Array.isArray(options) ? options.find(function (option) { return option.value === value; }) : null;
            var returnValue = value ? value : '';
            return correspondingOption ? correspondingOption.name : returnValue;
        };
    };
    ClinicPriceComponent.prototype.initDirections = function () {
        var _this = this;
        var fullDirection = this.clinicService.getClinicFullData().AccountDirections;
        fullDirection.forEach(function (item) {
            _this.directions.push(item.Action);
        });
        this.directions = this.directions.filter(function (item, index) {
            return _this.directions.indexOf(item) === index;
        });
    };
    ClinicPriceComponent.prototype.initPriceList = function () {
        var priceList = this.clinicService.getClinicFullData().AccClinicService;
        var parent = this.form.get('directions');
        for (var i = 0; i < this.directions.length; i++) {
            if (!parent.controls[i]) {
                parent.push(this.initFormServices());
            }
            var j = 0;
            for (var _i = 0, priceList_1 = priceList; _i < priceList_1.length; _i++) {
                var item = priceList_1[_i];
                if (item.Action === this.directions[i]) {
                    var controls = parent.controls[i].get('services');
                    var service = void 0;
                    if (item.ClinicService) {
                        service = item.ClinicService;
                    }
                    else {
                        service = item.CustomText;
                    }
                    controls.push(new FormGroup({
                        'id': new FormControl(item.Id),
                        'action': new FormControl(item.Action, [Validators.required]),
                        'clinicService': new FormControl(service, [Validators.required]),
                        'cost': new FormControl(item.Cost, [Validators.required]),
                        'type': new FormControl(item.TypeId, [Validators.required])
                    }));
                    controls.controls[j].get('clinicService').patchValue(service);
                    j++;
                }
            }
        }
        this.preloader = false;
    };
    ClinicPriceComponent.prototype.initForm = function () {
        this.form = new FormGroup({
            'directions': new FormArray([])
        });
    };
    ClinicPriceComponent.prototype.initFormServices = function () {
        return new FormGroup({
            'services': new FormArray([])
        });
    };
    ClinicPriceComponent.prototype.initFormService = function (action) {
        return new FormGroup({
            'id': new FormControl(null),
            'action': new FormControl(action, [Validators.required]),
            'clinicService': new FormControl(null, [Validators.required]),
            'cost': new FormControl(null, [Validators.required]),
            'type': new FormControl(null, [Validators.required])
        });
    };
    ClinicPriceComponent.prototype.addFormService = function (i) {
        var parent = this.form.get('directions');
        var controls = parent.controls[i].get('services');
        controls.push(this.initFormService(this.directions[i]));
    };
    ClinicPriceComponent.prototype.getFormServices = function (form) {
        return form.controls.services.controls;
    };
    ClinicPriceComponent.prototype.getFormDirections = function (form) {
        return form.controls.directions.controls;
    };
    ClinicPriceComponent.prototype.removeFormService = function (i, j) {
        var controls = this.form.get(['directions', i, 'services']);
        var id = controls.controls[j].get('id').value;
        if (id) {
            if (!this.forRemove.AccClinicService)
                this.forRemove.AccClinicService = [];
            this.forRemove.AccClinicService.push({
                Id: id
            });
        }
        controls.removeAt(j);
    };
    ClinicPriceComponent.prototype.onSubmit = function () {
        !api.production ? console.log('form', this.form) : null;
        var values = this.form.value;
        var services = [];
        for (var _i = 0, _a = values.directions; _i < _a.length; _i++) {
            var direction = _a[_i];
            var _loop_1 = function (price) {
                var noCustom = this_1.selectClinicServices.find(function (option) { return option.value === price.clinicService; });
                var service = {
                    Id: price.id,
                    Action: price.action,
                    Cost: price.cost,
                    TypeId: price.type,
                };
                noCustom ? service['ClinicService'] = price.clinicService : service['CustomText'] = price.clinicService;
                services.push(this_1.baseServices.clearEmpty(service));
            };
            var this_1 = this;
            for (var _b = 0, _c = direction.services; _b < _c.length; _b++) {
                var price = _c[_b];
                _loop_1(price);
            }
        }
        var request = {
            SourceID: api.sourceID,
            SourceSecretKey: api.sourceSecretKey,
            AccClinicService: services,
            AccountData: {
                AccountId: this.clinicService.getClinicFullData().AccountData.AccountId
            }
        };
        if (isEmpty(this.forRemove)) {
            this.savePriceList(request);
        }
        else {
            this.deleteFromPrice(request);
        }
        !api.production ? console.log('request', request) : null;
    };
    ClinicPriceComponent.prototype.deleteFromPrice = function (request) {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.dialogService.showStandardError();
            _this.clinicRemoveSubscribe.unsubscribe();
        }, api.requestTimeout);
        this.clinicRemoveSubscribe = this.clinicService.deleteAccountDetail(this.forRemove).subscribe(function (response) {
            clearTimeout(timeout);
            !api.production ? console.log('DeleteAccountDetailResult', response) : null;
            if (response.DeleteAccountDetailResult.Success) {
                _this.savePriceList(request);
            }
            else {
                _this.dialogService.showStandardError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.dialogService.showStandardError();
        });
    };
    ClinicPriceComponent.prototype.savePriceList = function (request) {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.dialogService.showStandardError();
            _this.clinicEditSubscribe.unsubscribe();
        }, api.requestTimeout);
        this.clinicEditSubscribe = this.clinicService.editClinic(request).subscribe(function (response) {
            clearTimeout(timeout);
            !api.production ? console.log('clinicEditResult', response) : null;
            if (response.EditAccountResult.Success) {
                _this.clinicService.openStandardDialog('Вы успешно сохранили прайс лист', 'Вы изменили прайс лист клиники, теперь новая информация будет доступна всем пользователям!');
                _this.getNewClinicData();
            }
            else {
                _this.dialogService.showStandardError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.dialogService.showStandardError();
        });
    };
    ClinicPriceComponent.prototype.getNewClinicData = function () {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.showRefreshError();
            _this.getCurrentClinicSubscribe.unsubscribe();
        }, api.requestTimeout);
        this.preloader = true;
        this.getCurrentClinicSubscribe = this.clinicService.currentClinic(this.accountId).subscribe(function (response) {
            clearTimeout(timeout);
            !api.production ? console.log('clinicEditResult', response) : null;
            if (response.CurrentClinicResult.Success) {
                _this.clinicService.setClinicFullData(response.CurrentClinicResult);
                _this.clearAll();
                _this.initDirections();
                _this.initPriceList();
                _this.filteredOptions = _this.selectClinicServices;
            }
            else {
                _this.showRefreshError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.showRefreshError();
        });
    };
    ClinicPriceComponent.prototype.showRefreshError = function () {
        var _this = this;
        this.dialogService.openStatusDialog('При обновлении данных произошла ошибка', 'Ваши изменения были сохранены, но при обновлении данных произошла ошибка. Попробуйте заново открыть страницу.', true, 'back').subscribe(function (response) {
            if (response === 'back')
                _this.router.navigate(['/clinic', _this.clinicService.activeClinic, 'info']);
        });
    };
    ClinicPriceComponent.prototype.clearAll = function () {
        this.forRemove = {};
        this.directions = [];
        var directions = this.form.get('directions');
        while (directions.length !== 0) {
            directions.removeAt(0);
        }
    };
    return ClinicPriceComponent;
}());
export { ClinicPriceComponent };
