<div class="content-wrapper">
  <div class="main-title">
    {{userCurrentData.Firstname}} {{userCurrentData.Surname}}
  </div>

  <app-account-sidebar class="account-sidebar"></app-account-sidebar>

  <div class="main-container">
    <div class="account-email-verification" *ngIf="showConfirmationEmail">
      <div class="text">Необходимо подтвердить E-mail</div>
      <button class="btn btn-primary btn-small" (click)="sendConfirmationRequest()">Отправить</button>
    </div>
    <div class="row main-info-block-wrapper">
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">E-mail</div>
        <div class="text">{{userCurrentData.Email}}</div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">Телефон</div>
        <div class="text">{{userCurrentData.Phone | phone}}</div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">Город</div>
        <div class="text">{{userCurrentData.City}}</div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">Адрес</div>
        <div class="text">{{userCurrentData.Address}}</div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">Возраст</div>
        <div class="text">{{userCurrentData.BirthDate | age}}</div>
      </div>
      <div class="col-xl-6 col-lg-12 col-md-6 main-info-block">
        <div class="title">Тип владельца</div>
        <div class="text">Владелец</div>
      </div>
      <div class="col-12 main-info-block">
        <div class="title">О себе</div>
        <div class="text large-text">{{userCurrentData.AboutSpecialist}}</div>
      </div>
    </div>
  </div>
</div>
