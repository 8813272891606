import { SelectInterface } from '../interfaces/select.interface';

export const doctorSpecialization: SelectInterface[] = [
  {value: '2b1ee8a9-ec75-4948-9049-55ebe68f7e97',	name: 'Анестезиолог'},
  {value: 'a24704c7-cc5b-4b88-ae47-24e83a72789e',	name: 'Герпетолог'},
  {value: 'e587abcb-13d9-4468-8e6b-173f7adec54d',	name: 'Гинеколог-акушер'},
  {value: '8de65567-19fd-4c3a-8c57-d9580bf12e27',	name: 'Грумер'},
  {value: '46dbd96b-11dd-40d6-8105-c80d74723605',	name: 'Дерматолог'},
  {value: 'bb4185ac-7360-447e-9ced-10cb4ccc1184',	name: 'Кардиолог'},
  {value: '5fb8978f-ec08-41f7-8e4a-93b23fb9082a',	name: 'Лаборант'},
  {value: '16e5bd5d-d6bf-443e-96ba-7f30f89caf92',	name: 'Невролог'},
  {value: 'f4d36a52-92de-4003-a48b-1d8c924cd97f',	name: 'Онколог'},
  {value: '2d85c5d9-12e5-410a-9b3c-7b69f75b0ae8',	name: 'Офтальмолог'},
  {value: '30ff1029-1373-4099-929f-35d80566e1e2',	name: 'Специалист по грызунам и экзотическим животным'},
  {value: '98e9ec43-4045-4229-bb4f-950c30ee1e80',	name: 'Стоматолог'},
  {value: '005c4a19-2be0-4a8c-8ac0-d6ce79ea4261',	name: 'Терапевт'},
  {value: '331e307b-ae36-4a1b-b020-fcb5dd246008',	name: 'Хирург'},
  {value: '113756c1-9f79-40b1-8b38-aaf133d82529',	name: 'Эндокринолог'},
  {value: '5725ef01-8772-414a-8207-7e1a1fc984a9',	name: 'Эндоскопист'},
];

export const jobChangeReason: SelectInterface[] = [
  {value: '38296fb8-57e6-df11-971b-001d60e938c6', name: 'Better job offer'},
  {value: '51580fbf-57e6-df11-971b-001d60e938c6', name: 'Career growth/promotion'},
  {value: '3adf39c5-57e6-df11-971b-001d60e938c6', name: 'Personal reasons'},
  {value: '87f935cf-57e6-df11-971b-001d60e938c6', name: 'Good career opportunity'}
];

export const job: SelectInterface[] = [
  {value: '11d68189-ced6-df11-9b2a-001d60e938c6', name: 'Developer'},
  {value: '955472f0-56e6-df11-971b-001d60e938c6', name: 'Sales manager'},
  {value: '33f48df9-56e6-df11-971b-001d60e938c6', name: 'Marketing manager'},
  {value: '34f48df9-56e6-df11-971b-001d60e938c6', name: 'CEO'},
  {value: 'ca49a603-57e6-df11-971b-001d60e938c6', name: 'Department manager'},
  {value: 'cb49a603-57e6-df11-971b-001d60e938c6', name: 'Specialist'}
];

export const department: SelectInterface[] = [
  {value: '66ffa487-b4da-df11-9b2a-001d60e938c6', name: 'Administration'},
  {value: '4691a98e-b4da-df11-9b2a-001d60e938c6', name: 'Logistics'},
  {value: '4791a98e-b4da-df11-9b2a-001d60e938c6', name: 'Marketing'},
  {value: '769964a0-b4da-df11-9b2a-001d60e938c6', name: 'Production'},
  {value: '9b90c192-60e6-df11-971b-001d60e938c6', name: 'Development'},
  {value: '2076c4b6-7fe6-df11-971b-001d60e938c6', name: 'Sales'}
];

export const decisionRole: SelectInterface[] = [
  {value: 'ed46bb3a-57e6-df11-971b-001d60e938c6', name: 'Decision maker'},
  {value: 'd1ab0e4e-57e6-df11-971b-001d60e938c6', name: 'Contact person'},
  {value: 'd2ab0e4e-57e6-df11-971b-001d60e938c6', name: 'Influencer'},
  {value: '91e32c57-57e6-df11-971b-001d60e938c6', name: 'Performer'},
  {value: 'f71ee81d-0cac-4e41-b7dc-9d4bd2e08491', name: 'Manager'}
];

export const postGraduateEducationType: SelectInterface[] = [
  {value: '25e3df7d-30c3-42c5-a133-ecbb4ad56848', name: 'Конференция'},
  {value: 'acbc0296-3a43-4599-961f-c4d5709b38a7', name: 'Симпозиум'},
  {value: '566c83e2-80bf-4701-9833-4fadb4ad4a8f', name: 'Вебинар'},
  {value: 'f4c3936b-52f0-4499-9fa7-76f8bd7a30e7', name: 'Круглый стол'},
  {value: 'ae6b4346-ad2e-47f5-a144-b6a5187ebefb', name: 'Конгресс'}
];
