import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { UserService } from '../../shared/services/user.service';
import { AuthService } from '../../shared/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CustomValidators } from '../../shared/validators/custom.validators';
import { RegisterUserInterface } from '../../shared/interfaces/auth/register-user.interface';
import { RegisterResponseInterface } from '../../shared/interfaces/auth/register-response.interface';
import { UserIdAndSecretInterface } from '../../shared/interfaces/user/user-id-and-secret.interface';
import { CookieService } from 'ngx-cookie-service';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { ClinicService } from '../../shared/services/clinic.service';
import { ClinicFullInterface } from '../../shared/interfaces/clinic/clinic-full.interface';
import { RegisterClinicResponseInterface } from '../../shared/interfaces/clinic/register-clinic-response.interface';
import { formatPhone } from '../../shared/functions/functions';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  form: FormGroup;
  errorMessage: string = api.mainErrorMessage;
  preloaderVisibility: boolean = true;
  timeout: boolean = true;

  phoneMask: object = {
    mask: PhoneMask
  };

  showPassword: boolean = false;

  signUpSubscribe: Subscription;
  signUpClinicSubscribe: Subscription;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private customValidators: CustomValidators,
    private cookie: CookieService,
    private dialog: MatDialog,
    private clinicService: ClinicService,
  ) {}

  ngOnInit() {

    if (localStorage.getItem('user-key')) {
      this.router.navigate(['/account']);
      return;
    } else {
      this.preloaderVisibility = false;
    }

    this.form = new FormGroup({
      'emailPhone': new FormControl(null, [Validators.required, this.customValidators.email]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(5)]),
      'firstName': new FormControl(null, [Validators.required]),
      'surName': new FormControl(null, [Validators.required]),
      'city': new FormControl(null, [Validators.required]),
      'privacy': new FormControl(null, [Validators.requiredTrue]),
      'clinicName': new FormControl(null, [Validators.required]),
      'clinicPhone': new FormControl(null, [Validators.required]),
    });
  }

  onSubmit() {
    const value = this.form.value;
    this.timeout = true;

    const userData: RegisterUserInterface = {
      Email: value.emailPhone,
      Password: value.password,
      City: value.city,
      Firstname: value.firstName,
      Surname: value.surName,
      TypeId: api.typeUser
    };

    this.preloaderVisibility = true;

    this.signUpSubscribe = this.authService.signUp(userData)
      .subscribe((response: RegisterResponseInterface) => {

        !api.production ? console.log('RegistrationResult', response) : null;

        if (response.RegistrationResult.Success) {

          const userData: UserIdAndSecretInterface =
            response.RegistrationResult.UserData as UserIdAndSecretInterface;

          let date = new Date();
          const expires = new Date(new Date().setDate(date.getDate() + 7));

          localStorage.setItem('user-key', userData.SecretKey);
          localStorage.setItem('user-id', userData.UserId);
          localStorage.setItem('auth-expires', expires.toString());

          let clinicPhone = null;

          if(value.clinicPhone) {
            clinicPhone = formatPhone(value.clinicPhone);
          }

          const clinicRequest: ClinicFullInterface = {
            SourceID: api.sourceID,
            SourceSecretKey: api.sourceSecretKey,
            UserData: {
              UserId: userData.UserId,
              SecretKey: userData.SecretKey,
            },
            AccountData: {
              Phone: clinicPhone,
              AccountName: value.clinicName,
              City: value.city
            }
          };

          this.signUpClinicSubscribe = this.clinicService.registerClinic(clinicRequest)
            .subscribe((response: RegisterClinicResponseInterface) => {

              !api.production ? console.log('SimpleClinicRegistrationResult', response) : null;

              if(response.SimpleClinicRegistrationResult.Success) {
                this.timeout = false;
                this.preloaderVisibility = false;
                this.openDialog(
                  'Регистрация прошла успешно!',
                  'Вам на почту отправленна ссылка для подтверждения регистрации& <br> Пожалуйста, перейдите по ней, чтобы пользоваться всеми возможностями нашего сервиса. <br> Спасибо!'
                );
              } else {
                this.preloaderVisibility = false;
                this.openDialog(
                  'Неизвестная ошибка',
                  this.errorMessage,
                  true
                );
              }
            });

        } else {
          let error = api.mainErrorMessage;

          let userError: boolean = false;

          const warnings = response.RegistrationResult.Warnings;

          for(let key in warnings) {
            if(warnings[key].Code == 503) {
              userError = true;
              error = 'Пользователь с таким E-mail уже существует. <br> Пожалуйста укажите другой E-mail.';
            }
          }

          this.timeout = false;
          this.preloaderVisibility = false;

          if(userError) {
            this.openDialog(
              'Пользователь существует!',
              error,
              true,
              true
            );
          } else {
            this.openDialog(
              'Неизвестная ошибка!',
              error,
              true
            );
          }

        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        console.log(this.signUpClinicSubscribe, !this.signUpClinicSubscribe);

        if(this.signUpClinicSubscribe) {
          this.openDialog(
            'Ошибка регистрации клиники',
            this.errorMessage,
            true
          );
        } else {
          this.openDialog(
            'Неизвестная ошибка',
            this.errorMessage,
            true
          );
        }

        this.signUpSubscribe.unsubscribe();
        this.signUpClinicSubscribe ? this.signUpClinicSubscribe.unsubscribe() : null;
      }
    }, 30000);
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    restore: boolean = false,
  ) {
    const dialogRef = this.dialog.open(SignUpDialog, {
      data: {
        title: title,
        message: message,
        restore: restore,
        error: error
      }
    });

    if(error) {
      dialogRef.afterClosed().subscribe(() => {
        this.preloaderVisibility = false;
      });
    } else {
      dialogRef.afterClosed().subscribe(() => {
        this.router.navigate(['/account']);
      });
    }
  }
}

@Component({
  selector: 'sign-up-dialog',
  templateUrl: 'sign-up-dialog.html',
})
export class SignUpDialog {
  constructor(
    public dialogRef: MatDialogRef<SignUpDialog>,
    @Inject(MAT_DIALOG_DATA) public data,
    private router: Router
  ) {}

  goRecover(): void {
    this.dialogRef.close();
    this.router.navigate(['/auth/recover']);
  }
}
