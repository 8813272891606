<div class="content-wrapper">
  <div class="main-title">Ваши клиники</div>
  <div class="row card-list" *ngIf="!preloaderVisibility">
    <div class="col-xl-4 col-md-6"  *ngFor="let item of clinicsList; let i = index" [routerLink]="['/clinic', i]">
      <div class="card-clinic">
        <div class="image">
          <img [src]="item.Files[item.Files.length - 1].Link" alt="" *ngIf="item.Files.length">
          <img src="/assets/images/clinic-placeholder.png" alt="" *ngIf="!item.Files.length">
        </div>
        <div class="caption">
          <div class="clinic-name">{{item.AccountName}}</div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-6" [routerLink]="['/account', 'clinic-add']">
      <div class="card-add">
        <div class="centered">
          <i class="if if-add-round icon-gradient"></i>
          <div class="text">Добавить клинику</div>
        </div>
      </div>
    </div>
  </div>

  <div class="load-content-spinner" *ngIf="preloaderVisibility">
    <img src="assets/images/load-content-spinner.svg" alt="">
  </div>
</div>
