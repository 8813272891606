<div class="content-wrapper">
  <div class="main-title">Расписания приемов</div>

  <nav class="custom-tabs-nav">
    <ul>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'specialists', 'list']" routerLinkActive="active">
          Специалисты клиники
        </button>
      </li>
      <li>
        <button [routerLink]="['/clinic', clinicService.activeClinic, 'specialists', 'invited']" routerLinkActive="active">
          Заявки на добавление
        </button>
      </li>
    </ul>
  </nav>

  <div class="router-wrapper">
    <router-outlet></router-outlet>
  </div>

</div>
