<div class="main-container" *ngIf="!preloaderGlobal">

  <form class="form-group"
        [formGroup]="form">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <app-force-autocomplete
          [control]="form.controls.specialization"
          formControlName="specialization"
          label="Специализация"
          [selectOptions]="filterSpecializations"
          (optionSelected)="formChange()"
        ></app-force-autocomplete>
      </div>
      <div class="col-lg-4 col-md-6">
        <mat-form-field>
          <mat-label>Тип выезда <i class="danger">*</i></mat-label>
          <mat-select formControlName="typeAppointment"
                      (selectionChange)="changeTypeAppointment()">
            <mat-option *ngFor="let option of selectTypeAppointment"
                        [value]="option.value">
              {{option.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-4 col-md-6">
        <app-force-autocomplete
          [control]="form.controls.specialist"
          formControlName="specialist"
          label="Врач"
          [selectOptions]="selectSpecialist"
          (optionSelected)="formChange()"
        ></app-force-autocomplete>
      </div>
    </div>
  </form>

  <app-date-changer (changeDate)="getDates($event)" *ngIf="showDateChanger"></app-date-changer>

  <div *ngIf="noAppointments">
    <p>За выбраный период нету ни одной записи</p>
  </div>

  <app-appointment-grid
    *ngIf="showGrid && !preloader"
    [appointmentsGrid]="appointmentsGrid"
    [dateRange]="dateRange"
    [admin]="true">
  </app-appointment-grid>

  <app-preloader *ngIf="preloader" [static]="true"></app-preloader>
</div>
<app-preloader *ngIf="preloaderGlobal" [static]="false"></app-preloader>
