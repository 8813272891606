<div class="content-wrapper">
  <div class="main-title">
    {{clinicFullData.AccountData.AccountName}}
    <div class="title-controls">
      <button class="button-transparent" [routerLink]="['/clinic', clinicService.activeClinic, 'edit']"
              routerLinkActive="active">
        <i class="if if-edit-02"></i>
        Редактировать
      </button>
    </div>
  </div>
  <div class="main-container">
    <div class="account-avatar account-clinic">
      <div class="image" (click)="changePhoto()">
        <img [src]="avatar" alt="" *ngIf="avatar">
        <img src="assets/images/clinic-placeholder.png" alt="" *ngIf="!avatar">
      </div>
      <div class="d-flex justify-content-center">
        <button class="button-transparent btn-large" (click)="changePhoto()">
          <i class="if if-photo-camera"></i>
          Изменить
        </button>
      </div>
    </div>

    <hr>

    <div class="row main-info-block-wrapper">

      <div class="col-lg-4 col-md-6 main-info-block">
        <div class="title">Город</div>
        <div class="text" *ngIf="clinicFullData.AccountData.City">{{clinicFullData.AccountData.City}}</div>
        <div class="text" *ngIf="!clinicFullData.AccountData.City">Не указано</div>
      </div>

      <div class="col-lg-4 col-md-6 main-info-block">
        <div class="title">Адресс</div>
        <div class="text" *ngIf="clinicFullData.AccountData.Address">{{clinicFullData.AccountData.Address}}</div>
        <div class="text" *ngIf="!clinicFullData.AccountData.Address">Не указано</div>
      </div>

      <div class="col-lg-4 col-md-6 main-info-block">
        <div class="title">Телефон</div>
        <div class="text" *ngIf="clinicFullData.AccountData.Phone">{{clinicFullData.AccountData.Phone}}</div>
        <div class="text" *ngIf="!clinicFullData.AccountData.Phone">Не указано</div>
      </div>

      <div class="col-lg-4 col-md-6 main-info-block">
        <div class="title">Сайт</div>
        <div class="text" *ngIf="clinicFullData.AccountData.Site">
          <a href="{{clinicFullData.AccountData.Site}}">
            {{clinicFullData.AccountData.Site}}
          </a>
        </div>
        <div class="text" *ngIf="!clinicFullData.AccountData.Site">Не указано</div>
      </div>
    </div>

    <h2>Направления клиники</h2>

    <div class="row main-info-block-wrapper"
         *ngIf="clinicFullData.AccountDirections.length">
      <div class="col-lg-4 col-md-6 main-info-block" *ngFor="let item of clinicDirections">
        <div class="info-block-background">
          <div class="title">
            {{item.action | catalogName: clinicActionsCatalog}}
          </div>
          <div class="text-list">
            <div *ngFor="let item2 of item.directions">
              {{item2 | catalogName: clinicDirectionsCatalog}}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main-info-block"
         *ngIf="!clinicFullData.AccountDirections.length">
      <div class="text">Не указано</div>
    </div>

    <h2>Страховые компании</h2>

    <div class="row main-info-block-wrapper"
         *ngIf="clinicFullData.AccountInsuranceCom.length">

      <div class="col-lg-4 col-md-6 main-info-block" *ngFor="let item of clinicFullData.AccountInsuranceCom">
        <div class="text">
          {{item.CompanyName}}
        </div>
      </div>
    </div>

    <div class="main-info-block"
         *ngIf="!clinicFullData.AccountInsuranceCom.length">
      <div class="text">Не указано</div>
    </div>

    <h2>График работы клиники</h2>

    <div class="clinic-schedule-item" *ngFor="let item of clinicSchedule;">
      <div class="name">{{item.WeekDay | catalogName: listWeekDays}}</div>
      <div class="text-time" *ngIf="!item.Holiday && !item.Roundclock">
        {{item.StartTime | date:'HH:mm'}} - {{item.EndTime | date:'HH:mm'}}
      </div>
      <div class="text-time" *ngIf="item.Holiday">
        Выходной
      </div>
      <div class="text-time" *ngIf="item.Roundclock">
        Круглосуточно
      </div>
    </div>

    <h2>О клинике</h2>

    <div class="main-info-block">
      <div class="text large-text" *ngIf="clinicFullData.AccountData.Description">{{clinicFullData.AccountData.Description}}</div>
      <div class="text" *ngIf="!clinicFullData.AccountData.Description">Не указано</div>
    </div>

  </div>
</div>
