<button class="close-mobile" (click)="closeMobileSidebar()">
  <i class="if if-close"></i>
</button>
<nav class="sidebar-navigation">
  <ul>
    <li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-dashboard"></i>
        <span class="text">Панель управления</span>
      </button>
    </li>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        [routerLink]="['/clinic', clinicService.activeClinic, 'info']"
        [routerLinkActive]="'active'"
      >
        <i class="if if-clinic"></i>
        <span class="text">Информация о клинике</span>
      </button>
    </li>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        [routerLink]="['/clinic', clinicService.activeClinic, 'price']"
        [routerLinkActive]="'active'"
      >
        <i class="if if-price"></i>
        <span class="text">Прайс лист</span>
      </button>
    </li>
    <li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-list"></i>
        <span class="text">Список клиентов</span>
      </button>
    </li>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        [routerLink]="['/clinic', clinicService.activeClinic, 'specialists']"
        routerLinkActive="active"
      >
        <i class="if if-list"></i>
        <span class="text">Специалисты</span>
      </button>
    </li>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        [routerLink]="['/clinic', clinicService.activeClinic, 'schedule']"
        routerLinkActive="active"
      >
        <i class="if if-calendar"></i>
        <span class="text">Расписание приемов</span>
      </button>
    </li>
    <li>
      <button (click)="closeMobileSidebarCheck()">
        <i class="if if-support"></i>
        <span class="text">Сообщения</span>
      </button>
    </li>
    <li>
      <button
        (click)="closeMobileSidebarCheck()"
        [routerLink]="['/account', 'clinic-list']"
      >
        <i class="if if-return"></i>
        <span class="text">Назад</span>
      </button>
    </li>
  </ul>
</nav>
<div class="sidebar-socials">
  <div class="title">Мы в соц. сетях</div>
  <div class="socials-list">
    <a href="https://vk.com/appmarko" target="_blank"
      ><i class="if if-vkontakte"></i
    ></a>
  </div>
</div>
