<div class="header-under-sidebar">
  <div class="header-logo" routerLink="/">
    <img src="assets/images/logo.svg" alt="">
  </div>
  <div class="header-burger" (click)="toggleSidebar()">
    <i class="if if-burger"></i>
  </div>
</div>
<div class="header-bars">
  <!--<nav class="header-navigation" [class.show]="userService.showMobileNavigation">
    <button class="close-mobile" (click)="closeMobileNav()"><i class="if if-close"></i></button>
    <ul class="parent-navigation">
      <li>
        <a [class]="">Новости</a>
        <i class="if if-angle-down" appNavToggle></i>
        <ul class="sub-navigation">
          <li>
            <a>Кошки</a>
          </li>
          <li>
            <a>Собаки</a>
          </li>
          <li>
            <a>Хомячки</a>
          </li>
        </ul>
      </li>
      <li>
        <a>Статьи</a>
        <i class="if if-angle-down" appNavToggle></i>
        <ul class="sub-navigation">
          <li>
            <a>Кошки</a>
          </li>
          <li>
            <a>Собаки</a>
          </li>
          <li>
            <a>Хомячки</a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>-->
  <div class="header-controls" [class.show]="userService.showMobileAccountInfo">
    <div class="header-chat">
      <i class="if if-support icon-gradient"></i>
    </div>
    <div class="header-notify">
      <i class="if if-alarm icon-gradient"></i>
      <div class="notify"></div>
    </div>
    <div class="header-user">
      <div class="small-avatar" routerLink="/account/info">
        <img [src]="userService.userAvatar" alt="" *ngIf="userService.userAvatar">
        <img src="assets/images/user-man-placeholder.png" alt=""
             *ngIf="!userService.userAvatar && userData && userData.Gender == 'Male'">
        <img src="assets/images/user-wooman-placeholder.png" alt=""
             *ngIf="!userService.userAvatar && userData && userData.Gender == 'Female'">
        <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!userService.userAvatar && !userData">
        <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!userService.userAvatar && userData">
      </div>
      <div class="header-user-info">
        <div class="head">
          <div class="large-avatar">
            <img [src]="userService.userAvatar" alt="" *ngIf="userService.userAvatar">
            <img src="assets/images/user-man-placeholder.png" alt=""
                 *ngIf="!userService.userAvatar && userData && userData.Gender == 'Male'">
            <img src="assets/images/user-wooman-placeholder.png" alt=""
                 *ngIf="!userService.userAvatar && userData && userData.Gender == 'Female'">
            <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!userService.userAvatar && !userData">
            <img src="assets/images/user-man-placeholder.png" alt="" *ngIf="!userService.userAvatar && userData">
          </div>
          <div class="info" *ngIf="userData">
            <div class="user-name"
                 *ngIf="userData.Firstname && userData.Surname">
              {{userData.Firstname}} {{userData.Surname}}
            </div>
            <div class="user-email"
                 *ngIf="userData.Email">
              {{userData.Email}}
            </div>
          </div>
        </div>
        <nav class="header-user-navigation">
          <ul>
            <li>
              <button routerLink="/account/info">
                <i class="if if-user-man icon-gradient" *ngIf="!userData"></i>
                <i class="if if-user-man icon-gradient" *ngIf="userData && userData.Gender == 'Male'"></i>
                <i class="if if-user-woman icon-gradient" *ngIf="userData && userData.Gender == 'Female'"></i>
                <span class="text">Мой профиль</span>
              </button>
            <li>
              <button class="disabled-link">
                <i class="if if-settings icon-gradient"></i>
                <span class="text">Настройки</span>
              </button>
            <li>
              <button (click)="onLogout()">
                <i class="if if-exit icon-gradient"></i>
                <span class="text">Выход</span>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <div class="header-controls-mobile">
    <button (click)="showMobileSidebar()">
      <i class="if if-burger icon-gradient"></i>
    </button>
    <button (click)="showMobileNav()">
      <i class="if if-burger-02 icon-gradient"></i>
    </button>
    <button (click)="toggleMobileAccountInfo()">
      <i class="if if-more-button-dots icon-gradient"></i>
    </button>
  </div>
</div>
<div class="header-overlay-mobile"
     [class.show]="userService.showMobileOverlay"
     (click)="hideAll()">
</div>
