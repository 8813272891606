import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppointmentsMoveQueryParamsInterface } from '../../../interfaces/appointments/appointments-move-query-params.interface';
import { SelectInterface } from '../../../interfaces/select.interface';
import { AppointmentAddUserDataInterface } from '../../../interfaces/appointments/appointment-add-user-data.interface';
import { AppointmentAddDataInterface } from '../../../interfaces/appointments/appointment-add-data.interface';
import { timeout } from 'rxjs/operators';
import { api } from '../../../../../environments/api';
import { AppointmentAddResponseInterface } from '../../../interfaces/appointments/appointment-add-response.interface';
import { AppointmentsTimeGridInterface } from '../../../interfaces/appointments/appointments-time-grid.interface';
import { ClinicService } from '../../../services/clinic.service';
import { DialogService } from '../../../services/dialog.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { typeAppointment } from '../../../catalogs/clinic.catalogs';

@Component({
  selector: 'app-create-appointment-move',
  templateUrl: './create-appointment-move.component.html',
  styleUrls: ['./create-appointment-move.component.scss']
})
export class CreateAppointmentMoveComponent implements OnInit, OnDestroy {

  @Input() appointment: AppointmentsTimeGridInterface;
  @Input() appointmentQuery: AppointmentsMoveQueryParamsInterface;
  @Input() services: SelectInterface[] = [];

  @Output() preloader = new EventEmitter<boolean>();
  @Output() setSuccess = new EventEmitter<boolean>();

  hideAll = false;
  needAddress = false;

  constructor(
    private clinicService: ClinicService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    if(this.appointment.PlaceAppointmentId == typeAppointment[1].value) this.needAddress = true;
  }

  submit() {
    this.preloader.emit(true);
    this.hideAll = true;
    const clinicData = this.clinicService.getClinicFullData();

    const userData: AppointmentAddUserDataInterface = {
      UserId: this.appointmentQuery.UserId,
      PetId: this.appointmentQuery.PetId,
      SpecialistId: this.appointment.SpecialistId,
      AccountId: this.appointment.AccountId
    };

    const appointmentData: AppointmentAddDataInterface = {
      StartDate: this.appointment.StartDate,
      DueDate: this.appointment.DueDate,
      Compliant: this.appointmentQuery.Compliant,
      ServiceId: this.appointmentQuery.ServiceId,
      Name: this.appointmentQuery.Name,
      Phone: this.appointmentQuery.Phone,
      Email: this.appointmentQuery.Email,
      PetName: this.appointmentQuery.PetName,
      Price: this.appointmentQuery.Price,
      TypeId: this.appointmentQuery.TypeId,
      PlaceAppointmentId: this.appointment.PlaceAppointmentId,
      Address: this.appointmentQuery.Address
    };

    this.clinicService.createAppointment(userData, appointmentData)
      .pipe(
        timeout(api.requestTimeout),
        untilDestroyed(this)
      ).subscribe((response: AppointmentAddResponseInterface) => {

        !api.production ? console.log('AppointmentAddResponseInterface', response) : null;

        this.preloader.emit(false);
        this.hideAll = false;

        if(response.CreateAppointmentResult.Success) {
          this.dialogService.openStatusDialog(
            'Запись на прием прошла успешно!',
            'Пользователь был успешно записан на прием в клинику.'
          );
          this.setSuccess.emit();
        } else {
          this.dialogService.showStandardError();
        }

      }, () => {
        this.preloader.emit(false);
        this.hideAll = false;
        this.dialogService.showStandardError();
      });
  }

  ngOnDestroy() {
  }

}
