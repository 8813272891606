<mat-dialog-content class="mat-typography">
  <div class="dialog-title">{{date | date:'dd MMMM yyyy'}}</div>

  <div class="time-grid-list">
    <button class="time-grid-item"
            *ngFor="let item of cells; let i = index"
            [disabled]="!item.IsEnable"
            (click)="showCreateAppointment(item, i)">
      {{item.TimeString}}
    </button>
  </div>

</mat-dialog-content>
<mat-dialog-actions align="center" class="dialog-buttons">
  <button type="button"
          class="btn btn-default btn-small"
          (click)="closeDialog()">
    Отмена
  </button>
</mat-dialog-actions>
