import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ClinicRoutingModule } from './clinic-routing.module';
import { ReactiveFormsModule } from '@angular/forms';

import { FormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { SharedModule } from '../shared/shared.module';
import { ngfModule } from 'angular-file';
import { ImageCropperModule } from '../shared/plugins/cropper';
import { ClinicComponent, ClinicDialog } from './clinic.component';
import { ClinicInfoComponent } from './clinic-info/clinic-info.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { ClinicEditComponent } from './clinic-edit/clinic-edit.component';
import {
  DateAdapter, MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatAutocompleteModule, MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule, MatIconModule,
  MatInputModule, MatNativeDateModule,
  MatSelectModule, MatTabsModule
} from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { ClinicChangePhotoComponent } from './clinic-change-photo/clinic-change-photo.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SpecialistsModule } from './specialists/specialists.module';
import { ScheduleModule } from './schedule/schedule.module';
import { ClinicPriceComponent } from './clinic-price/clinic-price.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'L',
  },
  display: {
    dateInput: 'L',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'L',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    ClinicRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TextMaskModule,
    SharedModule,
    ngfModule,
    ImageCropperModule,
    PipesModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    MatIconModule,
    SpecialistsModule,
    ScheduleModule
  ],
  declarations: [
    ClinicDialog,
    ClinicComponent,
    ClinicInfoComponent,
    ClinicEditComponent,
    ClinicChangePhotoComponent,
    ClinicPriceComponent
  ],
  entryComponents: [
    ClinicDialog,
    ClinicChangePhotoComponent
  ],
  providers: [
    DatePipe,
    MatDatepickerModule,
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ]
})
export class ClinicModule { }
