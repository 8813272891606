import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { clinicActions, clinicDirections, clinicMetro } from '../../shared/catalogs/clinic.catalogs';
import { api } from '../../../environments/api';
import { ClinicFullInterface } from '../../shared/interfaces/clinic/clinic-full.interface';
import { ClinicFormInterface } from '../../shared/interfaces/clinic/clinic-form.interface';
import { ClinicServiceInterface } from '../../shared/interfaces/clinic/clinic-service.interface';
import { BaseService } from '../../shared/services/base.service';
import { ClinicService } from '../../shared/services/clinic.service';
import { ClinicInsuranceComInterface } from '../../shared/interfaces/clinic/clinic-Insurance-com.interface';
import { ClinicDirectionsInterface } from '../../shared/interfaces/clinic/clinic-directions.interface';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { RegisterClinicResponseInterface } from '../../shared/interfaces/clinic/register-clinic-response.interface';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { DialogInterface } from '../../shared/interfaces/dialog.interface';
import { AccountDialog } from '../account.component';
import { MatDialog } from '@angular/material';
import { weekDays } from '../../shared/catalogs/main.catalog';
import { dateCompilationNoUtc, formatPhone } from '../../shared/functions/functions';
import { WorkTimeInterface } from '../../shared/interfaces/clinic/work-time-interface';
import { timepickerTheme } from '../../shared/const/const';

@Component({
  selector: 'app-clinic-add',
  templateUrl: './clinic-add.component.html',
  styleUrls: ['./clinic-add.component.scss']
})
export class ClinicAddComponent implements OnInit {

  preloaderVisibility: boolean = false;
  form: FormGroup;

  cityFias: string;
  showMetro: boolean = false;
  // moscowFias = api.dadataFiases.moscow;

  errorTitle: string = api.mainErrorTitle;
  errorMessage: string = api.mainErrorMessage;
  timeout = true;

  selectMetro: SelectInterface[] = clinicMetro;
  selectClinicDirections: SelectInterface[] = clinicDirections;
  selectClinicActions: SelectInterface[] = clinicActions;
  listWeekDays: SelectInterface[] = weekDays;

  clinicDirections: FormArray;
  clinicInsuranceCompany: FormArray;
  clinicSchedule: FormArray;

  clinicRegisterSubscribe: Subscription;

  timePickerTheme = timepickerTheme;

  phoneMask: object = {
    mask: PhoneMask
  };

  constructor(
    private baseService: BaseService,
    private clinicService: ClinicService,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.formInit();
  }

  /*
  * Initialization form
  * */

  formInit() {
    this.form = new FormGroup({
      'name': new FormControl(null, [Validators.required]),
      'city': new FormControl(null, [Validators.required]),
      'metro': new FormControl(null),
      'address': new FormControl(null),
      'phone': new FormControl(null, [Validators.required]),
      'site': new FormControl(null),
      'description': new FormControl(null),
      'clinicDirections': new FormArray([]),
      'insuranceCompanies': new FormArray([]),
      'clinicSchedule': new FormArray([])
    });

    this.clinicDirections = this.form.get('clinicDirections') as FormArray;
    this.clinicInsuranceCompany = this.form.get('insuranceCompanies') as FormArray;
    this.clinicSchedule = this.form.get('clinicSchedule') as FormArray;

    for (let key in weekDays) {
      this.clinicSchedule.push(
        new FormGroup({
          'weekDay': new FormControl(
            weekDays[key].value
          ),
          'startTime': new FormControl(null),
          'endTime': new FormControl(null),
          'holiday': new FormControl(null),
          'roundClock': new FormControl(null)
        })
      );
    }
  }

  /*
  * Set city fias and check moscow for metro
  * */

  setCityFias(response) {
    this.cityFias = response;
    this.form.get('address').setValue('');

    /*if (response[0]) {
      this.cityFias = response[0].data.city_fias_id;

      this.showMetro = this.moscowFias == response[0].data.city_fias_id;
    } else {
      this.cityFias = response.data.city_fias_id;
      this.showMetro = this.moscowFias == response.data.city_fias_id;
    }*/
  }


  /*
  * Add clinic Direction item
  * */

  /*addClinicDirections(): void {
    let actionControls = {};

    this.selectClinicActions.forEach((item, i) => {
      actionControls['action' + i] = new FormControl();
    });

    this.clinicDirections.push(
      new FormGroup({
        'id': new FormControl(),
        'clinicDirection': new FormControl(this.selectClinicDirections[0].value, [Validators.required]),
        'clinicActions': new FormGroup(actionControls)
      })
    );
  }*/

  addClinicDirections(): void {
    let directionControls = {};

    /*this.selectClinicDirections.forEach((item, i) => {
      directionControls['direction' + i] = new FormControl();
    });*/

    this.clinicDirections.push(
      new FormGroup({
        'id': new FormControl(),
        'clinicAction': new FormControl(this.selectClinicActions[0].value, [Validators.required]),
        'clinicDirections': new FormControl('',[Validators.required])
      })
    );
  }

  /*
  * Add clinic Insurance Company item
  * */

  addClinicInsuranceCompany(): void {
    this.clinicInsuranceCompany.push(
      new FormGroup({
        'id': new FormControl(),
        'companyName': new FormControl(null, [Validators.required])
      })
    );
  }


  /*
  * Remove clinic Direction item
  * */

  removeClinicDirections(i): void {
    this.clinicDirections.removeAt(i);
  }

  /*
  * Remove clinic Insurance company item
  * */

  removeClinicInsuranceCompany(i): void {
    this.clinicInsuranceCompany.removeAt(i);
  }

  /*
  * Submit form
  * */

  onSubmit() {
    const value = this.form.value as ClinicFormInterface;
    this.preloaderVisibility = true;
    this.timeout = true;

    console.log('clinic form', this.form);

    /*
    * Create clinic register request
    * */

    let phone = null;

    if(value.phone) {
      phone = formatPhone(value.phone);
    }

    let request: ClinicFullInterface = {
        SourceID: api.sourceID,
        SourceSecretKey: api.sourceSecretKey,
        AccountData: {
          AccountName: value.name,
          Phone: phone,
          City: value.city,
          Site: value.site,
          Description: value.description,
          Address: value.address
        }
      }
    ;

    /*
    * Add clinic insurance companies into request
    * */

    let requestInsuranceCompanies: ClinicInsuranceComInterface[] = [];

    for (let key in value.insuranceCompanies) {
      if (value.insuranceCompanies[key]) {
        let data: ClinicInsuranceComInterface = {
          CompanyName: value.insuranceCompanies[key].companyName,
        };
        requestInsuranceCompanies.push(this.baseService.clearEmpty(data));
      }
    }

    /*
    * Add clinic Directions into request
    * */

    let requestClinicDirections: ClinicDirectionsInterface[] = [];

    /*for (let key in value.clinicDirections) {
      if (value.clinicDirections[key]) {
        let i = 0;
        for (let key2 in value.clinicDirections[key].clinicActions) {
          if (value.clinicDirections[key].clinicActions[key2]) {
            let data: ClinicDirectionsInterface = {
              DirectionType: value.clinicDirections[key].clinicDirection,
              Action: this.selectClinicActions[i].value as string
            };
            requestClinicDirections.push(this.baseService.clearEmpty(data));
          }

          i++;
        }
      }
    }*/

    for (let key in value.clinicDirections) {
      if (value.clinicDirections[key]) {
        let i = 0;
        for (let key2 in value.clinicDirections[key].clinicDirections) {
          if (value.clinicDirections[key].clinicDirections[key2]) {
            let data: ClinicDirectionsInterface = {
              Action: value.clinicDirections[key].clinicAction,
              DirectionType: this.selectClinicDirections[i].value as string
            };
            requestClinicDirections.push(this.baseService.clearEmpty(data));
          }

          i++;
        }
      }
    }

    /*
    * Add clinic schedule into request
    * */

    let requestClinicSchedule: WorkTimeInterface[] = [];

    for (let key in value.clinicSchedule) {
      if (value.clinicSchedule[key]) {

        let startTimeVal = value.clinicSchedule[key].startTime;
        let endTimeVal = value.clinicSchedule[key].endTime;
        let startTime;
        let endTime;

        if(startTimeVal) {
          let date01 = new Date;
          let startTimeValSplit = value.clinicSchedule[key].startTime.split(':');

          date01.setHours(
            parseInt(startTimeValSplit[0]),
            parseInt(startTimeValSplit[1]),
            0);

          startTime = dateCompilationNoUtc(date01);
        }

        if(endTimeVal) {
          let date02 = new Date;
          let endTimeValSplit = value.clinicSchedule[key].endTime.split(':');

          date02.setHours(
            parseInt(endTimeValSplit[0]),
            parseInt(endTimeValSplit[1]),
            0);

          endTime = dateCompilationNoUtc(date02);
        }

        let data: WorkTimeInterface = {
          WeekDay: value.clinicSchedule[key].weekDay,
          StartTime: startTime,
          EndTime: endTime,
          Holiday: value.clinicSchedule[key].holiday,
          Roundclock: value.clinicSchedule[key].roundClock
        };
        requestClinicSchedule.push(this.baseService.clearEmpty(data));
      }
    }

    /*
    * Add clinic Metro into request
    * */

    if(value.metro) {
      request.AccountMetro = [];
      request.AccountMetro.push({
        Metro: value.metro
      });
    }

    requestClinicDirections.length
      ? request.AccountDirections = requestClinicDirections
      : null;

    requestInsuranceCompanies.length
      ? request.AccountInsuranceCom = requestInsuranceCompanies
      : null;

    requestClinicSchedule.length
      ? request.WorkTime = requestClinicSchedule
      : null;

    console.log(request);

    this.clinicRegisterSubscribe = this.clinicService.registerClinic(request)
      .subscribe((response: RegisterClinicResponseInterface) => {
        console.log('SimpleClinicRegistrationResult', response);
        this.timeout = false;

        if (response.SimpleClinicRegistrationResult.Success) {
          this.preloaderVisibility = false;
          this.openDialog(
            'Клиника успешно добавлена!',
            'Вы успешно добавили новую клинику.'
          );
        } else {
          this.preloaderVisibility = false;
          this.openDialog(
            this.errorTitle,
            this.errorMessage,
            true
          );
        }
      });

    setTimeout(() => {
      if (this.timeout) {
        this.preloaderVisibility = false;
        this.openDialog(
          this.errorTitle,
          this.errorMessage,
          true
        );
        this.clinicRegisterSubscribe.unsubscribe();
      }
    }, 30000);

  }

  openDialog(
    title: string,
    message: string,
    error: boolean = false,
    back: string = null
  ) {

    const data: DialogInterface = {
      title: title,
      message: message,
      error: error,
      back: back
    };

    const dialogRef = this.dialog.open(AccountDialog, {
      data: data
    });

    dialogRef.afterClosed().subscribe(() => {
      this.preloaderVisibility = false;
    });
  }

}
