<div class="auth-background-image">
  <img src="assets/images/auth-image-02.jpg" alt="">
</div>

<div class="auth-centered" *ngIf="!preloaderVisibility">
  <form
    class="auth-form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()">

    <div class="auth-form-title">Регистрация</div>

    <label [class.auth-error]="form.get('emailPhone').invalid && form.get('emailPhone').touched">
      <input
        type="email"
        placeholder="Email"
        id="email-phone"
        formControlName="emailPhone">
      <i class="if if-light-envelope"></i>

      <span class="auth-error-message"
            *ngIf="form.get('emailPhone').invalid && form.get('emailPhone').touched">

        <span *ngIf="form.get('emailPhone')['errors']['required']">
          Поле не может быть пустым
        </span>
        <span *ngIf="form.get('emailPhone')['errors']['email']">
          Не верно указан E-mail
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('password').invalid && form.get('password').touched">
      <input
        [type]="showPassword ? 'text' : 'password'"
        placeholder="Пароль"
        id="password"
        class="input-password"
        formControlName="password">
      <i class="if if-light-locked"></i>
      <span class="show-password" (click)="toggleShowPassword()">
         <i class="if if-eye"></i>
         <i class="if if-no-eye"></i>
      </span>

      <span class="auth-error-message"
            *ngIf="form.get('password').invalid && form.get('password').touched">

        <span *ngIf="form.get('password')['errors']['required']">
          Поле не может быть пустым
        </span>
        <span *ngIf="form.get('password')['errors']['minlength']">
          Длина пароля должна быть минимум 5 символов
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('firstName').invalid && form.get('firstName').touched">
      <dadata
        [type]="'fio'"
        [parts]="['NAME']"
        limit="3"
        id="first-name"
        formControlName="firstName"
        [placeholder]="'Имя'">
      </dadata>
      <i class="if if-light-user"></i>

      <span class="auth-error-message"
            *ngIf="form.get('firstName').invalid && form.get('firstName').touched">

        <span *ngIf="form.get('firstName')['errors']['required']">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('surName').invalid && form.get('surName').touched">
      <dadata
        [type]="'fio'"
        [parts]="['SURNAME']"
        limit="3"
        id="sur-name"
        formControlName="surName"
        [placeholder]="'Фамилия'">
      </dadata>
      <i class="if if-light-user"></i>

      <span class="auth-error-message"
            *ngIf="form.get('surName').invalid && form.get('surName').touched">

        <span *ngIf="form.get('surName')['errors']['required']">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('clinicName').invalid && form.get('clinicName').touched">
      <input type="text"
             placeholder="Название клиники"
             id="clinic-name"
             formControlName="clinicName"
             [class.is-invalid]="form.get('clinicName').invalid && form.get('clinicName').touched">
      <i class="if if-clinic"></i>

      <span class="auth-error-message"
            *ngIf="form.get('clinicName').invalid && form.get('clinicName').touched">

        <span *ngIf="form.get('clinicName').hasError('required')">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('clinicPhone').invalid && form.get('clinicPhone').touched">
      <input type="text"
             id="clinic-phone"
             [textMask]="phoneMask"
             placeholder="Телефон клиники"
             formControlName="clinicPhone"
             [class.is-invalid]="form.get('clinicPhone').invalid && form.get('clinicPhone').touched">
      <i class="if if-light-phone"></i>

      <span class="auth-error-message"
            *ngIf="form.get('clinicPhone').invalid && form.get('clinicPhone').touched">

        <span *ngIf="form.get('clinicPhone').hasError('required')">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('city').invalid && form.get('city').touched">
      <dadata
        [type]="'address'"
        fromBound="city"
        limit="3"
        id="city"
        formControlName="city"
        [placeholder]="'Город'">
      </dadata>
      <i class="if if-light-point"></i>

      <span class="auth-error-message"
            *ngIf="form.get('city').invalid && form.get('city').touched">

        <span *ngIf="form.get('city')['errors']['required']">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label class="custom-auth-checkbox">
      <input type="checkbox"
             formControlName="privacy">

      <span class="auth-checkbox">
         <i class="if if-check"></i>
      </span>
      <span class="label">
         Я принимаю условия <br>
         <a routerLink="/agreement">пользовательского соглашения</a>
      </span>
    </label>

    <button type="submit"
            [disabled]="form.invalid"
            class="auth-button">
      Регистрация
    </button>
  </form>
  <div class="auth-links already-have">
    <div class="title">Уже есть аккаунт?</div>
    <a routerLink="/auth/sign-in">Войти</a>
  </div>
</div>

<div class="load-spinner" *ngIf="preloaderVisibility">
  <img src="assets/images/load-spinner.svg" alt="">
</div>
