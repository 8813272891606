/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "./status-dialog.component";
var styles_StatusDialogComponent = [i0.styles];
var RenderType_StatusDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusDialogComponent, data: {} });
export { RenderType_StatusDialogComponent as RenderType_StatusDialogComponent };
export function View_StatusDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dialog-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "if icon-gradient"]], [[2, "if-angry-dog", null], [2, "if-happy-cat", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u043A "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.error; var currVal_1 = !_co.data.error; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.data.title; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.data.message; _ck(_v, 6, 0, currVal_3); }); }
export function View_StatusDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-status-dialog", [], null, null, null, View_StatusDialogComponent_0, RenderType_StatusDialogComponent)), i1.ɵdid(1, 49152, null, 0, i3.StatusDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], null, null); }
var StatusDialogComponentNgFactory = i1.ɵccf("app-status-dialog", i3.StatusDialogComponent, View_StatusDialogComponent_Host_0, {}, {}, []);
export { StatusDialogComponentNgFactory as StatusDialogComponentNgFactory };
