import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { api } from '../../../../../environments/api';
import { AppointmentsAdminTimeGridInterface } from '../../../../shared/interfaces/appointments/appointments-admin-time-grid.interface';
import { AppointmentEditDialogComponent } from '../appointment-edit-dialog/appointment-edit-dialog.component';

@Component({
  selector: 'app-appointment-admin-timegrid-dialog',
  templateUrl: './appointment-admin-timegrid-dialog.component.html',
  styleUrls: ['./appointment-admin-timegrid-dialog.component.scss']
})
export class AppointmentAdminTimegridDialogComponent implements OnInit {

  cells: AppointmentsAdminTimeGridInterface[] = this.dialogData.item;
  date = this.dialogData.date;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AppointmentAdminTimegridDialogComponent>
  ) { }

  ngOnInit() {

    !api.production ? console.log('time-cells', this.cells) : null;

    this.dialogRef.backdropClick().subscribe(() => {
      this.dialogRef.close({
        key: this.dialogData.key,
        date: this.dialogData.date
      });
    });
  }

  showCreateAppointment(item, index) {
    const dialogRef = this.dialog.open(AppointmentEditDialogComponent, {
      data: {
        item: item,
        index: index
      },
      panelClass: 'record-clinic-dialog',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((response) => {

      !api.production ? console.log('create-dialog-result', response) : null;

      let type = 'none';

      if(response && response.type) type = response.type;

      switch (type) {
        case 'block':
          this.cells[index].IsBlocked = true;
          this.cells[index].IsEnable = false;
          this.cells[index].BlockId = response.blockId;
          break;
        case 'unblock':
          this.cells[index].IsBlocked = false;
          this.cells[index].IsEnable = true;
          this.cells[index].BlockId = null;
          break;
        case 'removeAppointment':
          this.cells[index].IsBlocked = false;
          this.cells[index].IsEnable = true;
          this.cells[index].BlockId = null;
          this.cells[index].SchedulerAccountClients = null;
          break;
        case 'removeAppointmentBlock':
          this.cells[index].IsBlocked = true;
          this.cells[index].IsEnable = false;
          this.cells[index].BlockId = response.blockId;
          this.cells[index].SchedulerAccountClients = null;
          break;
        default:
          break;
      }
    });
  }

  closeDialog() {
    this.dialogRef.close({
      key: this.dialogData.key,
      date: this.dialogData.date
    });
  }

}
