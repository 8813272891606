/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./clinic.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../shared/components/header/header.component.ngfactory";
import * as i4 from "../shared/components/header/header.component";
import * as i5 from "../shared/services/auth.service";
import * as i6 from "../shared/services/user.service";
import * as i7 from "../shared/components/sidebar-clinic/sidebar-clinic.component.ngfactory";
import * as i8 from "../shared/components/sidebar-clinic/sidebar-clinic.component";
import * as i9 from "../shared/services/clinic.service";
import * as i10 from "@angular/common";
import * as i11 from "./clinic.component";
import * as i12 from "@angular/material/dialog";
var styles_ClinicComponent = [i0.styles];
var RenderType_ClinicComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ClinicComponent, data: {} });
export { RenderType_ClinicComponent as RenderType_ClinicComponent };
function View_ClinicComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "content-background"]], [[2, "sidebar-collapsed", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(2, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userService.showSidebar; _ck(_v, 0, 0, currVal_0); }); }
function View_ClinicComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "load-content-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", ""], ["src", "assets/images/load-content-spinner.svg"]], null, null, null, null, null))], null, null); }
export function View_ClinicComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [["class", "app-header"]], [[2, "sidebar-collapsed", null]], null, null, i3.View_HeaderComponent_0, i3.RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i4.HeaderComponent, [i5.AuthService, i6.UserService], { userData: [0, "userData"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-sidebar-clinic", [["class", "app-sidebar"]], [[2, "sidebar-collapsed", null], [2, "show", null]], null, null, i7.View_SidebarClinicComponent_0, i7.RenderType_SidebarClinicComponent)), i1.ɵdid(3, 114688, null, 0, i8.SidebarClinicComponent, [i6.UserService, i9.ClinicService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicComponent_1)), i1.ɵdid(5, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ClinicComponent_2)), i1.ɵdid(7, 16384, null, 0, i10.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.userData; _ck(_v, 1, 0, currVal_1); _ck(_v, 3, 0); var currVal_4 = !_co.preloaderVisibility; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.preloaderVisibility; _ck(_v, 7, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.userService.showSidebar; _ck(_v, 0, 0, currVal_0); var currVal_2 = !_co.userService.showSidebar; var currVal_3 = _co.userService.showMobileSidebar; _ck(_v, 2, 0, currVal_2, currVal_3); }); }
export function View_ClinicComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-clinic", [], null, null, null, View_ClinicComponent_0, RenderType_ClinicComponent)), i1.ɵdid(1, 114688, null, 0, i11.ClinicComponent, [i5.AuthService, i9.ClinicService, i2.ActivatedRoute, i2.Router, i12.MatDialog, i6.UserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ClinicComponentNgFactory = i1.ɵccf("app-clinic", i11.ClinicComponent, View_ClinicComponent_Host_0, {}, {}, []);
export { ClinicComponentNgFactory as ClinicComponentNgFactory };
var styles_ClinicDialog = [];
var RenderType_ClinicDialog = i1.ɵcrt({ encapsulation: 2, styles: styles_ClinicDialog, data: {} });
export { RenderType_ClinicDialog as RenderType_ClinicDialog };
export function View_ClinicDialog_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "dialog-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "if icon-gradient"]], [[2, "if-angry-dog", null], [2, "if-happy-cat", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "dialog-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(5, 16384, null, 0, i12.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 3, "mat-dialog-actions", [["align", "center"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i12.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" \u041E\u043A "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.error; var currVal_1 = !_co.data.error; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.data.title; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.data.message; _ck(_v, 6, 0, currVal_3); }); }
export function View_ClinicDialog_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "clinic-dialog", [], null, null, null, View_ClinicDialog_0, RenderType_ClinicDialog)), i1.ɵdid(1, 49152, null, 0, i11.ClinicDialog, [i12.MatDialogRef, i12.MAT_DIALOG_DATA], null, null)], null, null); }
var ClinicDialogNgFactory = i1.ɵccf("clinic-dialog", i11.ClinicDialog, View_ClinicDialog_Host_0, {}, {}, []);
export { ClinicDialogNgFactory as ClinicDialogNgFactory };
