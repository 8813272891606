<div class="content-wrapper">
  <div class="main-title">
    Редактирование профиля
  </div>

  <app-account-sidebar class="account-sidebar"></app-account-sidebar>

  <div class="main-container" *ngIf="!preloaderVisibility">
    <form class="form-group"
          [formGroup]="form"
          (ngSubmit)="onSubmit()">

      <dadata-mat [type]="'fio'"
                  [parts]="['NAME']"
                  limit="5"
                  id="first-name"
                  placeholder="Имя"
                  [required]="true"
                  [control]="form.controls.firstName"
                  formControlName="firstName">
      </dadata-mat>

      <dadata-mat [type]="'fio'"
                  [parts]="['SURNAME']"
                  limit="5"
                  id="sur-name"
                  placeholder="Фамилия"
                  [required]="true"
                  [control]="form.controls.surName"
                  formControlName="surName">
      </dadata-mat>

      <mat-form-field>
        <mat-label>Пол <i class="danger">*</i></mat-label>
        <mat-select
          id="gender"
          formControlName="gender">
          <mat-option *ngFor="let option of genderSelect"
                      [value]="option.value">
            {{option.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Дата рождения</mat-label>
        <input
          matInput
          id="birthday"
          formControlName="birthday"
          [matDatepicker]="myDatepicker"
          [max]="maxDate"
          (focus)="myDatepicker.open()">

        <mat-datepicker-toggle matSuffix [for]="myDatepicker">
          <i class="if if-calendar"></i>
        </mat-datepicker-toggle>
        <mat-datepicker #myDatepicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field>
        <mat-label>E-mail <i class="danger">*</i></mat-label>
        <input
          type="email"
          matInput
          id="email"
          formControlName="email">
        <mat-error *ngIf="form.get('email').hasError('required')">
          Поле не может быть пустым
        </mat-error>
        <mat-error *ngIf="form.get('email').hasError('email') && !form.get('email').hasError('required')">
          Не верно указан E-mail
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Телефон</mat-label>
        <input
          type="tel"
          matInput
          id="phone"
          [textMask]="phoneMask"
          formControlName="phone">
      </mat-form-field>

      <dadata-mat [type]="'address'"
                  fromBound="city"
                  limit="5"
                  id="city"
                  placeholder="Город"
                  [required]="true"
                  [cityFiasCheck]="true"
                  [control]="form.controls.city"
                  (selected)="setCityFias($event)"
                  formControlName="city">
      </dadata-mat>

      <dadata-mat [type]="'address'"
                  fromBound="street"
                  toBound="house"
                  limit="5"
                  id="address"
                  placeholder="Адрес"
                  [locations]="[{'city_fias_id': cityFias}]"
                  [restrict]="true"
                  [control]="form.controls.address"
                  formControlName="address">
      </dadata-mat>

      <mat-form-field>
        <mat-label>О себе</mat-label>
        <textarea
          matInput
          id="about-user"
          mat-autosize
          formControlName="aboutUser">
          {{userCurrentData.AboutSpecialist}}
        </textarea>
      </mat-form-field>

      <div class="last-buttons">
        <button type="button"
                class="btn btn-default"
                routerLink="/account">
          Отмена
        </button>
        <button type="submit"
                class="btn btn-primary"
                [disabled]="form.invalid">
          Сохранить
        </button>
      </div>

    </form>
  </div>

  <div class="load-content-spinner" *ngIf="preloaderVisibility">
    <img src="assets/images/load-content-spinner.svg" alt="">
  </div>
</div>
