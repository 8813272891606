/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./appointment-grid.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./appointment-grid.component";
import * as i4 from "@angular/material/dialog";
var styles_AppointmentGridComponent = [i0.styles];
var RenderType_AppointmentGridComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppointmentGridComponent, data: {} });
export { RenderType_AppointmentGridComponent as RenderType_AppointmentGridComponent };
function View_AppointmentGridComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "])), i1.ɵppd(2, 2), i1.ɵppd(3, 1), (_l()(), i1.ɵeld(4, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 2), i1.ɵppd(7, 1)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit, "EE")))); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit, "d MMM")))); _ck(_v, 5, 0, currVal_1); }); }
function View_AppointmentGridComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appointmentsGrid[_v.parent.parent.parent.context.$implicit].WorkDates[_v.parent.parent.context.$implicit].Length; _ck(_v, 1, 0, currVal_0); }); }
function View_AppointmentGridComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "if if-close"]], null, null, null, null, null))], null, null); }
function View_AppointmentGridComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "record-item"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showTimeGrid(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit].Appointments, _co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit].Date, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_6)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 0, 0, currVal_0); }); }
function View_AppointmentGridComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appointmentsGrid[_v.parent.parent.parent.context.$implicit].WorkDates[_v.parent.parent.context.$implicit].Length; _ck(_v, 1, 0, currVal_0); }); }
function View_AppointmentGridComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "if if-close"]], null, null, null, null, null))], null, null); }
function View_AppointmentGridComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "record-item"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAdminTimeGrid(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit].Appointments, _co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit].Date, _v.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_9)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.objectKeys(_co.appointmentsGrid[_v.parent.parent.context.$implicit].WorkDates[_v.parent.context.$implicit]).length; _ck(_v, 0, 0, currVal_0); }); }
function View_AppointmentGridComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.admin; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.admin; _ck(_v, 4, 0, currVal_1); }, null); }
function View_AppointmentGridComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "schedule-row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "specialist-small-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", ""], ["src", "/assets/images/user-man-placeholder.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "post"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_3)), i1.ɵdid(11, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.objectKeys(_co.appointmentsGrid[_v.context.$implicit].WorkDates); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.appointmentsGrid[_v.context.$implicit].SpecialistName; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.appointmentsGrid[_v.context.$implicit].SpecializationName; _ck(_v, 9, 0, currVal_1); }); }
export function View_AppointmentGridComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "schedule-record"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "schedule-head"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "item"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u0412\u0440\u0430\u0447"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_1)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AppointmentGridComponent_2)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dateRange; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.objectKeys(_co.appointmentsGrid); _ck(_v, 9, 0, currVal_1); }, null); }
export function View_AppointmentGridComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-appointment-grid", [], null, null, null, View_AppointmentGridComponent_0, RenderType_AppointmentGridComponent)), i1.ɵdid(1, 114688, null, 0, i3.AppointmentGridComponent, [i4.MatDialog], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppointmentGridComponentNgFactory = i1.ɵccf("app-appointment-grid", i3.AppointmentGridComponent, View_AppointmentGridComponent_Host_0, { appointmentsGrid: "appointmentsGrid", dateRange: "dateRange", admin: "admin" }, {}, []);
export { AppointmentGridComponentNgFactory as AppointmentGridComponentNgFactory };
