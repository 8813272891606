import { OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { api } from '../../../environments/api';
import { clinicActions, clinicDirections, clinicMetro } from '../../shared/catalogs/clinic.catalogs';
import { PhoneMask } from '../../shared/masks/phone.mask';
import { BaseService } from '../../shared/services/base.service';
import { ClinicService } from '../../shared/services/clinic.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { timepickerTheme } from '../../shared/const/const';
import { weekDays } from '../../shared/catalogs/main.catalog';
import { dateCompilationNoUtc, dateEncodeNoUtc, formatPhone, isEmpty } from '../../shared/functions/functions';
import { DatePipe } from '@angular/common';
import { DialogService } from '../../shared/services/dialog.service';
import { CustomValidators } from '../../shared/validators/custom.validators';
var ClinicEditComponent = /** @class */ (function () {
    function ClinicEditComponent(baseService, clinicService, dialog, router, route, datePipe, dialogService, customValidators) {
        this.baseService = baseService;
        this.clinicService = clinicService;
        this.dialog = dialog;
        this.router = router;
        this.route = route;
        this.datePipe = datePipe;
        this.dialogService = dialogService;
        this.customValidators = customValidators;
        this.preloader = false;
        this.showMetro = false;
        // moscowFias = api.dadataFiases.moscow;
        this.timePickerTheme = timepickerTheme;
        this.selectMetro = clinicMetro;
        this.selectClinicDirections = clinicDirections;
        this.selectClinicActions = clinicActions;
        this.listWeekDays = weekDays;
        this.filteredActions = this.selectClinicActions.slice();
        this.staticClinicDirections = [];
        this.forRemove = {};
        this.phoneMask = {
            mask: PhoneMask
        };
    }
    ClinicEditComponent.prototype.ngOnInit = function () {
        this.clinicIndex = this.route.parent.snapshot.paramMap.get('id');
        this.init();
    };
    ClinicEditComponent.prototype.init = function () {
        var _this = this;
        this.currentClinicFullData = this.clinicService.getClinicFullData();
        /*this.baseService.postSuggestion({
          query: this.currentClinicFullData.AccountData.City,
          count: 1
        })
          .subscribe((response: any) => {
            response.suggestions.length
              ? this.cityFias = response.suggestions[0].data.city_fias_id
              : null;
          });*/
        this.baseService.postSuggestion(this.currentClinicFullData.AccountData.City)
            .subscribe(function (response) {
            response.suggestions.length
                ? _this.cityFias = response.suggestions[0].data.city_fias_id
                : null;
        });
        var clinicDirectionsObject = {};
        this.currentClinicFullData.AccountDirections.forEach(function (el) {
            if (!clinicDirectionsObject[el.Action]) {
                clinicDirectionsObject[el.Action] = {};
                clinicDirectionsObject[el.Action].action = el.Action;
                clinicDirectionsObject[el.Action].id = [];
                clinicDirectionsObject[el.Action].directions = [];
            }
            clinicDirectionsObject[el.Action].directions.push(el.DirectionType);
            clinicDirectionsObject[el.Action].id.push(el.Id);
        });
        for (var key in clinicDirectionsObject) {
            this.staticClinicDirections.push(clinicDirectionsObject[key]);
        }
        this.formInit();
    };
    ClinicEditComponent.prototype._filter = function (value) {
        var filterValue = value.toLowerCase();
        return this.selectClinicActions.filter(function (option) { return option.name.toLowerCase().includes(filterValue); });
    };
    ClinicEditComponent.prototype.changeAutoFill = function (event, i) {
        // this.filteredActions[i] = this._filter(event.target.value) as SelectInterface[];
    };
    ClinicEditComponent.prototype.displayWith = function (obj) {
        return obj ? obj.name : undefined;
    };
    /*
    * Initialization form
    * */
    ClinicEditComponent.prototype.formInit = function () {
        this.form = new FormGroup({
            'name': new FormControl(this.currentClinicFullData.AccountData.AccountName, [Validators.required]),
            'city': new FormControl(this.currentClinicFullData.AccountData.City, [Validators.required]),
            'metro': new FormControl(this.currentClinicFullData.AccountMetro.length ? this.currentClinicFullData.AccountMetro[0].Metro : null),
            'address': new FormControl(this.currentClinicFullData.AccountData.Address),
            'phone': new FormControl(this.currentClinicFullData.AccountData.Phone, [Validators.required]),
            'site': new FormControl(this.currentClinicFullData.AccountData.Site),
            'description': new FormControl(this.currentClinicFullData.AccountData.Description),
            'googleMapsLink': new FormControl(this.currentClinicFullData.AccountData.GoogleMapsLink),
            'yandexMapsLink': new FormControl(this.currentClinicFullData.AccountData.YandexMapsLink),
            'clinicDirections': new FormArray([]),
            'insuranceCompanies': new FormArray([]),
            'clinicSchedule': new FormArray([])
        });
        this.clinicDirections = this.form.get('clinicDirections');
        this.clinicInsuranceCompany = this.form.get('insuranceCompanies');
        this.clinicSchedule = this.form.get('clinicSchedule');
        var _loop_1 = function (key) {
            if (this_1.currentClinicFullData.WorkTime.find(function (x) { return x.WeekDay == weekDays[key].value; })) {
                var index = this_1.currentClinicFullData.WorkTime.findIndex(function (x) { return x.WeekDay == weekDays[key].value; });
                var startTime = this_1.currentClinicFullData.WorkTime[index].StartTime;
                var endTime = this_1.currentClinicFullData.WorkTime[index].EndTime;
                var startTimeConvert = startTime ? this_1.datePipe.transform(dateEncodeNoUtc(startTime), 'HH:mm') : null;
                var endTimeConvert = endTime ? this_1.datePipe.transform(dateEncodeNoUtc(endTime), 'HH:mm') : null;
                this_1.clinicSchedule.push(new FormGroup({
                    'weekDay': new FormControl(weekDays[key].value),
                    'startTime': new FormControl(startTimeConvert),
                    'endTime': new FormControl(endTimeConvert),
                    'holiday': new FormControl(this_1.currentClinicFullData.WorkTime[index].Holiday),
                    'roundClock': new FormControl(this_1.currentClinicFullData.WorkTime[index].Roundclock)
                }));
            }
            else {
                this_1.clinicSchedule.push(new FormGroup({
                    'weekDay': new FormControl(weekDays[key].value),
                    'startTime': new FormControl(null),
                    'endTime': new FormControl(null),
                    'holiday': new FormControl(null),
                    'roundClock': new FormControl(null)
                }));
            }
        };
        var this_1 = this;
        for (var key in weekDays) {
            _loop_1(key);
        }
        var clinicInsuranceCompanyConst = this.currentClinicFullData.AccountInsuranceCom;
        if (clinicInsuranceCompanyConst.length) {
            for (var key in clinicInsuranceCompanyConst) {
                this.clinicInsuranceCompany.push(new FormGroup({
                    'id': new FormControl(clinicInsuranceCompanyConst[key].Id),
                    'companyName': new FormControl(clinicInsuranceCompanyConst[key].CompanyName, [Validators.required])
                }));
            }
        }
    };
    /*
    * Set city fias and check moscow for metro
    * */
    /*setCityFias(response) {
      if (response[0]) {
        this.cityFias = response[0].data.city_fias_id;
  
        this.showMetro = this.moscowFias == response[0].data.city_fias_id;
      } else {
        this.cityFias = response.data.city_fias_id;
        this.showMetro = this.moscowFias == response.data.city_fias_id;
      }
    }*/
    ClinicEditComponent.prototype.setCityFias = function (response) {
        this.cityFias = response;
        this.form.get('address').setValue('');
    };
    /*
    * Add clinic Direction item
    * */
    ClinicEditComponent.prototype.addClinicDirections = function () {
        // this.filteredActions.push(this.selectClinicActions);
        this.clinicDirections.push(new FormGroup({
            'id': new FormControl(),
            'clinicAction': new FormControl(null, [Validators.required, this.customValidators.autofillForceRequire]),
            'clinicDirections': new FormControl('', [Validators.required])
        }));
    };
    /*
    * Add clinic Insurance Company item
    * */
    ClinicEditComponent.prototype.addClinicInsuranceCompany = function () {
        this.clinicInsuranceCompany.push(new FormGroup({
            'id': new FormControl(),
            'companyName': new FormControl(null, [Validators.required])
        }));
    };
    /*
    * Remove clinic Direction item
    * */
    ClinicEditComponent.prototype.removeClinicDirections = function (i) {
        // this.filteredActions.splice(i,1);
        this.clinicDirections.removeAt(i);
    };
    /*
    * Remove clinic Insurance company item
    * */
    ClinicEditComponent.prototype.removeClinicInsuranceCompany = function (i) {
        console.log(this.clinicInsuranceCompany.controls[i].get('id').value);
        var id = this.clinicInsuranceCompany.controls[i].get('id').value;
        if (id) {
            if (!this.forRemove.AccountInsuranceCom)
                this.forRemove.AccountInsuranceCom = [];
            this.forRemove.AccountInsuranceCom.push({
                Id: id
            });
        }
        this.clinicInsuranceCompany.removeAt(i);
    };
    ClinicEditComponent.prototype.removeStaticDirections = function (i) {
        var ids = this.staticClinicDirections[i].id;
        if (!this.forRemove.AccountDirections)
            this.forRemove.AccountDirections = [];
        for (var _i = 0, ids_1 = ids; _i < ids_1.length; _i++) {
            var id = ids_1[_i];
            this.forRemove.AccountDirections.push({
                Id: id
            });
        }
        this.staticClinicDirections.splice(i, 1);
    };
    /*
    * Submit form
    * */
    ClinicEditComponent.prototype.onSubmit = function () {
        var value = this.form.value;
        this.preloader = true;
        /*
        * Create clinic register request
        * */
        var phone = null;
        if (value.phone) {
            phone = formatPhone(value.phone);
        }
        var request = {
            SourceID: api.sourceID,
            SourceSecretKey: api.sourceSecretKey,
            AccountData: {
                AccountId: this.currentClinicFullData.AccountData.AccountId,
                AccountName: value.name,
                Phone: phone,
                City: value.city,
                Site: value.site,
                Description: value.description,
                Address: value.address,
                GoogleMapsLink: value.googleMapsLink,
                YandexMapsLink: value.yandexMapsLink
            }
        };
        /*
        * Add clinic insurance companies into request
        * */
        var requestInsuranceCompanies = [];
        for (var key in value.insuranceCompanies) {
            if (value.insuranceCompanies[key]) {
                var data = {
                    CompanyName: value.insuranceCompanies[key].companyName,
                };
                value.insuranceCompanies[key].id
                    ? data.Id = value.insuranceCompanies[key].id
                    : null;
                requestInsuranceCompanies.push(this.baseService.clearEmpty(data));
            }
        }
        /*
        * Add clinic Directions into request
        * */
        var requestClinicDirections = [];
        for (var key in value.clinicDirections) {
            if (value.clinicDirections[key]) {
                var i = 0;
                for (var key2 in value.clinicDirections[key].clinicDirections) {
                    if (value.clinicDirections[key].clinicDirections[key2]) {
                        var data = {
                            Action: value.clinicDirections[key].clinicAction['value'],
                            DirectionType: this.selectClinicDirections[i].value
                        };
                        value.clinicDirections[key].id
                            ? data.Id = value.clinicDirections[key].id
                            : null;
                        requestClinicDirections.push(this.baseService.clearEmpty(data));
                    }
                    i++;
                }
            }
        }
        /*
        * Add clinic schedule into request
        * */
        var requestClinicSchedule = [];
        for (var key in value.clinicSchedule) {
            if (value.clinicSchedule[key]) {
                var startTimeVal = value.clinicSchedule[key].startTime;
                var endTimeVal = value.clinicSchedule[key].endTime;
                var startTime = void 0;
                var endTime = void 0;
                if (startTimeVal) {
                    var date01 = new Date;
                    var startTimeValSplit = value.clinicSchedule[key].startTime.split(':');
                    date01.setHours(parseInt(startTimeValSplit[0]), parseInt(startTimeValSplit[1]), 0);
                    startTime = dateCompilationNoUtc(date01);
                }
                if (endTimeVal) {
                    var date02 = new Date;
                    var endTimeValSplit = value.clinicSchedule[key].endTime.split(':');
                    date02.setHours(parseInt(endTimeValSplit[0]), parseInt(endTimeValSplit[1]), 0);
                    endTime = dateCompilationNoUtc(date02);
                }
                var data = {
                    WeekDay: value.clinicSchedule[key].weekDay,
                    StartTime: startTime,
                    EndTime: endTime,
                    Holiday: value.clinicSchedule[key].holiday,
                    Roundclock: value.clinicSchedule[key].roundClock
                };
                requestClinicSchedule.push(this.baseService.clearEmpty(data));
            }
        }
        /*
        * Add clinic Metro into request
        * */
        if (value.metro) {
            request.AccountMetro = [];
            request.AccountMetro.push({
                Metro: value.metro
            });
        }
        requestClinicDirections.length
            ? request.AccountDirections = requestClinicDirections
            : null;
        requestInsuranceCompanies.length
            ? request.AccountInsuranceCom = requestInsuranceCompanies
            : null;
        requestClinicSchedule.length
            ? request.WorkTime = requestClinicSchedule
            : null;
        !api.production ? console.log('request', request) : null;
        if (isEmpty(this.forRemove)) {
            this.saveClinicData(request);
        }
        else {
            this.deleteFromClinicData(request);
        }
    };
    ClinicEditComponent.prototype.saveClinicData = function (request) {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.dialogService.showStandardError();
            _this.clinicEditSubscribe.unsubscribe();
        }, api.requestTimeout);
        this.clinicEditSubscribe = this.clinicService.editClinic(request)
            .subscribe(function (response) {
            !api.production ? console.log('EditAccountResult', response) : null;
            _this.preloader = false;
            clearTimeout(timeout);
            if (response.EditAccountResult.Success) {
                _this.dialogService.openStatusDialog('Информация успешно изменена!', 'Вы успешно изменили информацию о клинике', false, 'back-info');
                _this.getNewClinicData();
            }
            else {
                _this.dialogService.showStandardError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.preloader = false;
            _this.dialogService.showStandardError();
        });
    };
    ClinicEditComponent.prototype.getNewClinicData = function () {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.showRefreshError();
            _this.getCurrentClinicSubscribe.unsubscribe();
        }, api.requestTimeout);
        var accountId = this.currentClinicFullData.AccountData.AccountId;
        this.getCurrentClinicSubscribe = this.clinicService.currentClinic(accountId)
            .subscribe(function (response) {
            clearTimeout(timeout);
            _this.preloader = false;
            if (response.CurrentClinicResult.Success) {
                _this.clinicService.setClinicFullData(response.CurrentClinicResult);
                _this.clearAll();
                _this.init();
            }
            else {
                _this.showRefreshError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.showRefreshError();
        });
    };
    ClinicEditComponent.prototype.deleteFromClinicData = function (request) {
        var _this = this;
        var timeout = setTimeout(function () {
            _this.dialogService.showStandardError();
            _this.clinicRemoveSubscribe.unsubscribe();
        }, api.requestTimeout);
        this.clinicRemoveSubscribe = this.clinicService.deleteAccountDetail(this.forRemove).subscribe(function (response) {
            clearTimeout(timeout);
            !api.production ? console.log('DeleteAccountDetailResult', response) : null;
            if (response.DeleteAccountDetailResult.Success) {
                _this.saveClinicData(request);
            }
            else {
                _this.dialogService.showStandardError();
            }
        }, function () {
            clearTimeout(timeout);
            _this.dialogService.showStandardError();
        });
    };
    ClinicEditComponent.prototype.showRefreshError = function () {
        var _this = this;
        this.dialogService.openStatusDialog('При обновлении данных произошла ошибка', 'Ваши изменения были сохранены, но при обновлении данных произошла ошибка. Попробуйте заново открыть страницу.', true, 'back').subscribe(function (response) {
            if (response === 'back')
                _this.router.navigate(['/clinic', _this.clinicService.activeClinic, 'info']);
        });
    };
    ClinicEditComponent.prototype.clearAll = function () {
        this.form.reset();
        this.staticClinicDirections = [];
        while (this.clinicDirections.length !== 0) {
            this.clinicDirections.removeAt(0);
        }
        while (this.clinicInsuranceCompany.length !== 0) {
            this.clinicInsuranceCompany.removeAt(0);
        }
        while (this.clinicSchedule.length !== 0) {
            this.clinicSchedule.removeAt(0);
        }
    };
    /*
    * Testing something
    * */
    ClinicEditComponent.prototype.test = function () {
        !api.production ? console.log('test-from', this.form) : null;
        /*
            let test = '11:00';
        
            let date = new Date;
        
            date.setHours(parseInt(test.split(':')[0]), parseInt(test.split(':')[1]), 0);
        
            console.log(date);
        
            let unix = dateCompilationNoUtc(date);
        
            console.log(unix);
        
            let newDate = dateEncodeNoUtc(unix);
        
            console.log(newDate.getHours() + ':' + newDate.getMinutes());*/
    };
    return ClinicEditComponent;
}());
export { ClinicEditComponent };
