<div class="schedule-record">
  <div class="schedule-head">
    <div class="item">Врач</div>
    <div class="item" *ngFor="let date of dateRange">
      {{date | date:'EE' | titlecase}} <span>{{date | date:'d MMM' | titlecase}}</span>
    </div>
  </div>

  <div class="schedule-row" *ngFor="let key of objectKeys(appointmentsGrid)">
    <div class="item">
      <div class="specialist-small-info">
        <div class="image">
          <img src="/assets/images/user-man-placeholder.png" alt="">
        </div>
        <div class="content">
          <div class="name">{{appointmentsGrid[key].SpecialistName}}</div>
          <div class="post">{{appointmentsGrid[key].SpecializationName}}</div>
        </div>
      </div>
    </div>
    <div class="item" *ngFor="let dateKey of objectKeys(appointmentsGrid[key].WorkDates)">
      <button class="record-item"
              *ngIf="!admin"
              [disabled]="!objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length"
              (click)="showTimeGrid(appointmentsGrid[key].WorkDates[dateKey].Appointments, appointmentsGrid[key].WorkDates[dateKey].Date, key)">
        <span *ngIf="objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length">{{appointmentsGrid[key].WorkDates[dateKey].Length}}</span>
        <i class="if if-close" *ngIf="!objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length"></i>
      </button>
      <button class="record-item"
              *ngIf="admin"
              [disabled]="!objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length"
              (click)="showAdminTimeGrid(appointmentsGrid[key].WorkDates[dateKey].Appointments, appointmentsGrid[key].WorkDates[dateKey].Date, key)">
        <span *ngIf="objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length">{{appointmentsGrid[key].WorkDates[dateKey].Length}}</span>
        <i class="if if-close" *ngIf="!objectKeys(appointmentsGrid[key].WorkDates[dateKey]).length"></i>
      </button>
    </div>
  </div>
</div>
