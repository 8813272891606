<div class="time-header">
  <div class="toggle-date" *ngIf="showWeek">
    <button class="prev-date"
            (click)="prevWeek()">
      <i class="if if-angle-left"></i>
    </button>
    <button class="today"
            [class.disabled]="currentWeek"
            (click)="goCurrentWeek()">
      Текущая неделя
    </button>
    <button class="next-date"
            (click)="nextWeek()">
      <i class="if if-angle-right"></i>
    </button>
  </div>
  <div class="toggle-date" *ngIf="!showWeek">
    <button class="prev-date"
            (click)="prevMonth()">
      <i class="if if-angle-left"></i>
    </button>
    <button class="today"
            [class.disabled]="currentMonth"
            (click)="goCurrentMonth()">Текущий месяц</button>
    <button class="next-date"
            (click)="nextMonth()">
      <i class="if if-angle-right"></i>
    </button>
  </div>
  <div class="text-date" *ngIf="showWeek">
    {{startDateFormat | titlecase}} - {{dueDateFormat | titlecase}}
  </div>
  <div class="text-date" *ngIf="!showWeek">
    {{monthDateFormat | titlecase}}
  </div>
  <div class="toggle-long-time">
    <button (click)="changeRangeDates(false)" [class.active]="!showWeek" [disabled]="!showWeek">Месяц</button>
    <button (click)="changeRangeDates(true)" [class.active]="showWeek" [disabled]="showWeek">Неделя</button>
  </div>
</div>
