import { Component, OnInit } from '@angular/core';
import { ClinicFullInterface } from '../../shared/interfaces/clinic/clinic-full.interface';
import { ClinicService } from '../../shared/services/clinic.service';
import { SelectInterface } from '../../shared/interfaces/select.interface';
import { clinicActions, clinicDirections, clinicServices } from '../../shared/catalogs/clinic.catalogs';
import { CustomDirectionsInterface } from '../../shared/interfaces/clinic/custom-directions.interface';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ClinicChangePhotoComponent } from '../clinic-change-photo/clinic-change-photo.component';
import { FileInterface } from '../../shared/interfaces/file.interface';
import { WorkTimeInterface } from '../../shared/interfaces/clinic/work-time-interface';
import { dateEncodeNoUtc } from '../../shared/functions/functions';
import { weekDays } from '../../shared/catalogs/main.catalog';

@Component({
  selector: 'app-clinic-info',
  templateUrl: './clinic-info.component.html',
  styleUrls: ['./clinic-info.component.scss']
})
export class ClinicInfoComponent implements OnInit {

  clinicFullData: ClinicFullInterface;

  avatarFiles: FileInterface[];
  avatar: string;
  avatarId: string;
  showAvatarCap: boolean = true;

  refresh: boolean = true;

  clinicServicesCatalog: SelectInterface[] = clinicServices;
  clinicDirectionsCatalog: SelectInterface[] = clinicDirections;
  clinicActionsCatalog: SelectInterface[] = clinicActions;
  listWeekDays: SelectInterface[] = weekDays;

  clinicDirections: CustomDirectionsInterface[] = [];
  clinicSchedule: WorkTimeInterface[] = [];

  constructor(
    public clinicService: ClinicService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.clinicFullData = this.clinicService.getClinicFullData();

    console.log('clinic data', this.clinicFullData);

    let clinicDirectionsObject = {};

    this.clinicFullData.AccountDirections.forEach((el) => {
      if(!clinicDirectionsObject[el.Action]) {
        clinicDirectionsObject[el.Action] = {};
        clinicDirectionsObject[el.Action].action = el.Action;
        clinicDirectionsObject[el.Action].directions = [];
      }

      clinicDirectionsObject[el.Action].directions.push(el.DirectionType);
    });

    for(let key in clinicDirectionsObject) {
      this.clinicDirections.push(clinicDirectionsObject[key]);
    }

    this.clinicFullData.WorkTime.forEach((el) => {

      let startTime = el.StartTime;
      let endTime = el.EndTime;

      let startTimeConvert = startTime ? dateEncodeNoUtc(startTime) : null;
      let endTimeConvert = endTime ? dateEncodeNoUtc(endTime) : null;


      console.log('startTimeConvert', startTimeConvert.toString());

      // let startHours = startTimeConvert ? startTimeConvert.getHours() : null;
      // let startMinutes = startTimeConvert ? startTimeConvert.getMinutes() : null;
      // let endHours = endTimeConvert ? endTimeConvert.getHours() : null;
      // let endMinutes = endTimeConvert ? endTimeConvert.getMinutes() : null;

      this.clinicSchedule.push({
        WeekDay: el.WeekDay,
        StartTime: startTimeConvert,
        EndTime: endTimeConvert,
        Holiday: el.Holiday,
        Roundclock: el.Roundclock
      })
    });

    this.getClinicAvatar();

    console.log(this.clinicDirections);
  }

  getClinicAvatar(timeStamp?) {

    this.avatarFiles = this.clinicFullData.Files;

    console.log('get');

    if(this.avatarFiles.length) {

      const last = this.avatarFiles.length - 1;

      this.avatar = this.avatarFiles[last].Link;
      this.avatarId = this.avatarFiles[last].Id;

      if(timeStamp) {
        this.avatar += '?' + timeStamp;
      }

      this.showAvatarCap = false;
    } else {
      this.showAvatarCap = true;
    }
  }

  changePhoto() {
    const dialogRef = this.dialog.open(ClinicChangePhotoComponent, {
      data: {
        avatar: this.avatar,
        avatarId: this.avatarId
      },
      panelClass: 'change-photo-dialog'
    });

    dialogRef.afterClosed().subscribe((result) => {
      if(result.success) {
        this.getClinicAvatar((new Date()).getTime());
        this.snackBar.open('Фото успешно изменено!', 'Ок', {
          duration: 3000
        });
      }
    });
  }

}
