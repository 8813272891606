<mat-form-field>
  <mat-label>
    {{label}}
    <i
      class="danger"
      *ngIf="required"
    >
      *
    </i>
  </mat-label>
  <input type="text"
         matInput
         #inputValue
         (blur)="onInputBlur()"
         [formControl]="control"
         [matAutocomplete]="directionsAuto"
         (keyup)="changeAutoFill($event)">
  <mat-autocomplete
    #directionsAuto="matAutocomplete"
    [displayWith]="displayWith"
    (optionSelected)="onOptionSelected($event)"
  >
    <mat-option
      *ngFor="let option of filteredOptions"
      [value]="option"
    >
      {{option.name}}
    </mat-option>
  </mat-autocomplete>

  <mat-error *ngIf="control.hasError('required')">
    Поле не может быть пустым
  </mat-error>

  <mat-error *ngIf="control.hasError('incorrect')">
    Вы должны выбрать вариант из выпадающего списка
  </mat-error>
</mat-form-field>
