import { MatDialog } from '@angular/material';
import { StatusDialogComponent } from '../components/status-dialog/status-dialog.component';
import { SubmitDialogComponent } from '../components/submit-dialog/submit-dialog.component';
import { api } from '../../../environments/api';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
var DialogService = /** @class */ (function () {
    function DialogService(dialog) {
        this.dialog = dialog;
        this.errorTitle = api.mainErrorTitle;
        this.errorMessage = api.mainErrorMessage;
    }
    /*
    * Открытие диалога со статусом "успех" или "провал"
    * */
    DialogService.prototype.openStatusDialog = function (title, message, error, back) {
        if (error === void 0) { error = false; }
        if (back === void 0) { back = null; }
        var data = {
            title: title,
            message: message,
            error: error,
            back: back
        };
        var dialogRef = this.dialog.open(StatusDialogComponent, {
            data: data
        });
        return dialogRef.afterClosed();
    };
    /*
    * Вызов модального окна со стандартной ошибкой
    * */
    DialogService.prototype.showStandardError = function () {
        this.openStatusDialog(this.errorTitle, this.errorMessage, true);
    };
    /*
    * Открытие диалога с подтверждением "да" или "нет"
    * */
    DialogService.prototype.openSubmitDialog = function (title, message) {
        var data = {
            title: title,
            message: message
        };
        var dialogRef = this.dialog.open(SubmitDialogComponent, {
            data: data
        });
        return dialogRef.afterClosed();
    };
    DialogService.ngInjectableDef = i0.defineInjectable({ factory: function DialogService_Factory() { return new DialogService(i0.inject(i1.MatDialog)); }, token: DialogService, providedIn: "root" });
    return DialogService;
}());
export { DialogService };
