<div class="auth-background-image">
  <img src="assets/images/auth-image-01.jpg" alt="">
</div>

<div class="auth-centered" *ngIf="!preloaderVisibility">
  <form
    class="auth-form"
    [formGroup]="form"
    (ngSubmit)="onSubmit()">

    <div class="auth-form-title">Вход</div>

    <label [class.auth-error]="form.get('email').invalid && form.get('email').touched">
      <input
        type="email"
        placeholder="Email"
        id="email"
        formControlName="email">
      <i class="if if-light-envelope"></i>

      <span class="auth-error-message"
            *ngIf="form.get('email').invalid && form.get('email').touched">

        <span *ngIf="form.get('email')['errors']['required']">
          Поле не может быть пустым
        </span>
        <span *ngIf="form.get('email')['errors']['pattern']">
          Не верно указан E-mail
        </span>
      </span>
    </label>

    <label [class.auth-error]="form.get('password').invalid && form.get('password').touched">
      <input
        [type]="showPassword ? 'text' : 'password'"
        placeholder="Пароль"
        id="password"
        class="input-password"
        formControlName="password">
      <i class="if if-light-locked"></i>
      <span class="show-password" (click)="toggleShowPassword()">
         <i class="if if-eye"></i>
         <i class="if if-no-eye"></i>
      </span>

      <span class="auth-error-message"
            *ngIf="form.get('password').invalid && form.get('password').touched">

        <span *ngIf="form.get('password')['errors']['required']">
          Поле не может быть пустым
        </span>
      </span>
    </label>

    <label class="custom-auth-checkbox">
      <input
        type="checkbox"
        id="remember"
        formControlName="remember">

      <span class="auth-checkbox">
         <i class="if if-check"></i>
      </span>
      <span class="label">
        Запомнить меня
      </span>
    </label>

    <button type="submit"
            [disabled]="form.invalid"
            class="auth-button">
      Войти
    </button>
  </form>
  <div class="auth-links">
    <a routerLink="/auth/sign-up">Регистрация</a>
    <a routerLink="/auth/recover">Забыли пароль?</a>
  </div>
</div>

<div class="load-spinner" *ngIf="preloaderVisibility">
  <img src="assets/images/load-spinner.svg" alt="">
</div>
