<div class="dialog-icon">
  <i class="if icon-gradient"
     [class.if-angry-dog]="data.error"
     [class.if-happy-cat]="!data.error">
  </i>
</div>
<div class="dialog-title">{{data.title}}</div>
<mat-dialog-content class="mat-typography">
  <p [innerHTML]="data.message"></p>
  <p *ngIf="data.restore">Если вы забыли пароль попробуйте <a (click)="goRecover()">востановить его</a>!</p>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-dialog-close class="btn btn-primary">Ок</button>
</mat-dialog-actions>
