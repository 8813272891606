<div class="dialog-title">{{data.title}}</div>
<mat-dialog-content class="mat-typography">
  <p [innerHTML]="data.message"></p>
</mat-dialog-content>
<mat-dialog-actions align="center" class="dialog-buttons">
  <button class="btn btn-primary"
          (click)="onYesClick()">
    Да
  </button>
  <button class="btn btn-default"
          (click)="onNoClick()">
    Нет
  </button>
</mat-dialog-actions>
