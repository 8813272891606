import { SelectInterface } from '../interfaces/select.interface';

export const clinicMetro: SelectInterface[] = [
  {value: 'e08ca65a-b190-4252-a018-645c852e194b', name: 'Автозаводская (14 линия)'},
  {value: '0535a4d2-57ff-4cf8-b0a5-9f202ee365e4', name: 'Авиамоторная'},
  {value: '107dbf22-59b7-4295-8d64-e8c4eaa66a9b', name: 'Автозаводская (Замоскворецкая линия)'},
  {value: '9b3b9ece-bb79-4041-a2ef-cc4ec317b2ec', name: 'Алексеевская'},
  {value: 'd804ccc0-fc46-400b-b72f-ebde8e697d37', name: 'Академическая'},
  {value: 'a5d89fc5-f2e0-41a7-ab05-2d58e9278eee', name: 'Алма-Атинская'},
  {value: 'bce1384e-3fd2-4001-8a10-f2e1ccfe818e', name: 'Андроновка'},
  {value: '1aca5d9e-2e8f-44cd-be6e-f7dc3cd80387', name: 'Алтуфьево'},
  {value: 'ed199be4-9b61-45e4-aed9-e8c5d75ce63c', name: 'Аннино'},
  {value: '113e86f0-ecac-43d4-b444-417dbb624d71', name: 'Арбатская (Филевская линия)'},
  {value: '6cffec9f-764f-4bd4-a960-ee249df4806f', name: 'Арбатская (Арбатско-Покровская линия)'},
  {value: '3eeb95f0-592a-4c82-bfbe-596503eb71a2', name: 'Аэропорт'},
  {value: 'd9d5f933-16cd-486a-b48d-095d36d329ab', name: 'Багратионовская'},
  {value: '6112596c-8552-4c4d-820d-c261a9c57eaf', name: 'Бабушкинская'},
  {value: 'faf24768-25e7-45ec-b47f-ce879ed0129f', name: 'Балтийская'},
  {value: 'a6fcff18-e4f9-4d6c-9928-0a0a2044897e', name: 'Бауманская'},
  {value: '81395680-b023-440c-9ef8-6231d4f76681', name: 'Баррикадная'},
  {value: '8207a9e7-73a3-436b-b483-585b132839f3', name: 'Беговая'},
  {value: '40e876c3-fcb1-4cdb-b373-9916860625cf', name: 'Белорусская'},
  {value: '7e7be8a5-d9d3-4a2b-8e8a-03ad53a2c364', name: 'Белокаменная'},
  {value: 'bcba171f-308b-42be-b651-e8b54834145e', name: 'Беляево'},
  {value: '2ecbab88-5574-451a-8208-a20259623451', name: 'Библиотека им. Ленина'},
  {value: '37c6ba2e-fb44-455a-9374-ee081eedcf7b', name: 'Бибирево'},
  {value: 'c3714bb4-6c81-4ce0-a039-1824ec97fcbb', name: 'Битцевский парк'},
  {value: '13f057b7-6b3d-491a-9b9c-f3d86f60c625', name: 'Боровицкая'},
  {value: '35805388-6884-43d4-920f-9973cb47949e', name: 'Борисово'},
  {value: '99c6d5cd-7308-4f6e-a8b4-a8269d9c4f4b', name: 'Ботанический сад'},
  {value: '6ccc5a49-eeba-4aa4-abbd-8866031d9a01', name: 'Братиславская'},
  {value: '49cdb445-cd5c-4f5f-8f45-7981411ccc64', name: 'Ботанический сад (14 линия)'},
  {value: 'f1b59312-d053-4a78-bf59-a1db36578cbf', name: 'Бульвар Адмирала Ушакова'},
  {value: '5dd44dfc-c2a1-41f7-91a6-d15bcb7dfb1d', name: 'Бульвар Рокоссовского'},
  {value: '050f325d-c89c-498d-b0ce-b76e7d5c9a39', name: 'Бульвар Дмитрия Донского'},
  {value: '87084f1e-870d-4005-a613-284dd87fca4e', name: 'Бунинская аллея'},
  {value: '5ea57175-fc39-4a31-8954-ea1087338e52', name: 'Варшавская'},
  {value: '63c31dbc-4cd2-4567-8246-b8665afa2683', name: 'Бутырская'},
  {value: 'a4446c0d-636b-4fb3-9143-3f66d888e75c', name: 'ВДНХ'},
  {value: '215ac4a5-f910-42be-81a9-8c0540a84c05', name: 'Владыкино'},
  {value: 'cf876d1b-7bb1-4d1b-a714-fea153cba9fe', name: 'Верхние Котлы'},
  {value: '6425ebc6-1490-4a2d-9672-61a7f7ca1067', name: 'Владыкино (14 линия)'},
  {value: '218ae758-fce8-45ee-a6a0-8b3816072b3a', name: 'Войковская'},
  {value: 'e9d361d9-ef9f-4bc1-8d6b-5b95acd3ff2e', name: 'Водный стадион'},
  {value: 'c1c3e11f-53ff-44d4-bd25-e011b2ae6f0e', name: 'Волгоградский проспект'},
  {value: 'b65aecd2-dd2e-4cc7-a7ba-12eea107cebe', name: 'Волоколамская'},
  {value: 'e648bb9c-1b20-4f96-8d86-28685f75ba02', name: 'Волжская'},
  {value: '528bb8fc-59b4-49d9-b9c3-0a5a9564ad89', name: 'Воробьевы горы'},
  {value: 'e25b33fe-64ab-4476-8ee1-13b622f2023f', name: 'Выставочный центр'},
  {value: '5e052778-0ebb-468b-9f98-cf9058524eec', name: 'Выставочная'},
  {value: 'fd235892-d525-4fa7-b3d4-d7fe62fde483', name: 'Выхино'},
  {value: '09b5bcb6-5fdf-4413-bfa0-221c7d12da14', name: 'Деловой центр (МЦК)'},
  {value: 'e6d62c80-9c63-4365-83c2-f514528257a2', name: 'Деловой центр'},
  {value: '9be05f72-c51e-48ad-ab02-7434a35c6676', name: 'Динамо'},
  {value: 'b56f790d-e8e5-4a46-8657-dc702d7cbf5f', name: 'Добрынинская'},
  {value: '14a1760d-c930-4713-be09-f2e8c0385e36', name: 'Дмитровская'},
  {value: 'dcc71cab-bb1b-40ae-9c24-d079a14ae162', name: 'Домодедовская'},
  {value: '4e9a9945-7805-42c0-8ec9-3c8804baa81e', name: 'Дубровка'},
  {value: '9e302e43-00c4-4193-a5de-f3b4694df70c', name: 'Достоевская'},
  {value: '46bcf606-5a79-4474-afdc-679ae6c1bde7', name: 'Дубровка (14 линия)'},
  {value: 'e31b6e3a-1668-45c9-a0bb-4697782e99d3', name: 'ЗИЛ'},
  {value: 'fe0b8a0b-ee30-46fd-adb3-15c7e6b2aa41', name: 'Жулебино'},
  {value: '86499745-264c-4069-877f-54f37a0ac3b4', name: 'Зорге'},
  {value: '7f9442f1-2431-4f0e-983f-874ce6cb23f9', name: 'Измайлово'},
  {value: '3614d1b9-0844-4e2d-9a1b-451dbe1f0123', name: 'Зябликово'},
  {value: '0245b868-4000-47fd-861d-41b0c9dd0ebe', name: 'Измайловская'},
  {value: 'df47cd44-e6b1-4032-9d09-ae2f69eff7e5', name: 'Кантемировская'},
  {value: '7c764faf-69ff-459f-a741-98c8fd874fe6', name: 'Калужская'},
  {value: 'a5b62aea-6d5d-42a2-b825-c38f69ad3249', name: 'Каховская'},
  {value: '6f82a7b6-f815-44f0-8533-19e0c9b8e5ed', name: 'Каширская'},
  {value: '52666f55-7936-4407-9989-2063ddbcc3b8', name: 'Киевская'},
  {value: 'ef45ef94-5184-40e8-a0b1-90ad577e3297', name: 'Китай-город'},
  {value: '5e344ad0-8080-4606-9279-f083de5e2a51', name: 'Кожуховская'},
  {value: '80e19f0d-2b9b-4eae-98df-f40d0da484bc', name: 'Коломенская'},
  {value: 'e0a2be6d-4f37-4634-acda-903c101fa8e0', name: 'Комсомольская'},
  {value: '46fc9e98-124f-49ea-8460-16624c2921bf', name: 'Коньково'},
  {value: '67ed7d13-4d50-45f3-89ff-715d333a19a9', name: 'Коптево'},
  {value: 'f0542a37-3c95-4bed-83c1-c5ae9fdfd9c7', name: 'Котельники'},
  {value: '81e04ddd-470a-47af-a36b-331b10df28e9', name: 'Красногвардейская'},
  {value: 'dc1fbbfd-cf67-4dc5-a124-3af00c424e31', name: 'Краснопресненская'},
  {value: '3d4a0416-1eda-44a5-a33b-cb4829170105', name: 'Красносельская'},
  {value: 'a3552e9e-2654-4098-9f0b-b20590cffe03', name: 'Красные Ворота'},
  {value: 'cd7c3f50-b12e-455c-be39-195b652a29f1', name: 'Крестьянская застава'},
  {value: 'fd809f9b-e204-42ba-9ead-5f83c3771cd4', name: 'Кропоткинская'},
  {value: 'fe67c579-9ae8-49eb-ad97-70a3eb63d0ab', name: 'Крылатское'},
  {value: '495b18bc-86f4-4261-86f9-059dd7b8e749', name: 'Крымская'},
  {value: 'c5d6e3e8-4259-47bc-b16a-e7ada2aef62f', name: 'Кузнецкий мост'},
  {value: '464973ab-6b40-4998-8441-c0377911d1e2', name: 'Кузьминки'},
  {value: '708ea4f3-7c4c-4e12-b7b7-b2399b62c9c1', name: 'Кунцевская'},
  {value: '9278b884-6bec-4488-aadd-5016c355ad5a', name: 'Курская'},
  {value: '16e8af43-ca81-4257-9440-b4ae42fd58b2', name: 'Кутузовская'},
  {value: 'fb3a63e2-47dd-4bd1-a2db-d8f513f56c1f', name: 'Ленинский проспект'},
  {value: 'aa16b4be-8bdf-44fd-be01-d88ab6afedcf', name: 'Лермонтовский проспект'},
  {value: '31580562-b438-4c04-bca3-19f5c48fe7ef', name: 'Лесопарковая'},
  {value: '6b6fbdf2-b18b-4b6e-a819-f94881601502', name: 'Лихоборы'},
  {value: '03b956f6-1f03-4b1d-bdcf-0dd1fc170ead', name: 'Локомотив'},
  {value: '54fb5a76-1a29-4df2-9449-ee65028f4a05', name: 'Ломоносовский проспект'},
  {value: 'e2c5f3d7-1789-41b7-af41-6cc68c722b29', name: 'Лубянка'},
  {value: '2f98df0f-aad7-49c0-ac85-8d7cab7eb001', name: 'Лужники'},
  {value: '25456f9d-8051-4034-98bf-26d6594b5092', name: 'Люблино'},
  {value: 'b70238fa-67df-404e-baaf-89a6e9a9d7fb', name: 'Марксистская'},
  {value: '7b75670a-f73b-4605-ab79-d03f08b4aef8', name: 'Марьина роща'},
  {value: 'd15170f4-8c41-4742-bc76-6907225dd15e', name: 'Марьино'},
  {value: '4d2b088b-bcc1-43dd-8e1a-2983fdc4a99d', name: 'Маяковская'},
  {value: '5fc707f8-4f7a-4bb1-b7f7-cd9bab483795', name: 'Медведково'},
  {value: '8ee7f06c-7d29-4e86-91a5-f1f567fd6ca0', name: 'Международная'},
  {value: '0f1a6371-4c8c-4479-8676-ad59685af04a', name: 'Менделеевская'},
  {value: 'e555c323-3b70-49b9-9f88-4b6734e70299', name: 'Минская'},
  {value: 'b62f4d7c-bc4d-45a1-81d8-0a6196f5f8c3', name: 'Митино'},
  {value: '88758787-3189-45ce-b341-90f32e79a69f', name: 'Молодежная'},
  {value: '77e9edc0-c96e-45ec-8f15-018b7a85f52a', name: 'Мякинино'},
  {value: 'a6a26b7f-f902-4821-9f61-1b21860b11a4', name: 'Нагатинская'},
  {value: '689deb76-c389-4cf2-a1fa-1da32dda5ddd', name: 'Нагорная'},
  {value: '2e0126ef-beb9-446c-acaf-91fbd9335199', name: 'Нахимовский проспект'},
  {value: '35962448-65ca-45f1-8fdb-6f4bae827e80', name: 'Нижегородская'},
  {value: '16e2fd99-2ceb-49b3-934a-93cb4a200641', name: 'Новогиреево'},
  {value: 'af74ebfa-5270-421d-aad2-a50e3c1fdcc9', name: 'Новокосино'},
  {value: '6a85d2b4-8368-453a-bfc0-4a88179dae95', name: 'Новокузнецкая'},
  {value: 'c108a9ec-df57-490f-a321-0c8856f425ed', name: 'Новослободская'},
  {value: 'ce379a11-2f91-4e29-9224-f72a03d5d7c5', name: 'Новохохловская'},
  {value: '1e78fc1f-a1ac-4fa8-af39-7e333fb965f0', name: 'Новоясеневская'},
  {value: '1ba9dd8a-30c2-43b5-b967-0fb51363dda1', name: 'Новые Черемушки'},
  {value: '853e1644-5bc8-4167-9265-e5b415b55d6c', name: 'Окружная'},
  {value: 'ad88e7f0-9cc0-487f-9fe2-e7a427f151e0', name: 'Октябрьская'},
  {value: '55a91fba-477a-4208-bbf1-96130c2a9c93', name: 'Октябрьское поле'},
  {value: 'a85a3924-74c2-49d4-89e6-fad2a21b3828', name: 'Орехово'},
  {value: '6bfd1097-98f3-4a5a-9f97-231fbe8b12b8', name: 'Отрадное'},
  {value: 'fa90cdbc-ae9b-4288-b3ba-ce624931cfa4', name: 'Охотный ряд'},
  {value: 'f966b25b-833b-49e5-93aa-27aba2ddace8', name: 'Павелецкая'},
  {value: 'f05fe198-613a-4ede-bc8e-33c4d1470c97', name: 'Панфиловская'},
  {value: '61801d73-b572-4e9c-b586-6273cc4e048f', name: 'Парк Культуры'},
  {value: '50010e52-e076-4203-92df-ee15e3b34ec4', name: 'Парк Победы'},
  {value: 'd7775324-18f0-4511-a8f3-1b70ed42c8e6', name: 'Партизанская'},
  {value: '5c4aba4e-4a4e-4ea0-91a7-2a90605481cd', name: 'Первомайская'},
  {value: '184da23f-af1d-4058-90a0-842a23347ca7', name: 'Перово'},
  {value: '4ff61393-9f29-46bf-bd8f-d74ae4717f6d', name: 'Петровский парк'},
  {value: '64e37329-567b-4160-9dce-21f9cfd76fda', name: 'Петровско-Разумовская'},
  {value: '8668d32d-4ce8-4d49-8b4f-bb66d3e969f4', name: 'Печатники'},
  {value: '1365a07e-6f83-4029-b2c3-9f9f6d26ce05', name: 'Пионерская'},
  {value: 'ee48297c-627a-483f-b1af-50acc693c17a', name: 'Планерная'},
  {value: 'e92ed61f-ee40-4a51-a5df-5e1ba6f5796a', name: 'Площадь Гагарина'},
  {value: '7b080908-4dba-4b9d-8fa2-c564be2f32f1', name: 'Площадь Ильича'},
  {value: '46be3020-13a4-4e05-997c-eb71eec0eada', name: 'Площадь Революции'},
  {value: 'f906774c-03e4-4c3b-ab10-11294e98f382', name: 'Полежаевская'},
  {value: '2c518e58-1900-49cc-a91e-d5b239148d9f', name: 'Полянка'},
  {value: '74238a53-0afe-4496-81f1-d710357ddc7e', name: 'Пражская'},
  {value: '4f9b6824-221c-4e66-8e02-052f271cbe80', name: 'Преображенская площадь'},
  {value: '78a830fb-9ef2-4add-adf0-3e302823a879', name: 'Пролетарская'},
  {value: '35c1ab07-3053-485d-b969-1b741e15c564', name: 'Проспект Вернадского'},
  {value: 'eb452839-f3e1-4732-8fb8-f26b2ffa66af', name: 'Проспект Мира'},
  {value: 'c3ea9e96-9dc4-46c1-a32b-27549b783f19', name: 'Профсоюзная'},
  {value: '36393396-4af3-4481-af69-c1e938ef77fe', name: 'Пушкинская'},
  {value: 'e35032c4-96c1-4a35-96ff-9efe23ccc5e5', name: 'Пятницкое шоссе'},
  {value: '359817b7-fd02-419a-9b46-83e2dd0d9ced', name: 'Раменки'},
  {value: '25786835-0f97-4247-a533-9e87541b5443', name: 'Речной вокзал'},
  {value: '609d7c2b-4340-46b8-a24b-e3ff43a3031d', name: 'Рижская'},
  {value: '675c0d3a-1367-44ac-aba0-437914fc82de', name: 'Римская'},
  {value: '79c42b15-18fb-473a-b61d-f9a50e551b0f', name: 'Ростокино'},
  {value: '3bb8a692-b383-4ab1-af16-efb026442ac7', name: 'Румянцево'},
  {value: '3d299b4a-a268-4e53-affe-e95dc8311387', name: 'Рязанский проспект'},
  {value: '90c4d107-12d3-403d-a8f2-e1b235b70c65', name: 'Савеловская'},
  {value: '2b599eff-7d41-41ea-97e2-1d4ef4447857', name: 'Саларьево'},
  {value: '419574ca-c0d7-4cdc-bdbb-d306ce14eeec', name: 'Свиблово'},
  {value: '997b65e5-59f9-41a4-8bc6-088f11a709b3', name: 'Севастопольская'},
  {value: 'e83de5b8-3b9b-4d05-8a9f-5cb975a4f603', name: 'Семеновская'},
  {value: '3fd1f313-91dc-45e8-add5-f851a2a4bd11', name: 'Серпуховская'},
  {value: 'd548dda9-3804-457c-ad21-7488712edf75', name: 'Славянский бульвар'},
  {value: '08a6f2b6-79b5-4ede-b64a-1bd4320d6c1e', name: 'Смоленская (Арбатско-Покровская линия)'},
  {value: '653824f3-2fda-485b-bcc2-4eee933c93fc', name: 'Смоленская (Филевская линия)'},
  {value: '80161793-dc52-45a6-a056-9c4f0c1765bc', name: 'Сокол'},
  {value: '4eb3a0bf-f56a-4145-b4a1-d193a0ccf085', name: 'Соколиная гора'},
  {value: 'ac3d67cf-0fca-4a39-826a-3a4c667ef99c', name: 'Сокольники'},
  {value: '8f2af9fa-3366-472a-b594-5d8829520910', name: 'Спартак'},
  {value: '0953d6d3-dedb-41d5-a358-0124610cf53a', name: 'Спортивная'},
  {value: '0b12ac3a-7702-4760-9b0f-9c02c2262faa', name: 'Сретенский бульвар'},
  {value: 'a6445dea-8eef-46a6-b1f4-735f80eea587', name: 'Стрешнево'},
  {value: '98360f59-2542-4c7d-bc56-c4ff885c3ef3', name: 'Строгино'},
  {value: '09b963c2-c02b-4e9b-9cc0-0194b89d501f', name: 'Студенческая'},
  {value: '8e9f74a1-4fa7-4543-bf44-53ad076f0fba', name: 'Сухаревская'},
  {value: 'e9418ac1-cc7e-4671-a486-e07ad8fd460a', name: 'Сходненская'},
  {value: '973a7013-885f-4627-afb2-e5a23a1bac05', name: 'Таганская'},
  {value: 'e771e34e-e210-482f-bb95-7e253ba52d32', name: 'Тверская'},
  {value: '7f01f228-177c-4496-a5bf-d241c5446567', name: 'Театральная'},
  {value: '2899f6fb-3248-48cc-ac81-c3685e1cb56d', name: 'Текстильщики'},
  {value: '36a0bc5e-492b-49db-8967-a7c17fa7af26', name: 'Телецентр'},
  {value: 'c8e6eda6-c260-48da-aa97-a98095a61ccb', name: 'Теплый Стан'},
  {value: '6bf15031-6046-4e57-82a3-02087cb9856a', name: 'Технопарк'},
  {value: 'e68f49bd-9972-42ec-a5a3-dc45177b8e81', name: 'Тимирязевская'},
  {value: 'eee4f7a0-e6de-4ca4-8a42-d7b106d23f36', name: 'Третьяковская'},
  {value: '2b54de51-c39c-4fac-b5b8-b676c8593742', name: 'Тропарево'},
  {value: '8c8981d6-cd4b-4304-8ea6-f32609d0009b', name: 'Трубная'},
  {value: 'ce662a00-616b-4e39-8c82-b398f1017295', name: 'Тульская'},
  {value: '78139fad-a8cb-472f-bd97-672bb8825c3f', name: 'Тургеневская'},
  {value: 'b6c3af46-814b-4065-81d7-b77c5cae8485', name: 'Тушинская'},
  {value: 'fb6859b3-348f-4617-bfd4-32573fd446f5', name: 'Угрешская'},
  {value: '3441c8ac-4928-4a42-a361-c9bee33d4c52', name: 'Улица 1905 года'},
  {value: '9e36fc6e-af98-4b50-8180-cccb672c8cd7', name: 'Улица Академика Королёва'},
  {value: '0d665d99-4c9b-4716-a898-c3fda3417fce', name: 'Улица Академика Янгеля'},
  {value: '6c33c287-c955-4e3f-bbea-cb39495eed4d', name: 'Улица Горчакова'},
  {value: '2fda912e-2409-419a-9519-b155a3ee2725', name: 'Улица Милашенкова'},
  {value: '98608b40-3961-4a6b-859f-f1990b14581f', name: 'Улица Сергея Эйзенштейна'},
  {value: 'da0e8a73-f37f-4c9c-b675-018c0342638f', name: 'Улица Скобелевская'},
  {value: '13426ce0-99ef-47ea-8f2a-b4ea1de622c1', name: 'Улица Старокачаловская'},
  {value: '82f4b35b-05d0-4d5a-a130-67ecb41569a2', name: 'Университет'},
  {value: '483fa3fb-2e45-40f3-92ea-153bbfee47bf', name: 'Филевский парк'},
  {value: '86429da3-fa80-462a-9171-fa4868faa6fe', name: 'Фили'},
  {value: '98496478-59a3-4ec2-a5b3-805afe8f1392', name: 'Фонвизинская'},
  {value: 'f6ad28f2-9409-4cb0-be6e-92b3f7c844cb', name: 'Фрунзенская'},
  {value: '81a61427-4584-4225-9f58-0ed33dc9f91f', name: 'Хорошево'},
  {value: '103e1dc6-a668-4d4e-b620-bb4c506fb893', name: 'Хорошевская'},
  {value: 'f2501982-56be-4c71-9273-c3dee20c1bf0', name: 'Царицыно'},
  {value: 'bebc3179-722c-40cf-b8ab-a70553083fd7', name: 'Цветной бульвар'},
  {value: '1d08a4da-7248-478c-b28f-df437752fdbc', name: 'ЦСКА'},
  {value: '7bd2650a-1f49-4bf0-a526-ef3e418cd265', name: 'Черкизовская'},
  {value: '3f1b6b7a-b33e-488f-8705-f729bffa33bf', name: 'Чертановская'},
  {value: 'c0009eca-50b4-4d33-a7c3-dc2ba148b3df', name: 'Чеховская'},
  {value: 'acf81e90-58a4-467e-95b4-c217eca5b3c8', name: 'Чистые пруды'},
  {value: '98f27d35-c03e-4870-b93a-6b2548ca49f3', name: 'Чкаловская'},
  {value: '28af7830-8c2c-4316-b34e-c45368a2f79e', name: 'Шаболовская'},
  {value: '28970fc0-deec-45f6-9c28-2b02e2eb4457', name: 'Шелепиха'},
  {value: 'ba00930a-bc19-45ae-b130-21827e981cea', name: 'Шипиловская'},
  {value: '9acb5a75-4896-4293-9d9d-6a65dff23001', name: 'Шоссе Энтузиастов'},
  {value: 'd6572244-d3b3-4248-bd2c-961ccf961ae5', name: 'Щелковская'},
  {value: '1247624c-cdcc-4fd7-a429-47db9a50436d', name: 'Щукинская'},
  {value: '52238d3b-a4db-4db3-9c5d-4035b77c766b', name: 'Электрозаводская'},
  {value: '688c7af5-cfd4-453c-98ad-934b4a41068b', name: 'Юго-Западная'},
  {value: 'd6b13953-bd5d-4b46-a015-4a78a5cf3b55', name: 'Южная'},
  {value: '9b9479e1-0665-4118-b7db-cb520d0efcbf', name: 'Ясенево'}
];

export const clinicServices: SelectInterface[] = [
  { value: '29cabd80-0fde-4df6-adf9-40413bef137b', name: 'Бронхоскопия'},
  { value: '8a5e5e33-2aff-4dba-919f-ac5b58bb49bb', name: 'Вакцинация от бешенства'},
  { value: '10a548c5-5c54-4095-b5b1-915c696b0260', name: 'Вакцинация против лейкоза'},
  { value: '73ebd2b0-cbdb-48e7-a14b-495bddca371d', name: 'Вакцинация против лептоспироза'},
  { value: 'c110ab34-5e00-4461-9eb7-705a87109faa', name: 'Вакцинация против пироплазмоза'},
  { value: 'bdf34d84-6e64-4fbe-b768-c6d634b28132', name: 'Вакцинация против хламидиоза'},
  { value: 'dd22d8e2-5a01-42a2-a5c2-e3059b83f8e1', name: 'Вызов на дом'},
  { value: '92ba490a-621a-4c86-973b-2f33308032fc', name: 'Гастроскопия'},
  { value: '4e19e00c-7eca-4ff4-aa66-19e8cc4c95c0', name: 'Госпитализация'},
  { value: 'b2d58b9e-e449-4f82-ac65-876003ca6305', name: 'Гостиница для животных'},
  { value: 'dd129a27-caec-4520-88c8-eaa0a00eb67f', name: 'Груминг'},
  { value: '917b8930-9200-48d0-889c-8d96fd671388', name: 'Измерение давления'},
  { value: 'ff8a5b50-1742-4373-ab8c-8059f497e0d0', name: 'Кастрация кобеля'},
  { value: 'cb9e7377-6fb0-434e-b7d1-f7947589d78c', name: 'Кастрация кобеля крипторха'},
  { value: '9e261f1c-fd9a-4fe3-ade6-05c005fbaf1f', name: 'Кастрация кота'},
  { value: '64e55e5f-b8d2-47f7-ba46-55470a17e06f', name: 'Колоноскопия'},
  { value: '0e571c94-8e07-4657-ab6f-41a17b1d2a6f', name: 'Комплексная вакцинация кошек'},
  { value: '45f8a780-9127-430c-8764-7fbd54754428', name: 'Комплексная вакцинация собак'},
  { value: '8bea6766-39dd-481e-877e-f152e9d6ecb1', name: 'Компьютерная томография'},
  { value: '6ab2937a-d8aa-448d-8cbf-bf854c2f61d3', name: 'Консультация ветеринара'},
  { value: '34974792-4c3d-47c5-9d50-393557445a4b', name: 'Консультация дерматолога'},
  { value: '0b71120f-90f1-44da-a59e-7d569d95650d', name: 'Консультация кардиолога'},
  { value: 'd8ed55d1-f421-44fb-a729-67808c93ede3', name: 'Консультация невролога'},
  { value: 'afbcdaf2-4765-4970-b446-9be7ad99656b', name: 'Консультация онколога'},
  { value: '7e8f48d5-de7d-440c-9c4d-99a19830126f', name: 'Консультация ортопеда'},
  { value: '9bd7c515-2bdc-4974-b3be-b7eed607b3d7', name: 'Консультация офтальмолога'},
  { value: '67ba9f4a-0ff1-4f91-8a4e-993f4bab4d26', name: 'Консультация по результатам анализов'},
  { value: 'c0ebebc9-318b-4f72-b8d8-dc3d25e1b50f', name: 'Консультация репродуктолога'},
  { value: '2b3659f6-6c4b-4c8f-9d85-3cb2298fa79a', name: 'Консультация стоматолога'},
  { value: '2149c129-d1ff-4df3-ab3e-497c13832653', name: 'Консультация эндокринолога'},
  { value: '71716ffa-19a2-42c1-bd55-2916b6a4cb36', name: 'Лаборатория'},
  { value: '2cb07686-1841-44d8-b5e0-d410ee0916e1', name: 'Лечение кошек'},
  { value: '99d14b01-4f33-4713-aeda-c557a8de5c79', name: 'Лечение рептилий'},
  { value: '2f78160c-7237-4309-8381-ba3173945c2b', name: 'Лечение собак'},
  { value: '094896a9-37d1-472c-9c4a-dbb3aacf9fb3', name: 'Лечение харьков'},
  { value: '8ea584f4-2b25-48cc-8cb6-5440dde9a839', name: 'Люминесцентная диагностика'},
  { value: 'e324b1ed-a177-45dc-9aa9-737dab0ee2c4', name: 'Офтальмоскопия'},
  { value: '074dc99a-99eb-4ed1-aa44-7646cf3002a1', name: 'Передержка кошки'},
  { value: 'bcb514ac-0d11-46f1-8903-ff8860cdc1b9', name: 'Передержка собаки'},
  { value: '6153815b-ae81-432a-a502-7ca21ce724ff', name: 'Рентген'},
  { value: 'b9523a31-e9c4-4f20-9b27-2295c1dbb4df', name: 'Скорая ветеринарная помощь'},
  { value: '6e0366f9-7b8f-4053-9092-596797211cc6', name: 'Стерилизация кошки'},
  { value: '64cd9dc9-284a-4d55-8e9a-6afd8e72a16a', name: 'Стерилизация суки'},
  { value: '74ba91ff-b771-4e7d-8ada-298e0019e067', name: 'Стрижка когтей'},
  { value: '4f0e14c3-c1dc-4b3f-9c26-137098c96670', name: 'Трахеоскопия'},
  { value: '95205dec-7346-448e-9b5e-dc2c0a83c2b5', name: 'Удаление клеща'},
  { value: '99c1c9bd-fe06-4fea-84b1-7c515cda4387', name: 'УЗИ'},
  { value: '5327e541-25ce-4cd3-9b29-0a422769bc92', name: 'Чипирование'},
  { value: '26785da8-c1c8-4a2c-800b-ba37c4d76bf6', name: 'Чистка зубов кошке'},
  { value: '0d71c6c3-c48a-4013-89db-d7226c6b11fc', name: 'Чистка зубов собаке'},
  { value: '300d366b-d89a-4c02-81e2-e3d284b882a4', name: 'Чистка параанальных желез'},
  { value: 'ae1e9454-6cc2-4a77-bf03-3ab29b42e6cd', name: 'Чистка ушей'},
  { value: '7fdae2fd-6fb5-4d23-931d-f2efcb24f4a5', name: 'ЭКГ'},
  { value: 'f80a7bbf-f168-4ac7-91d8-ce4527040040', name: 'ЭКГ-мониторинг'}
];

export const clinicDirections: SelectInterface[] = [
  {value: '005233f3-eb6e-4803-bb57-07fd0c4faa21', name: 'Консультация'},
  {value: '3b2a2f97-b484-4139-998c-9507ac631411', name: 'Обследование'},
  {value: '54217d21-de34-4261-b924-56fa13331897', name: 'Другое'}
];

export const clinicActions: SelectInterface[] = [
  {value: 'aaa14bf3-0204-4f51-9b95-9913e73c98e8', name: 'Акушерство'},
  {value: 'f58368bc-4bbb-4499-b3a1-d0ac3a911b3a', name: 'Анестезиология'},
  {value: '72a45b12-5fef-4042-837e-245b398a123f', name: 'Гипертология'},
  {value: '717790d5-de62-47c5-b17d-b3fa747214e4', name: 'Груминг'},
  {value: 'e516bff1-6ab7-4b59-80e7-06310d2f865d', name: 'Дерматология'},
  {value: '434d833a-f033-4f5f-b762-efd70cdd2ac2', name: 'Кардиология'},
  {value: 'd10a3d53-7fc3-45ea-85e9-94c3fc8da15b', name: 'Лабораторная диагностика'},
  {value: '2f2b14d4-c490-4f45-8a56-b9af954512e5', name: 'Неврология'},
  {value: '586c8ca2-1062-4427-a0a7-322e6cbeac8d', name: 'Онкология'},
  {value: '01c20bed-1caf-43b6-8f69-4173543f1a41', name: 'Офтальмология'},
  {value: '8657811e-ba58-45a3-b3b4-09c7d825708b', name: 'Стоматология'},
  {value: '981c1edf-5bb4-4982-8619-86b66ccf3df0', name: 'Терапия'},
  {value: 'c02a905c-438d-4dd8-aa1d-725cdf0c7bc8', name: 'Хирургия'},
  {value: '88bf27c5-3a46-4b1b-b1cd-a97005ef0a0b', name: 'Экзотические животные'},
  {value: 'ded94480-e6b6-466c-93be-c5d1a53b98f3', name: 'Эндокринология'},
  {value: '46164f81-9599-4bc0-9890-2083033d2910', name: 'Эндоскопия'},
  {value: '374aa21e-a88b-49fb-a0a6-b860babe109f', name: 'Другое'},
];

export const specializations: SelectInterface[] = [
  {value: '2b1ee8a9-ec75-4948-9049-55ebe68f7e97',	name: 'Анестезиолог'},
  {value: 'a24704c7-cc5b-4b88-ae47-24e83a72789e',	name: 'Герпетолог'},
  {value: 'e587abcb-13d9-4468-8e6b-173f7adec54d',	name: 'Гинеколог-акушер'},
  {value: '8de65567-19fd-4c3a-8c57-d9580bf12e27',	name: 'Грумер'},
  {value: '46dbd96b-11dd-40d6-8105-c80d74723605',	name: 'Дерматолог'},
  {value: 'bb4185ac-7360-447e-9ced-10cb4ccc1184',	name: 'Кардиолог'},
  {value: '5fb8978f-ec08-41f7-8e4a-93b23fb9082a',	name: 'Лаборант'},
  {value: '16e5bd5d-d6bf-443e-96ba-7f30f89caf92',	name: 'Невролог'},
  {value: 'f4d36a52-92de-4003-a48b-1d8c924cd97f',	name: 'Онколог'},
  {value: '2d85c5d9-12e5-410a-9b3c-7b69f75b0ae8',	name: 'Офтальмолог'},
  {value: '30ff1029-1373-4099-929f-35d80566e1e2',	name: 'Специалист по грызунам и экзотическим животным'},
  {value: '98e9ec43-4045-4229-bb4f-950c30ee1e80',	name: 'Стоматолог'},
  {value: '005c4a19-2be0-4a8c-8ac0-d6ce79ea4261',	name: 'Терапевт'},
  {value: '331e307b-ae36-4a1b-b020-fcb5dd246008',	name: 'Хирург'},
  {value: '113756c1-9f79-40b1-8b38-aaf133d82529',	name: 'Эндокринолог'},
  {value: '5725ef01-8772-414a-8207-7e1a1fc984a9',	name: 'Эндоскопист'},
];

export const typeAppointment: SelectInterface[] = [
  {value: '71e0f5b1-f8c4-4ee8-acb7-7abb11e20ed7', name: 'В клинике'},
  {value: 'fdfac7a2-a126-4869-be65-a17ec8b71925', name: 'На выезд'}
];

export const weekDay: SelectInterface[] = [
  {value: 'a752dcfb-c499-4462-b96c-e791b6696764', name: 'Понедельник'},
  {value: 'c3e40532-f401-465e-b907-59dd14532635', name: 'Вторник'},
  {value: '34367590-94ee-47c6-9c0c-4e7fceae5164', name: 'Среда'},
  {value: '9f47e8b6-aabd-4582-bae7-582415a4e8c3', name: 'Четверг'},
  {value: '7ad02a66-8b15-4250-8a84-a71200667317', name: 'Пятница'},
  {value: 'b4749969-9871-452a-8257-52283e058555', name: 'Суббота'},
  {value: 'a1053a1e-cad6-4207-b526-d29d83183214', name: 'Воскресенье'},
];

export const typeId: SelectInterface[] = [
  {value: '30122fde-480a-4466-a21c-a3c0c67fa15b', name: 'Хирургия'},
  {value: '5af2b189-b588-4db5-82b4-cc9134b19f6f', name: 'Манипуляции косметические'},
  {value: '94ab675c-77a5-4568-be6b-9639bcbd60a7', name: 'Манипуляции лабораторные'},
  {value: 'e31ef64e-dcaf-41df-968e-3a631981100b', name: 'Инъекции'},
  {value: '8c1b68ba-5ebc-401c-b520-48c889879332', name: 'Вакцинация'},
  {value: 'ad21c928-c607-48bf-8660-a2c37125ee64', name: 'Зоогостиница'},
  {value: '4730f06b-92d3-4a43-b6c4-86517f58427d', name: 'Стационар'},
  {value: '141a40ac-4b9b-4276-94ec-c5f9d19bc84e', name: 'Первичный прием'},
  {value: '753526b9-fb69-45cd-9e8d-0639f5079101', name: 'Вторичный прием'}
];
