import { RouterModule, Routes } from '@angular/router';
import { ClinicComponent } from '../clinic.component';
import { AuthGuard } from '../../shared/services/auth.guard';
import { NgModule } from '@angular/core';
import { ScheduleComponent } from './schedule.component';
import { ScheduleAddComponent } from './schedule-add/schedule-add.component';
import { ScheduleEditComponent } from './schedule-edit/schedule-edit.component';
import { AppointmentAddComponent } from './appointment-add/appointment-add.component';
import { AppointmentEditComponent } from './appointment-edit/appointment-edit.component';

const routes: Routes = [
  {
    path: 'clinic/:id', component: ClinicComponent, canActivate: [AuthGuard], children: [
      {
        path: 'schedule', component: ScheduleComponent, children: [
          {path: '', redirectTo: 'appointment-add', pathMatch: 'full'},
          {path: 'add', component: ScheduleAddComponent},
          {path: 'edit', component: ScheduleEditComponent},
          {path: 'appointment-add', component: AppointmentAddComponent},
          {path: 'appointment-edit', component: AppointmentEditComponent}
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ScheduleRoutingModule {
}
